import React, { useState, useMemo, useEffect } from "react";
import AccountCard from "@/components/account-card";
import { auth, getUserInfo, getAccountPortfolioInfo } from "@/api/userinfo";
import { Card, CardContent, CardProps, Grid2, Container, Typography, Box, Divider, ButtonProps, ButtonBase, Paper, Icon } from "@mui/material";
import "./rwa-profile.less";
import { useTranslation } from "react-i18next";
import TransferDialog from "../rwa/dialog/transfer-dialog";
import SwapDailog from "../trade/dialog/swap-dialog";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AccountPortfolioVO } from "@/api/types";
import { useNavigate } from "react-router-dom";
import FissionSettingDialog from "@/pages/rwa/dialog/fission-setting-dialog";
import { bigNumberFormat } from "@/utils/common";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import BigNumber from "bignumber.js";

const ParameterCoefficientCard = (props: CardProps) => {
  const { t } = useTranslation();
  const queryCleint = useQueryClient();
  const { data: userInfo } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryCleint
  );
  return (
    <Card {...props}>
      <CardContent>
        <Typography variant="h6">{t("bd.rwa.profile.parameter.coefficient")}</Typography>
        <Grid2 container sx={{ mt: 1 }}>
          <Grid2 size={5}>
            <Box>
              <Typography sx={{ color: "text.disabled" }}>{t("bd.rwa.profile.leverage.multiple")}</Typography>
              <Typography sx={{ mt: 0.5 }}>{bigNumberFormat(userInfo?.data?.packageInfo?.lever)}%</Typography>
            </Box>
          </Grid2>
          <Grid2 size={2}>
            <Box sx={{ height: "100%" }} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Divider orientation="vertical" flexItem />
            </Box>
          </Grid2>
          <Grid2 size={5}>
            <Box>
              <Typography sx={{ color: "text.disabled" }}>{t("bd.rwa.profile.collision.ratio")}</Typography>
              <Typography sx={{ mt: 0.5 }}>{bigNumberFormat(userInfo?.data?.packageInfo?.intelligenceLever)}%</Typography>
            </Box>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};
const HarvestCard = ({ collapsed, harvestExt }: { collapsed: boolean; harvestExt?: AccountPortfolioVO[] }) => {
  const { t } = useTranslation();
  return (
    <Card
      className="list-item-card"
      sx={collapsed ? { bgcolor: "#0A3566", borderRadius: 1.5 } : { display: "none", bgcolor: "#0A3566", borderRadius: 1.5 }}
    >
      <CardContent sx={{ paddingBottom: "0.5rem !important", padding: 1.5 }}>
        {(harvestExt || [])
          .filter((item) => {
            if (item.portfolio?.portfolioCode === "bd.portfolio.code.harvest" && BigNumber(item.balance).comparedTo(0) <= 0) {
              return false;
            }
            return true;
          })
          .map((item, index) => (
            <Box key={index}>
              <Typography color="#595959" component="span">
                {t(item.portfolio?.portfolioCode)}
              </Typography>
              <Typography component="span">{bigNumberFormat(item.balance, item.portfolio?.portfolioDecimal)}</Typography>
            </Box>
          ))}
      </CardContent>
    </Card>
  );
};

const MyPortfolioCard = (props: CardProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data } = useQuery(
    {
      queryKey: ["accountPortfolio"],
      queryFn: getAccountPortfolioInfo,
    },
    queryClient
  );
  const [collapsed, setCollapsed] = useState(false);
  const harvestExt = data?.data.filter(
    (item) => item.portfolio?.metaInfo && item.portfolio.metaInfo.includes("harvest") && item.portfolio.metaInfo.includes("invest")
  );
  const harvestEtBalanceTotal = harvestExt?.reduce((acc, cur) => BigNumber(cur.balance).plus(BigNumber(acc)).toNumber(), 0);

  const [showMoreHarvest, setShowMoreHarvest] = useState(true);
  useEffect(() => {
    if (!harvestExt?.length) {
      setShowMoreHarvest(false);
    } else if (harvestExt?.length) {
      const harvestExtList = harvestExt.filter((item) => {
        if (item.portfolio?.portfolioCode === "bd.portfolio.code.harvest" && BigNumber(item.balance || "0.00").comparedTo(0) <= 0) {
          return false;
        }
        return true;
      });
      if (harvestExtList.length === 0) {
        setShowMoreHarvest(false);
      } else {
        setShowMoreHarvest(true);
      }
    } else {
      setShowMoreHarvest(true);
    }
  }, [harvestExt]);
  const renderPortfolioInfo = useMemo(() => {
    if (data?.data) {
      const portfolioMap = data?.data.reduce((acc: Record<string, string>, current: AccountPortfolioVO) => {
        if (current.portfolio?.portfolioCode) {
          if (current.portfolio?.portfolioCode === "bd.portfolio.code.harvest") {
            acc[current.portfolio?.portfolioCode] = bigNumberFormat(harvestEtBalanceTotal, current.portfolio.portfolioDecimal);
          } else {
            acc[current.portfolio?.portfolioCode] = bigNumberFormat(current.balance, current.portfolio.portfolioDecimal);
          }
        }
        return acc;
      }, {});
      return (
        <>
          <Typography variant="h6">{t("page.burningprofile.myportfolio")}</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography sx={{ color: "text.disabled" }}>{t("bd.portfolio.code.planting")}</Typography>
            <Typography sx={{ mt: 0.5 }}>{portfolioMap["bd.portfolio.code.planting"] || bigNumberFormat("0.00")}</Typography>
          </Box>
          <Grid2 container sx={{ mt: 2 }}>
            <Grid2 size={5}>
              <Box>
                <Typography sx={{ color: "text.disabled" }}>{t("bd.portfolio.code.training")}</Typography>
                <Typography sx={{ mt: 0.5 }}>{portfolioMap["bd.portfolio.code.training"] || bigNumberFormat("0.00")}</Typography>
              </Box>
            </Grid2>
            <Grid2 size={2}>
              <Box sx={{ height: "100%" }} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Divider orientation="vertical" flexItem />
              </Box>
            </Grid2>
            <Grid2 size={5}>
              <Box>
                <Typography sx={{ color: "text.disabled" }}>{t("total.generated.value")}</Typography>
                {showMoreHarvest ? (
                  <Typography sx={{ mt: 0.5 }} onClick={() => setCollapsed(!collapsed)}>
                    <Typography component="span" sx={{ verticalAlign: "top" }}>
                      {portfolioMap["bd.portfolio.code.harvest"] || bigNumberFormat("0.00")}
                    </Typography>
                    {!collapsed ? <ArrowDropDownSharp sx={{ color: "text.secondary" }} /> : <ArrowDropUpSharp sx={{ color: "text.secondary" }} />}
                  </Typography>
                ) : (
                  <Typography sx={{ mt: 0.5 }}>{portfolioMap["bd.portfolio.code.harvest"] || bigNumberFormat("0.00")}</Typography>
                )}
              </Box>
            </Grid2>
          </Grid2>
          <HarvestCard collapsed={collapsed} harvestExt={harvestExt} />
        </>
      );
    }
    return null;
  }, [data?.data, t, collapsed]);
  return (
    <Card {...props}>
      <CardContent>{renderPortfolioInfo}</CardContent>
    </Card>
  );
};
const AppTradeBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [openSwapDialog, setOpenSwapDailog] = useState(false);
  const [openFissionSettingDialog, setOpenFissionSettingDialog] = useState(false);
  const AppTradeIcon = ({ iconClass, text, sx, ...props }: ButtonProps & { iconClass: string; text: string }) => {
    let borderRadius = "0.875rem";
    if (sx && "borderRadius" in sx) {
      borderRadius = sx.borderRadius as string;
    }

    return (
      <ButtonBase sx={sx} {...props}>
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: borderRadius,
          }}
        >
          <Icon className={iconClass} />
          <Typography>{text}</Typography>
        </Paper>
      </ButtonBase>
    );
  };

  return (
    <Box>
      <AppTradeIcon
        iconClass="icon-action-transfer"
        text={t("bd.trade.type.transfer")}
        onClick={async () => {
          try {
            const { data } = await auth({
              type: "passwordCheck",
            });
            setOpenTransferDialog(true);
            console.log("auth response ", data);
          } catch (e) {
            console.warn("check password fail", e);
          }
        }}
        sx={{
          mr: 1,
          height: "4.5rem",
          width: "4.5rem",
          borderRadius: "0.875rem",
        }}
      />

      <AppTradeIcon
        iconClass="icon-action-swap"
        text={t("bd.trade.type.swap")}
        onClick={async () => {
          try {
            const { data } = await auth({
              type: "passwordCheck",
            });
            setOpenSwapDailog(true);
            console.log("auth response ", data);
          } catch (e) {
            console.warn("check password fail", e);
          }
        }}
        sx={{
          mr: 1,
          height: "4.5rem",
          width: "4.5rem",
          borderRadius: "0.875rem",
        }}
      />

      <AppTradeIcon
        iconClass="icon-action-cdk"
        text={t("bd.trade.type.cdk")}
        onClick={async () => {
          navigate("/app/active-cdk");
        }}
        sx={{
          mr: 1,
          height: "4.5rem",
          width: "4.5rem",
          borderRadius: "0.875rem",
        }}
      />
      <AppTradeIcon
        iconClass="icon-action-setting"
        text={t("bd.trade.type.setting")}
        onClick={async () => {
          setOpenFissionSettingDialog(true);
        }}
        sx={{
          mr: 1,
          height: "4.5rem",
          width: "4.5rem",
          borderRadius: "0.875rem",
        }}
      />
      <TransferDialog open={openTransferDialog} onClose={() => setOpenTransferDialog(false)} />
      <SwapDailog open={openSwapDialog} onClose={() => setOpenSwapDailog(false)} />
      <FissionSettingDialog open={openFissionSettingDialog} onClose={() => setOpenFissionSettingDialog(false)} />
    </Box>
  );
};
const RWAProfile: React.FC = () => {
  return (
    <Container className="page-home tab-rwa-profile" sx={{ my: 1, backgroundImage: "none" }}>
      <AccountCard variant="mine" sx={{ borderRadius: 3.5 }} />
      <ParameterCoefficientCard sx={{ borderRadius: 3.5, my: 1 }} />
      <MyPortfolioCard sx={{ borderRadius: 3.5, my: 1 }} />
      <AppTradeBar />
    </Container>
  );
};
export default RWAProfile;
