import request from "@/utils/request";
import { AxiosPromise } from "axios";
// import { listChargeOrder } from "@/api/trade";
import {
  BdCdKeyRecordVo,
  BuyCdkOrderParams,
  BuyCdkOrderVO,
  BuyPackageOrderParams,
  BuyPackageOrderVO,
  BdAccountPackageInvestSettingItem,
} from "@/api/types/package";

/**
 * 购买套餐
 * @param data
 * @returns
 */
export function createBuyPackage(data: {
  password: string;
  packageId: string;
  payInfo: {
    portfolio: string;
    amount: number;
  }[];
}): AxiosPromise<any> {
  return request({
    url: "/app/invest/buy/package",
    method: "post",
    data,
  });
}

/**
 * 充值复投套餐
 */
export function createBuyRechargeReinvest(data: {
  password: string;
  payInfo: {
    portfolio: string;
    amount: number;
  }[];
}) {
  return request({
    url: "/app/invest/reinvest/package",
    method: "post",
    data,
  });
}

/**
 * 购买CDK
 * @param data
 */
export function createBuyCdk(data: {
  password: string;
  packageId: string;
  payInfo: {
    portfolio: string;
    amount: number;
  }[];
}): AxiosPromise<BdCdKeyRecordVo> {
  return request({
    url: "/app/invest/buy/cdkey",
    method: "post",
    data,
  });
}

/**
 * 激活CDK
 * @param data
 */
export function createActiveCdk(params: { cdKey: string }): AxiosPromise<any> {
  return request({
    url: "/app/invest/active/cdkey",
    method: "post",
    params,
  });
}

/**
 * 查询购买套餐订单
 */
export function fetchBuyPackageOrder(params: BuyPackageOrderParams): AxiosPromise<BuyPackageOrderVO[]> {
  return request({
    url: "/app/invest/list/package/order",
    method: "get",
    params,
  });
}

/**
 * 查询购买CDK订单
 */
export function fetchBuyCdkOrder(params: BuyCdkOrderParams): AxiosPromise<BuyCdkOrderVO[]> {
  return request({
    url: "/app/invest/list/cdkey/order",
    method: "get",
    params,
  });
}
/**
 *
 * 查询投资维护配置
 */
export function fetchInvestmentConfig(): AxiosPromise<BdAccountPackageInvestSettingItem[]> {
  return request({
    url: "/app/invest/investment/config",
    method: "get",
  });
}
