import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import cache from "@/plugins/cache";
import { AppState } from "@/stores/app";
import { useSnapshot } from "valtio";
import resources from "./resources";

const LangKey = "App-Lang";

const defaultLang = "en_US";

export function initI18n() {
  const resourcesMap: any = {};
  Object.keys(resources).forEach((r: string) => {
    resourcesMap[r] = {
      translation: (resources as any)[r],
    };
  });

  i18n
    // .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: cache.local.get(LangKey) || defaultLang,
      fallbackLng: defaultLang,
      debug: process.env.NODE_ENV == "production",
      // load: "currentOnly",
      // backend: {
      //   loadPath: (lngs: Array<string>, namespaces: Array<string>) => `http://spg6zzcu9.hd-bkt.clouddn.com/i18n/h5/${lngs[0]}/${namespaces[0]}.json`,
      // },
      // interpolation: {
      //   escapeValue: false, // not needed for react as it escapes by default
      // },
      resources: resourcesMap,
    });
}

export const toggleLangMode = (lang: LangType) => {
  cache.local.set(LangKey, lang);
  i18n.changeLanguage(lang);
  AppState.themeLang = lang;
};

export const useAppLang = () => {
  const snap = useSnapshot(AppState);
  const lang = (snap.themeLang || cache.local.get(LangKey) || defaultLang) as unknown as LangType;
  return { lang };
};
