import { getDefaultConfig } from "connectkit";
import { Address, Chain } from "viem";
import { createConfig, http, useAccount, useReadContract, useSwitchChain, useWriteContract } from 'wagmi';
import { bsc, bscTestnet, arbitrumSepolia } from 'wagmi/chains';

const chainSupportMap: Record<string, Chain> = {
    "1830473497408536577": bsc,
    "1830485885524475906": bscTestnet,
    "1835599008996536322": arbitrumSepolia,
}

const currentChainSupport = chainSupportMap[process.env.REACT_APP_CHAIN_SUPPORT_ID]
export const config = createConfig(
    getDefaultConfig(
        {
            chains: [currentChainSupport],
            transports: {
                [bsc.id]: http("https://ancient-neat-arrow.bsc.quiknode.pro/156dc4fd6bf4c620d00ed2d4b943ffa71b847fc6/"),
                [bscTestnet.id]: http(),
                [arbitrumSepolia.id]: http(),
            },
            // Required API Keys
            walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? '',

            // Required App Info
            appName: "RWA",
        }
    )
)
export function useSwitchDefaultNetwork() {
    const { chainId } = useAccount();
    const { chains, switchChain } = useSwitchChain()

    const switchChainWrapper = () => {
        const firstChainId = chains[0].id;
        //console.log('useSwitchDefaultNetwork before checking connect fist chain id is :', firstChainId);
        //console.log('useSwitchDefaultNetwork before checking need chain id is :', chainId);
        if (firstChainId != chainId) {
            //console.log("useSwitchDefaultNetwork ...", firstChainId);
            switchChain({
                chainId: firstChainId
            });
        }
    }

    return { switchDefaultChain: switchChainWrapper }
}

export function checkIfDefaultChain(currentChainId?: number) {
    const { chains } = useSwitchChain()
    return chains[0].id == currentChainId
}

export function useErc20Balance(tokenContractAddress: string, userAddress: string) {
    return useReadContract({
        address: tokenContractAddress as Address,
        abi: [
            {
                "constant": true,
                "inputs": [{ "name": "_owner", "type": "address" }],
                "name": "balanceOf",
                "outputs": [{ "name": "balance", "type": "uint256" }],
                "type": "function"
            },
        ],
        functionName: 'balanceOf',
        args: [userAddress],
    });
}


interface WriteContractVariables {
    tokenContractAddress: string;
    payAddress: string;
    amount: number
}

export function useTransferErc20() {
    const { writeContract } = useWriteContract()
    const transferErc20 = (variables: WriteContractVariables, config?: any) =>
        writeContract({
            address: variables.tokenContractAddress as Address,
            abi: [
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "value",
                            "type": "uint256"
                        }
                    ],
                    "name": "transfer",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
            ],
            functionName: 'transfer',
            args: [variables.payAddress as Address, variables.amount as unknown as bigint],
        }, config);
    return { writeContract, transferErc20 }
}