import { Box, BoxProps, Card, CardContent, Container, Divider, Grid2, Icon, Typography } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import SwitchTab from "@/components/switch-tab";
import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { useNavigate } from "react-router-dom";
import { getAccountPortfolioInfo, getUserInfo } from "@/api/userinfo";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import DropdownMenu from "@/components/dropdown-menu";
import BigNumber from "bignumber.js";
import { BdAccountPortfolioChangeVo, queryFlowBonus, queryNumberBonus, querySpecialBonus } from "@/api/bonus";
import dayjs from "dayjs";
import { bigNumberFormat } from "@/utils/common";
interface IncomeFragementProps extends BoxProps {
  index: number;
}
const getStartOrEndDate = (key: "year" | "week" | "month") => {
  return key === "year"
    ? {
        startDate: dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD hh:mm:ss"),
        endDate: dayjs().endOf("year").format("YYYY-MM-DD hh:mm:ss"),
      }
    : key === "month"
      ? {
          startDate: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD hh:mm:ss"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD hh:mm:ss"),
        }
      : {
          startDate: dayjs().subtract(7, "day").startOf("week").format("YYYY-MM-DD hh:mm:ss"),
          endDate: dayjs().endOf("week").format("YYYY-MM-DD hh:mm:ss"),
        };
};
const IncomeFragement = ({ index }: IncomeFragementProps) => {
  const selectKey = React.useRef<"year" | "week" | "month">("year");
  const { t } = useTranslation();
  const [harvest, setHarvest] = useState('0.00');
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const pageSize = useRef(10);

  const [total, setTotal] = useState(0);
  const renderItem = useCallback((item: BdAccountPortfolioChangeVo) => {
    return (
      <Box sx={{ mt: 2 }} display="flex" justifyContent="space-between">
        <Box>
          <Typography color={item.changeType === 1 ? "" : "#E47D55"}>
            {item.changeType === 1 ? "+" : "-"}&nbsp;{bigNumberFormat(item.amount, 2)}
            <Typography component="span" sx={{ml: 1}}>{t(item.portfolio.portfolioCode)}</Typography>
          </Typography>
          <Typography color="text.disabled">{item.processDate}</Typography>
        </Box>
        {index === 1 && (
          <Typography color={item.originType === 8 ? "#32B16C" : "#E47D55"}>
            {item.originType === 8 ? t("bd.rwa.community.share") : t("bd.rwa.community.fissile")}
          </Typography>
        )}
      </Box>
    );
  }, []);

  const fetchData = async (pageNum: number): Promise<BdAccountPortfolioChangeVo[]> => {
    const res = !index
      ? await queryFlowBonus({ pageNum, pageSize: pageSize.current, ...getStartOrEndDate(selectKey.current) })
      : index === 1
        ? await queryNumberBonus({
            pageNum,
            pageSize: pageSize.current,
            ...getStartOrEndDate(selectKey.current),
          })
        : await querySpecialBonus({
            pageNum,
            pageSize: pageSize.current,
            ...getStartOrEndDate(selectKey.current),
          });
    setHarvest(bigNumberFormat(res.summary, 2));
    setTotal(res.total);
    return res.rows || [];
  };
  return (
    <Box>
      <Box sx={{ mt: 1 }} display="flex" justifyContent="space-between">
        <Box>
          <Typography component="span">{harvest}</Typography>
          <Typography component="span" color="text.disabled">
            &nbsp;{t("total.generated.value")}
          </Typography>
        </Box>
        <DropdownMenu
          onSelect={(key: string) => {
            selectKey.current = key as "year";
            setTotal(0);
            scrollListRef.current?.reload();
          }}
          selectedKey={selectKey.current}
          items={[
            {
              title: t("bd.rwa.income.nearly.year"),
              key: "year",
            },
            {
              title: t("bd.rwa.income.nearly.week"),
              key: "week",
            },
            {
              title: t("bd.rwa.income.nearly.month"),
              key: "month",
            },
          ]}
          showArrow={true}
        />
      </Box>
      <Typography color="text.disabled">
        {getStartOrEndDate(selectKey.current).startDate} ~ {getStartOrEndDate(selectKey.current).endDate}
      </Typography>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Box sx={{ height: "14.5rem", marginBottom: "10px" }}>
        <Typography variant="body1" color="text.disabled">
          {t("bd.rwa.income.detailed.records")}
        </Typography>
        <InfiniteScrollList
          ref={scrollListRef}
          pageSize={pageSize.current}
          total={total}
          idField="changeId"
          renderItem={renderItem}
          fetchData={fetchData}
        />
      </Box>
    </Box>
  );
};

export default function Income() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState(0);
  const { data: accountPortfolioInfo } = useQuery(
    {
      queryKey: ["accountPortfolio"],
      queryFn: getAccountPortfolioInfo,
    },
    queryClient
  );
  const { data: userInfo } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryClient
  );
  const handleChange = (_: any, value: number) => {
    setActiveKey(value);
  };
  const trainingRecord = accountPortfolioInfo?.data.find((item) => item.portfolio?.portfolioCode === "bd.portfolio.code.training");
  const alreadyRelease =
    BigNumber((trainingRecord && trainingRecord.total) || "0.0000").minus(BigNumber((trainingRecord && trainingRecord.balance) || "0.0000"))

  const bonusRecord = accountPortfolioInfo?.data.find((item) => item.portfolio?.portfolioCode === "bd.portfolio.code.bonus");
  const bonusAlreadyRelease =
    BigNumber((bonusRecord && bonusRecord.total) || "0.0000").minus(BigNumber((bonusRecord && bonusRecord.balance) || "0.0000"))

  const items = userInfo?.data.setting.showBucket
    ? [
        {
          label: t("bd.rwa.income.tabs.flow"),
          component: <IncomeFragement index={0} />,
        },
        {
          label: t("bd.rwa.income.tabs.number"),
          component: <IncomeFragement index={1} />,
        },
        {
          label: t("bd.rwa.income.tabs.special"),
          component: <IncomeFragement index={2} />,
        },
      ]
    : [
        {
          label: t("bd.rwa.income.tabs.flow"),
          component: <IncomeFragement index={0} />,
        },
        {
          label: t("bd.rwa.income.tabs.number"),
          component: <IncomeFragement index={1} />,
        },
      ];
 const trainingRelease = alreadyRelease.div(BigNumber(trainingRecord?.total || '0.00')).multipliedBy(100).toString();
  return (
    <Container className="income-page">
      <Card
        sx={{
          borderRadius: 3.5,
          my: 2
        }}
      >
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="body2" color="text.disabled">
                {t("bd.rwa.income.total.training.value")}
              </Typography>
              <Typography variant="body1">
                {bigNumberFormat(trainingRecord?.total,trainingRecord?.portfolio?.portfolioDecimal)}
              </Typography>
            </Box>
            <Icon className="icon-history-record" onClick={() => navigate("/app/training-history")} />
          </Box>
          <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
            <Box className="icon-ellipse" display="flex" justifyContent="center" alignItems="center" sx={{ height: "15rem", width: "15rem" }}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h5" color="text.disabled">
                  {t("bd.rwa.income.training.value.released")}
                </Typography>
                <Typography variant="h3">
                  {bigNumberFormat(trainingRelease, 2)}
                  %
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Grid2 container sx={{ mt: 1 }}>
              <Grid2 size={5}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ color: "text.disabled" }}>
                    <Icon className="icon-dot-success" sx={{ width: "0.5rem", height: "0.5rem", mr: 1 }} />
                    {t("bd.rwa.income.training.value.released")}
                  </Typography>
                  <Typography sx={{ mt: 0.5 }}>
                    {bigNumberFormat(alreadyRelease.toString(),trainingRecord?.portfolio?.portfolioDecimal)}
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 size={2}>
                <Box sx={{ height: "100%" }} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                  <Divider orientation="vertical" flexItem />
                </Box>
              </Grid2>
              <Grid2 size={5}>
                <Box>
                  <Typography sx={{ color: "text.disabled" }}>
                    <Icon className="icon-dot-error" sx={{ width: "0.5rem", height: "0.5rem", mr: 1 }} />
                    {t("bd.rwa.income.training.value.not.released")}
                  </Typography>
                  <Typography sx={{ mt: 0.5, ml: 2 }} color="#E47D55">
                    {bigNumberFormat(trainingRecord?.balance,trainingRecord?.portfolio?.portfolioDecimal)}
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        </CardContent>
      </Card>
      {userInfo?.data.setting.showBucket && (
        <Card
          sx={{
            borderRadius: 3.5,
            mt: 2,
          }}
        >
          <CardContent>
            <Typography variant="h6">{t("bd.portfolio.code.bonus")}</Typography>
            <Grid2 container sx={{ mt: 1 }}>
              <Grid2 size={5}>
                <Box>
                  <Typography sx={{ color: "text.disabled" }}>
                    <Icon className="icon-dot-success" sx={{ width: "0.5rem", height: "0.5rem", mr: 1 }} />
                    {t("bd.rwa.income.bonus.value.released")}
                  </Typography>
                  <Typography sx={{ mt: 0.5, ml: 2 }}>
                    {bigNumberFormat(bonusAlreadyRelease.toString(), bonusRecord?.portfolio?.portfolioDecimal)}
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 size={2}>
                <Box sx={{ height: "100%" }} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                  <Divider orientation="vertical" flexItem />
                </Box>
              </Grid2>
              <Grid2 size={5}>
                <Box>
                  <Typography sx={{ color: "text.disabled" }}>
                    <Icon className="icon-dot-error" sx={{ width: "0.5rem", height: "0.5rem", mr: 1 }} />
                    {t("bd.rwa.income.bonus.value.not.released")}
                  </Typography>
                  <Typography sx={{ mt: 0.5, ml: 2 }} color="#E47D55">
                    {bigNumberFormat(bonusRecord?.balance, bonusRecord?.portfolio?.portfolioDecimal)}
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>
          </CardContent>
        </Card>
      )}
      <Card
        sx={{
          borderRadius: 3.5,
          mt: 2,
        }}
      >
        <CardContent>
          <SwitchTab defaultTabIndex={activeKey} onChange={handleChange} items={items} />
        </CardContent>
      </Card>
    </Container>
  );
}
