import { proxy } from "valtio";
import WebApp from "@twa-dev/sdk";
import { PaletteMode } from "@mui/material";

type AppType = "Telegram" | "Browser";

interface AppInfo {
  /**
   * App类型
   */
  appType: AppType;

  /**
   * 电报用户编号
   */
  telegramUserId?: string;
}

interface AppStore {
  /**
   * App信息
   */
  appInfo: AppInfo;

  /**
  * App主题
  */
  themeMode: Nullable<PaletteMode>

  /**
   * App语言
   */
  themeLang: Nullable<LangType>

  /**
   * 是否显示广告页
   */
  hadShowSplash?: boolean;
}

const initialObject: AppStore = {
  themeMode: null,
  themeLang: null,
  appInfo: {
    appType: "Browser",
    get telegramUserId() {
      if (this.appType == "Telegram") {
        return WebApp.initDataUnsafe.user?.id ? WebApp.initDataUnsafe.user.id + "" : undefined;
      } else {
        return "BROWSER_00001";
      }
    },
  },
};

export const AppState = proxy(initialObject);