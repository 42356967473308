import { Box, Tab, Tabs } from "@mui/material";
import { ReactNode, useState } from "react";

interface SwitchTabItem {
    label: string;
    component: React.ReactNode;
}

interface SwitchTabProps {
    onChange: (event: React.SyntheticEvent, tabIndex: number) => void;
    items: SwitchTabItem[]
    defaultTabIndex?: number;
    suffix?: ReactNode;
}

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}


function a11yProps(index: number) {
    return {
        id: `switch-tab-${index}`,
        'aria-controls': `switch-tabpanel-${index}`,
    };
}


function TabPanel({ children, value, index, ...restProps }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`switch-tabpanel-${index}`}
            aria-labelledby={`switch-tab-${index}`}
            {...restProps}
        >
            {value === index && children}
        </div>
    );
}

export default function SwitchTab({ onChange, items, defaultTabIndex = 0, suffix, ...restProps }: SwitchTabProps) {
    const [tabIndex, setTabIndex] = useState(defaultTabIndex || 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        onChange && onChange(event, newValue);
        setTabIndex(newValue);
    };

    return <Box {...restProps}>
        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Tabs
                value={tabIndex}
                variant="fullWidth"
                onChange={handleChange}
                TabIndicatorProps={{
                    sx: {
                        backgroundColor: 'transparent',
                    },
                }}
                sx={{
                    mr: 1,
                    flexGrow: 1,
                }}
            >
                {
                    items.map((item, index) => {
                        return <Tab key={index} label={item.label} {...a11yProps(index)} />
                    })
                }
            </Tabs>
            {suffix}
        </Box>

        {
            items.map((item, index) => {
                return <TabPanel key={index} value={tabIndex} index={index}>
                    {item.component}
                </TabPanel>
            })
        }
    </Box>;
}