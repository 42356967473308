import { InputAdornment, MenuItem, Select, TextField, TextFieldProps, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import React from "react";

export interface PortfolioItem {
  id: string | number;
  label: string;
}

export interface PortfolioInputValue {
  amount: number | string;
  portfolio: PortfolioItem | null;
}

export type PortfolioInputProps = {
  value: PortfolioInputValue;
  onChange?: (newValue: PortfolioInputValue) => void;
  placeholder?: string;
  items?: PortfolioItem[];
  selectable?: boolean;
  inputable?: boolean;
  endAdornment?: React.ReactNode;
} & Omit<TextFieldProps, "onChange" | "value">;

export default function PortfolioInput({
  value,
  onChange,
  placeholder,
  items,
  selectable,
  inputable,
  endAdornment,
  ...restProps
}: PortfolioInputProps) {
  console.log("PortfolioInput render", value);
  /**
   * 处理货币改变事件
   * @param typeStr 货币选择改变值
   */
  const handlePortfolioChange = (typeStr: string | number) => {
    const newPortfolio = items?.find((r) => r.id == typeStr) || null;
    const newValue = {
      amount: '',
      portfolio: newPortfolio,
    };
    onChange && onChange(newValue);
  };

  /**
   * 处理金额输入值变动
   * @param amountStr 金额输入框变动的值
   */
  const handleValueChange = (amountStr: string) => {
    const newValue = {
      ...value,
      amount: amountStr,
    };
    onChange && onChange(newValue);
  };

  const StartAdornment = (
    <InputAdornment
      position="start"
      sx={{
        position: "relative",
      }}
    >
      {selectable ? (
        <Select
          fullWidth
          variant="standard"
          sx={{
            "&:before": {
              borderBottom: "none !important",
            },
            "&:after": {
              borderBottom: "none !important",
            },
          }}
          value={value.portfolio?.id}
          onChange={(event) => handlePortfolioChange(event.target.value)}
        >
          {items?.map((r) => {
            return (
              <MenuItem key={r.id} value={r.id}>
                {r.label}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Typography paddingLeft={3} paddingRight={3}>
          {value.portfolio?.label}
        </Typography>
      )}
    </InputAdornment>
  );

  return (
    <TextField
      {...restProps}
      variant="outlined"
      fullWidth
      type="number"
      sx={{
        mt: 1,
        "& .MuiInputBase-root": {
          paddingLeft: "0",
        },
      }}
      disabled={!inputable}
      placeholder={placeholder}
      slotProps={{
        input: {
          autoComplete: "off",
          startAdornment: StartAdornment,
          endAdornment: endAdornment,
        },
      }}
      value={value.amount == null || value.amount == "" ? "" : BigNumber(value.amount).toNumber()}
      onChange={(e) => {
        handleValueChange(e.target.value);
      }}
    />
  );
}
