import { chargeMethods } from "@/api/global";
import { ChainChargeSettingVO } from "@/api/types";
import { ReactComponent as History } from "@/assets/images/history.svg";
import SwitchTab from "@/components/switch-tab";
import { useErc20Balance, useTransferErc20 } from "@/web3";
import { AccountBalanceWalletOutlined } from "@mui/icons-material";
import { Box, BoxProps, Button, Container, InputAdornment, SvgIcon, TextField, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { formatUnits } from "viem";
import BigNumber from "bignumber.js";
import { reportChargeOrder } from "@/api/trade";
import { enqueueSnackbar } from "notistack";
import { bigNumberFormat } from "@/utils/common";

interface ChargeFragementProps extends BoxProps {
  setting: ChainChargeSettingVO;
}

const ChargeFragement = ({ setting, ...restProps }: ChargeFragementProps) => {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const account = useAccount();
  const [chargeAmount, setChargeAmount] = useState("");
  const { transferErc20 } = useTransferErc20();
  const queryClient = useQueryClient();

  const { data: erc20Balance, queryKey } = useErc20Balance(setting.chargeToken?.tokenAddress ?? "", account.address as string);
  let erc20BalanceShow = "0";
  if (erc20Balance != null) {
    erc20BalanceShow = formatUnits(erc20Balance as bigint, 18);
  }

  const afterChargeSuccess = () => {
    //清空充值金额
    setChargeAmount("");
    //刷新余额
    queryClient.invalidateQueries({ queryKey });
  };

  const handleCharge = () => {
    console.log(
      "ready to charge:",
      "\n token address => ",
      setting.chargeToken?.tokenAddress,
      "\n symbol =>",
      setting.chargeToken?.tokenSymbol ?? "",
      "\n amount => ",
      chargeAmount,
      "\n receive address => ",
      setting.receivedTokenAddress
    );

    transferErc20(
      {
        tokenContractAddress: setting.chargeToken?.tokenAddress ?? "",
        payAddress: setting.receivedTokenAddress as string,
        amount: BigNumber(chargeAmount)
          .multipliedBy(10 ** 18)
          .toNumber(),
      },
      {
        onSuccess: async (txId: string) => {
          console.log("send token tx success", txId);
          // report tx id 和 setting id
          const data = {
            txId: txId,
            settingId: setting.chainChargeSettingId,
            sendTokenAmount: chargeAmount,
          };
          const reportResult = await reportChargeOrder(data);
          console.log("reportChargeOrder", reportResult);
          enqueueSnackbar(t("bd.charge.success.message"), { variant: "success",  autoHideDuration: 2000 });

          afterChargeSuccess();
        },
        onError: (error: any) => {
          console.log("send token tx error", error);
          enqueueSnackbar("充值失败,原因:" + error, { variant: "error",  autoHideDuration: 2000 });
        },
      }
    );
  };

  return (
    <Box className="charge-frament" sx={{ mt: 1 }} {...restProps}>
      <Typography variant="body2" color="text.secondary">
        {t("bd.charge.notice", {
          portfolioType: t(setting.objectCode) ?? "",
        })}
      </Typography>

      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
        <Typography variant="body2" color="text.secondary">
          {t("bd.charge.label")}
        </Typography>
        <Box className="wallet-balance" display="flex" flexDirection="row" alignItems="center" gap={1}>
          <AccountBalanceWalletOutlined sx={{ color: "text.primary" }} />
          <Typography>{bigNumberFormat(erc20BalanceShow, 2)}</Typography>
        </Box>
      </Box>

      <TextField
        variant="outlined"
        fullWidth
        sx={{ mt: 1 }}
        placeholder={t("bd.charge.input.placeholder")}
        slotProps={{
          input: {
            autoComplete: "off",
            startAdornment: (
              <InputAdornment sx={{ width: "5rem" }} position="start">
                {t(setting.objectCode)?? ""}:
              </InputAdornment>
            ),
          },
        }}
        value={chargeAmount}
        onChange={(e) => {
          const validVlaue = e.target.value.replace(/[^0-9.]/g, "");
          setChargeAmount(validVlaue);
        }}
      />
      <Box sx={{ mt: 5 }} display="flex" flexDirection="row" flexWrap="nowrap">
        <Button
          variant="outlined"
          sx={{ flexGrow: 1 }}
          onClick={() => {
            naviate("/app/charge-history");
          }}
        >
          <SvgIcon component={History} inheritViewBox />
        </Button>

        <Button variant="contained" disabled={!chargeAmount} sx={{ ml: 1, flexGrow: 2 }} onClick={handleCharge}>
          {t("button.confirm")}
        </Button>
      </Box>
    </Box>
  );
};

export default function TradeCharge() {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    console.log(tabIndex);
  };

  const { data, isSuccess } = useQuery({
    queryKey: ["chargeMethods"],
    queryFn: chargeMethods,
  });
  const items =
    data?.data.map((r) => {
      return {
        label: t(r.objectCode) || "",
        component: <ChargeFragement setting={r} />,
      };
    }) || [];
  useEffect(() => {
    if(!data?.data.length && isSuccess){
       enqueueSnackbar(t('bd.trade.type.charge.method.empty.message'),{variant: 'warning', autoHideDuration: 2000 })
      naviate('/home/burningProfile', { replace: true })
    }
  }, [data?.data, isSuccess]);

  return (
    <Container className="page-trade-charge" sx={{ my: 1 }}>
      <Box>
        <SwitchTab onChange={handleChange} items={items} />
      </Box>
    </Container>
  );
}
