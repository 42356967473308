import { Box, Button, Card, CardContent, Container, styled, TextareaAutosize, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createCustomerServiceChat } from "@/api/support";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
     outline: 0;
  border-color: #16477F;
  border-radius: 8px;
  background: #042245;
  // &:focus {
  //   border-color: ${theme.palette.primary.light};
  // }
  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const ContactCustomer = () => {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const [form, setForm] = useState<{
    subject?: string;
    content?: {
      message: string;
      messageType: string;
    }[];
  }>({
    subject: "",
    content: [],
  });
  return (
    <Container sx={{ my: 3 }}>
      <Card variant="outlined" sx={{ borderRadius: ".7rem" }}>
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Box>
              <Typography sx={{ color: "text.secondary", mb: 1 }}>{t("bd.support.contact.coustomer.form.title")}</Typography>
            </Box>
            <TextField
              value={form?.subject}
              slotProps={{
                input: {
                  autoComplete: "off",
                },
              }}
              onFocus={(e)=>{
                setTimeout(()=> {
                  e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 500)
              }}
              onChange={(e) => {
                setForm({
                  ...form,
                  subject: e.target.value,
                });
              }}
              variant="outlined"
              fullWidth
              placeholder={t("bd.support.contact.coustomer.form.title.placeholder")}
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <Box>
              <Typography sx={{ color: "text.secondary", mb: 1 }}>{t("bd.support.contact.coustomer.form.content")}</Typography>
            </Box>
            <Textarea
              minRows={4}
              sx={{ width: 1 }}
              onFocus={(e)=>{
                setTimeout(()=> {
                  e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 500)
              }}
              placeholder={t("bd.support.contact.coustomer.form.content.placeholder")}
              value={form.content?.map((item) => item.message).join("\n")}
              onChange={(e) => {
                setForm({
                  ...form,
                  content: e.target.value.split("\n").map((item) => ({
                    message: item,
                    messageType: "0",
                  })),
                });
              }}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2, mb: 1 }}
            onClick={async () => {
              try {
              await createCustomerServiceChat(form);
                enqueueSnackbar(t("bd.support.contact.coustomer.success"), {
                  variant: "success",
                  autoHideDuration: 2000
                });
                 setTimeout(()=> {
                   naviate("/app/inbox")
                 }, 200)
              } catch (error) {
                console.error(error);
              }
            }}
          >
            {t("button.submit")}
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ContactCustomer;
