import { updatePassword } from '@/api/userinfo';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { enqueueSnackbar } from 'notistack';
import { createContext, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordInput from '../password-input';

interface SetPasswordDialogContextValue {
    isShow: boolean;
    show: () => void;
    hide: () => void
}

const SetPasswordDialogContext = createContext<SetPasswordDialogContextValue>({
    isShow: false,
    show: () => { },
    hide: () => { }
});

export const SetPasswordDialogProvider = ({ children }: { children?: ReactNode }) => {
    const [isShowDialog, setIsShowDialog] = useState(false);

    const showDialog = () => {
        setIsShowDialog(true);
    };

    const hideDialog = () => {
        setIsShowDialog(false);
    };

    return (
        <SetPasswordDialogContext.Provider value={{ isShow: isShowDialog, show: showDialog, hide: hideDialog }}>
            {children}
            <SetPasswordDialog />
        </SetPasswordDialogContext.Provider>
    );
}

export const useSetPasswordDialog = () => {
    const { show, hide } = useContext(SetPasswordDialogContext);
    return { showSetPasswordDialog: show, hidePasswordDialog: hide }
}

interface SetPasswordDialogProps {
    open: boolean;
    show: () => void;
    hide: () => void
}

export default function SetPasswordDialog() {
    const { t } = useTranslation();
    const { isShow, hide } = useContext(SetPasswordDialogContext);
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');

    const handleConfirm = async () => {
        if (password != passwordConfirm) {
            enqueueSnackbar(t("password.not.same"), {
                variant: 'error',
                autoHideDuration: 2000
            })
            return;
        }

        try {
            const { data } = await updatePassword(password);
            console.log("handleConfirm updatePassword success", data);
            enqueueSnackbar(t('password.setting.success'), {
                variant: 'success',
                autoHideDuration: 2000
            })
        } catch (err) {
            console.log('updatePassword', err);
        } finally {
            hide();
        }
    }

    return <Dialog
        className='set-password-dialog'
        open={isShow}
        onClose={() => hide()}
        fullWidth
        PaperProps={{
            component: 'form',
            sx:{
              margin: 0,
              width:'calc(100% - 32px)'
            }
        }}>

        <DialogTitle>{t("setting.trade.password")}</DialogTitle>

        <IconButton
            aria-label="close"
            onClick={() => hide()}
            sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
            })}
        >
            <CloseIcon />
        </IconButton>

        <DialogContent sx={{ px: '1.125rem', py: '0.625rem' }}>
            <Box className="form-item" sx={{ mb: 2.25 }}>
                <Typography className="form-item-key">{t('setting.trade.password.field.label')}</Typography>
                <PasswordInput
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t('bd.input.password.placeholder')}
                    onFocus={(e)=>{
                        setTimeout(()=> {
                            e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                        }, 300)
                    }}
                />
            </Box>

            <Box className="form-item" sx={{ mb: 2.25 }}>
                <Typography className="form-item-key">{t('setting.trade.password.field.label.confirm')}</Typography>
                <PasswordInput
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    placeholder={t('bd.input.password.confirm.placeholder')}
                    onFocus={(e)=>{
                        setTimeout(()=> {
                            e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                        }, 300)
                    }}
                />
            </Box>
        </DialogContent>

        <DialogActions
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                pt: '0.625rem',
                pb: '1.25rem',
                mx: '1rem'
            }}>
            <Button variant='outlined' onClick={() => hide()} color="primary" style={{ flexGrow: 1 }}>
                {t("button.cancel")}
            </Button>

            <Button variant='contained' onClick={handleConfirm} color="primary" style={{ flexGrow: 1, minWidth: 'unset' }}>
                {t("button.confirm")}
            </Button>
        </DialogActions>

        <Container style={{ textAlign: 'center', paddingBottom: "1rem" }}>
            <Typography variant='body2' color='text.secondary'>{t("bd.password.forget.tips")}</Typography>
        </Container>
    </Dialog>
}