import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import PasswordInput from "@/components/password-input";

interface EnterPasswordDialogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  onConfirm?: () => void;
}
export default function EnterPasswordDialog({ open, onClose, password, onConfirm, setPassword, ...restProps }: EnterPasswordDialogProps) {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleConfirm = async () => {
    try {
      await onConfirm?.();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx: {
          margin: 0,
          width: "calc(100% - 32px)",
        },
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.rwa.package.buy.enter.password.dialog.title")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: "1rem", py: 2 }}>
        <Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {t("bd.rwa.package.buy.enter.password.dialog.title")}
            </Typography>
          </Box>
          <PasswordInput
            onFocus={(e) => {
              setTimeout(() => {
                e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
              }, 500);
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("bd.input.password.placeholder")}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button variant="outlined" sx={{ flexGrow: 1 }} onClick={handleClose}>
          {t("button.cancel")}
        </Button>
        <Button variant="contained" sx={{ ml: 1, flexGrow: 2 }} onClick={handleConfirm}>
          {t("button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
