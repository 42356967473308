import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchBuyPackageOrder } from "@/api/package";
import { BuyPackageOrderVO } from "@/api/types/package";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import { bigNumberFormat } from "@/utils/common";
const HarvestCard = ({ collapsed, payInfo }: { collapsed: boolean; payInfo: { amount: string; portfolio: string }[] }) => {
  const { t } = useTranslation();
  return (
    <Card
      className="list-item-card"
      sx={collapsed ? { bgcolor: "#0A3566", borderRadius: 1.5 } : { display: "none", bgcolor: "#0A3566", borderRadius: 1.5 }}
    >
      <CardContent sx={{ paddingBottom: "0.5rem !important", padding: 1.5 }}>
        {payInfo.map((item, index) => (
          <Box key={index}>
            <Typography color="#595959" component="span">
              {t(item.portfolio)}
            </Typography>
            <Typography component="span">{bigNumberFormat(item.amount)}</Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};
export default function PackageHistory() {
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const pageSize = useRef(10);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const renderItem = useCallback((item: BuyPackageOrderVO & { collapsed: boolean }, index?: number, data?: any[]) => {
    return (
      <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
        <CardContent>
          <Box>
            <Typography color="#595959" component="span">
              {t("bd.rwa.package.buy.hisotry.type")}
            </Typography>
            <Typography component="span">{t(item.packageCode)}</Typography>
          </Box>
          <Box>
            <Typography color="#595959" component="span">
              {t("package.buy.type.buy")}
            </Typography>
            <Typography
              component="span"
              onClick={() => {
                const newItems = [...(data || [])];
                newItems[index || 0].collapsed = !newItems[index || 0].collapsed;
                scrollListRef.current?.setItems(newItems);
              }}
            >
              <Typography component="span" sx={{ verticalAlign: "middle" }}>
                {bigNumberFormat(item.recordAmount)}
              </Typography>
              <Typography component="span" color="text.secondary" sx={{ verticalAlign: "-0.4rem" }}>
                {!item.collapsed ? <ArrowDropDownSharp /> : <ArrowDropUpSharp />}
              </Typography>
            </Typography>
          </Box>
          <HarvestCard collapsed={item.collapsed} payInfo={item.payInfo} />
          <Box>
            <Typography color="#595959" component="span">
              {t("bd.order.create.date")}
            </Typography>
            <Typography color="#595959" component="span">
              {item.createTime || "--"}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }, []);

  const fetchData = async (page: number): Promise<BuyPackageOrderVO[]> => {
    const data = await fetchBuyPackageOrder({ pageNum: page, pageSize: pageSize.current });
    setTotal(data.total);
    console.log(data.rows);

    return data.rows.map((item) => ({ ...item, collapsed: false })) || [];
  };
  return (
    <Box className="page-charge-history" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        pageSize={pageSize.current}
        total={total}
        idField="packageRecordId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
    </Box>
  );
}
