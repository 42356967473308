import { Palette } from "@mui/material"
import { TypographyOptions } from "@mui/material/styles/createTypography"

type TypographyConfigType = (palette: Palette) => TypographyOptions

const TypographyConfig: TypographyConfigType = (palette: Palette) => {
    return {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 12,
        fontWeightRegular: 500
    }
}

export default TypographyConfig