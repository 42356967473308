import en_US from './i18n/en_US.json';
import ja_JP from './i18n/ja_JP.json';
import ko_KR from './i18n/ko_KR.json';
import th_TH from './i18n/th_TH.json';
import vi_VN from './i18n/vi_VN.json';
import zh_CN from './i18n/zh_CN.json';
import zh_TW from './i18n/zh_TW.json';

const resources = {
  en_US,
  ja_JP,
  ko_KR,
  th_TH,
  vi_VN,
  zh_CN,
  zh_TW
} as const;

export default resources;
