import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { enqueueSnackbar } from "notistack";
import { ReactComponent as History } from "@/assets/images/history.svg";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getPlaceTeamMemberList, submitPlaceTeamMember } from "@/api/userinfo";
import React, { useEffect,  useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
interface FissionSettingDialogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}
const StyledPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: "var(--mui-shape-borderRadius) !important",
  borderColor: "rgba(var(--mui-palette-common-onBackgroundChannel) / 0.23) !important",
  color: "#E44D4D",
  marginTop: 5,
  marginBottom: 20,
  height: 40,
  lineHeight: "40px",
}));
export default function FissionSettingDialog({ open, onClose, ...restProps }: FissionSettingDialogProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [waitActive, setWaitActive] = useState<string[]>([]);
  const [inviteeUid, setInviteeUid] = useState("");
  const [leaderUid, setLeaderUid] = useState("");
  const naviate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  //处理关闭弹id
  const handleClose = () => {
    onClose();
  };
  const { data } = useQuery(
    {
      queryKey: ["placeTeamMemberList"],
      queryFn: getPlaceTeamMemberList,
    },
    queryClient
  );
  useEffect(() => {
    if (data?.data) {
      const waitActive = data?.data.map((item) => item.uid);
      setWaitActive(waitActive);
    }
  }, [data?.data]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx: {
          margin: 0,
          width: "calc(100% - 32px)",
        },
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.trade.type.setting")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" color="text.secondary">
              {t("bd.rwa.profile.fission.setting.await")}
            </Typography>
          </Box>
          {!waitActive.length ? (
            <StyledPaper>{t("bd.rwa.profile.fission.setting.not.active.account")}</StyledPaper>
          ) : (
            <>
              <TextField
                fullWidth
                variant="outlined"
                select
                value={activeIndex}
                sx={{
                  mt: 1,
                  mb: 1,
                  "& .MuiInputBase-root": {
                    paddingLeft: "0",
                  },
                  "& .MuiInputBase-input": {
                    paddingLeft: "1rem",
                  },
                  "& .MuiOutlinedInput-input.Mui-disabled": {
                    WebkitTextFillColor: "#fff",
                  },
                }}
                slotProps={{
                  // select: {
                  //   IconComponent: "span",
                  // },
                  input: {
                    startAdornment: (
                      <InputAdornment position="end" onClick={() => setInviteeUid(waitActive[activeIndex])}>
                        <Icon className="icon-action-add" sx={{ width: "0.9rem", height: "0.9rem" }} />
                      </InputAdornment>
                    ),
                  },
                }}
                onChange={(e) => setActiveIndex(Number(e.target.value))}
              >
                {waitActive.map((item, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      UID:{item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </>
          )}
        </Box>
        <Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" color="text.secondary">
              {t("bd.rwa.profile.fission.setting.set.relationship")}
            </Typography>
            <Typography variant="body2" color="text.disabled">
              {t("bd.rwa.profile.fission.setting.non-relational.accounts.cannot.bound")}
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body1" color="text.secondary">
              {t("bd.rwa.profile.fission.setting.superior.id")}
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t("bd.support.contact.coustomer.content.placeholder")}
            value={leaderUid}
            onFocus={(e)=>{
              setTimeout(()=> {
                e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }, 500)
            }}
            sx={{
              mt: 1,
              "& .MuiInputBase-root": {
                paddingLeft: "0",
              },
            }}
            onChange={(e) => setLeaderUid(e.target.value)}
            slotProps={{
              input: {
                autoComplete: "off",
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" color="text.secondary">
              {t("bd.rwa.profile.fission.setting.subordinate.id")}
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            disabled
            placeholder={t("bd.support.contact.coustomer.content.placeholder")}
            value={inviteeUid}
            sx={{
              mt: 1,
              "& .MuiInputBase-root": {
                paddingLeft: "0",
              },
              "& .MuiOutlinedInput-input.Mui-disabled": {
                WebkitTextFillColor: "#fff",
              },
            }}
            slotProps={{
              input: {
                autoComplete: "off",
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button variant="outlined" sx={{ flexGrow: 1 }} onClick={() => naviate("/app/fissionSetting-history")}>
          <SvgIcon component={History} inheritViewBox />
        </Button>
        <Button
          variant="contained"
          sx={{ ml: 1, flexGrow: 2 }}
          disabled={!leaderUid && !inviteeUid}
          onClick={async () => {
            await submitPlaceTeamMember({
              inviteeUid,
              leaderUid,
            });
            onClose();
            setLeaderUid("");
            setInviteeUid("");
            setWaitActive(waitActive.filter(item => item !== inviteeUid))
            enqueueSnackbar(t("bd.rwa.profile.fission.setting.binding.relationship.successful"), { variant: "success", autoHideDuration: 2000 });
          }}
        >
          {t("button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
