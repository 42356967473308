import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import PasswordInput from "../password-input";
import { enqueueSnackbar } from "notistack";
import { updatePassword } from "@/api/userinfo";
interface UpdateDailogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}

export default function UpdatePasswordDailog({ open, onClose, ...restProps }: UpdateDailogProps) {
  const { t } = useTranslation();
  const [passwordForm, setPasswordForm] = useState<{
    newPassword?: string;
    oldPassword?: string;
    confirmPassword?: string;
  }>();
  return (
    <Dialog
      className="trade-update-passowrd-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx:{
          margin: 0,
          width:'calc(100% - 32px)'
        }
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.input.password.reset")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Box className="form-item">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
            <Typography className="form-item-key" variant="body2" color="text.secondary">
              {t("bd.dialog.update-password.old-password")}
            </Typography>
          </Box>
          <PasswordInput
            value={passwordForm?.oldPassword}
            placeholder={t("bd.input.password.placeholder")}
            onChange={(e) => setPasswordForm({ ...passwordForm, oldPassword: e.target.value })}
          />
        </Box>

        <Box className="form-item">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
            <Typography className="form-item-key" variant="body2" color="text.secondary">
              {t("bd.dialog.update-password.new-password")}
            </Typography>
          </Box>
          <PasswordInput
            value={passwordForm?.newPassword}
            placeholder={t("bd.input.password.placeholder")}
            onChange={(e) => setPasswordForm({ ...passwordForm, newPassword: e.target.value })}
          />
        </Box>

        <Box className="form-item" sx={{ mb: 0 }}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
            <Typography className="form-item-key" variant="body2" color="text.secondary">
              {t("bd.dialog.update-password.confirm-password")}
            </Typography>
          </Box>
          <PasswordInput
            value={passwordForm?.confirmPassword}
            placeholder={t("bd.input.password.placeholder")}
            onChange={(e) => setPasswordForm({ ...passwordForm, confirmPassword: e.target.value })}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: "0.625rem",
          mx: "1rem",
        }}
      >
        <Button variant="outlined" sx={{ flexGrow: 1 }} onClick={() => onClose()}>
          {t("button.cancel")}
        </Button>

        <Button variant="contained" sx={{ ml: 1, flexGrow: 1 }} onClick={ async ()=> {
          if(passwordForm?.confirmPassword !== passwordForm?.newPassword) {
            return enqueueSnackbar(t("bd.update.passowrd.dialog.confirm.password.verify.error.message"), { variant: "error",  autoHideDuration: 2000 })
          }
           try {
             await updatePassword(passwordForm?.newPassword || '', passwordForm?.oldPassword);
             enqueueSnackbar(t("bd.update.passowrd.dialog.success.message"), { variant: "success",  autoHideDuration: 2000 });
             onClose();
           } catch (e) {
            console.log(e)
            //  enqueueSnackbar(t("bd.update.passowrd.dialog.fail.message"), { variant: "error",  autoHideDuration: 2000 });
           }
        }}>
          {t("button.confirm")}
        </Button>
      </DialogActions>
      <Typography sx={{ textAlign: "center", pb: "0.625rem", pt: "0.625rem", color: "#fff" }}>{t("bd.update.passowrd.dialog.tips")}</Typography>
    </Dialog>
  );
}
