import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { BdBonusBucketVo, queryBonusBySpecialRecord } from "@/api/bonus";
import BigNumber from "bignumber.js";
import DateFilterDialog, { FilterDialogValue } from "@/components/date-filter-dialog";
import { useTranslation } from "react-i18next";
import { bigNumberFormat } from "@/utils/common";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
const HarvestCard = ({ collapsed, payInfo }: { collapsed: boolean; payInfo: { amount: string; portfolio: string }[] }) => {
  const { t } = useTranslation();
  return (
    <Card
      className="list-item-card"
      sx={collapsed ? { bgcolor: "#262626", borderRadius: 1.5 } : { display: "none", bgcolor: "#262626", borderRadius: 1.5 }}
    >
      <CardContent sx={{ paddingBottom: "0.5rem !important", padding: 1.5 }}>
        {payInfo.map((item, index) => (
          <Box key={index}>
            <Typography color="text.disabled" component="span">
              {t(item.portfolio)}
            </Typography>
            <Typography component="span">{bigNumberFormat(item.amount)}</Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};
export default function SpecialBonus() {
  const pageSize = useRef(10);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();
  const [summary, setSummary] = useState("0.00");
  const [openFilterDailog, setOpenFilterDailog] = useState(false);
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const filterValue = useRef<FilterDialogValue>({
    startDate: null,
    endDate: null,
  });
  const renderItem = useCallback((item: BdBonusBucketVo & { collapsed: boolean }, index?: number, data?: any[]) => {
    return (
      <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
        <CardContent>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.bonus.date")}
            </Typography>
            <Typography component="span">{item.createTime}</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.bonus.come.id")}
            </Typography>
            <Typography component="span">{item.relationUidPath?.split("/")[0]}</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.bonus.from.quantity")}
            </Typography>
            <Typography component="span">{bigNumberFormat(item.packageRecordAmount)}</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.bonus.reward.percentage")}
            </Typography>
            <Typography component="span">{bigNumberFormat(item.bucketLever)}%</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.bonus.calc.percentage")}
            </Typography>
            <Typography component="span">
              {bigNumberFormat(
                BigNumber(item.bucketBonusAmount || "0.0000")
                  .div(BigNumber(item.packageRecordAmount || "0.0000"))
                  .multipliedBy(100)
                  .toString()
              )}
              %
            </Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.bonus.amount.payable")}
            </Typography>
            <Typography
              component="span"
              onClick={() => {
                const newItems = [...(data || [])];
                newItems[index || 0].collapsed = !newItems[index || 0].collapsed;
                scrollListRef.current?.setItems(newItems);
              }}
            >
              <Typography component="span" sx={{ verticalAlign: "middle" }}>
                {bigNumberFormat(item.bucketBonusAmount)}
              </Typography>
              {item.harvestDetailItems.length ? (
                <Typography component="span" color="text.secondary" sx={{ verticalAlign: "-0.4rem" }}>
                  {!item.collapsed ? <ArrowDropDownSharp /> : <ArrowDropUpSharp />}
                </Typography>
              ) : null}
            </Typography>
          </Box>
          {item.harvestDetailItems.length ? <HarvestCard collapsed={item.collapsed} payInfo={item.harvestDetailItems} /> : null}
        </CardContent>
      </Card>
    );
  }, []);

  const fetchData = async (pageNum: number): Promise<BdBonusBucketVo[]> => {
    const res = await queryBonusBySpecialRecord({
      pageNum,
      pageSize: pageSize.current,
      startDate: filterValue.current.startDate?.format("YYYY-MM-DD HH:mm:ss"),
      endDate: filterValue.current.endDate?.format("YYYY-MM-DD HH:mm:ss"),
    });
    setSummary(bigNumberFormat(res.summary));
    setTotal(res.total);
    return res.rows.map((item) => ({ ...item, collapsed: false })) || [];
  };

  const handleOnFilter = (newValue: FilterDialogValue) => {
    console.log("handleOnFilter ===> ", JSON.stringify(newValue));
    filterValue.current.startDate = newValue.startDate;
    filterValue.current.endDate = newValue.endDate;
    scrollListRef.current?.reload();
  };
  const ListHeader = (
    <Container>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6" sx={{ pl: 2 }}>
          {t("bd.rwa.bonus.cumulative")} {summary}
        </Typography>
        {/*<Icon className="icon-select-date" onClick={() => setOpenFilterDailog(true)} />*/}
      </Box>
    </Container>
  );
  return (
    <Box className="page-withdraw-history" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        pageSize={pageSize.current}
        total={total}
        header={ListHeader}
        idField="bonusOriginId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
      <DateFilterDialog open={openFilterDailog} value={filterValue.current} onClose={() => setOpenFilterDailog(false)} onFilter={handleOnFilter} />
    </Box>
  );
}
