import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { listTransferOrder } from "@/api/trade";
import './transfer-history.less'
import { useTranslation } from "react-i18next";
import { transferMethods } from "@/api/global";
import { TransferRecordVO } from "@/api/types/transferRecord";
import { useQuery } from "@tanstack/react-query";
import { bigNumberFormat } from "@/utils/common";
export default function TransferHistory() {
	const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const pageSize = useRef(10);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const { data } = useQuery({
    queryKey: ["transferMethods"],
    queryFn: transferMethods,
  });
  const renderItem = useCallback((item: TransferRecordVO) => {
    const current = data?.data.find(transferItem => transferItem.portfolio?.portfolioId === item.portfolioId)

    return (
      <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
        <CardContent>
          <Box>
            <Typography color="text.disabled" component="span">
              UID
            </Typography>
            <Typography component="span">{item.receiverUid}</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.profile.transfer.dialog.transfer.amount")}
            </Typography>
            <Typography component="span">{bigNumberFormat(item.portfolioAmount,current?.portfolio?.portfolioDecimal)} {t(current?.portfolio?.portfolioCode)}</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.order.create.date")}
            </Typography>
            <Typography color="text.disabled" component="span">{item.createTime || "--"}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }, [data?.data]);

  const fetchData = async (page: number): Promise<TransferRecordVO[]> => {
      const data = await listTransferOrder({ pageNum: page, pageSize: pageSize.current });
      setTotal(data.total);
    return data.rows || [];
  };
	return ( <Box className="page-transfer-history" sx={{ height: "100%", position: "relative" }}>
		<InfiniteScrollList
		  ref={scrollListRef}
		  pageSize={pageSize.current}
		  total={total}
		  idField="transferRecordId"
		  renderItem={renderItem}
		  fetchData={fetchData}
		/>
	  </Box>)
}