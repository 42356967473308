import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface CommunitySearchDialogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  onFilter?: (value?: string) => void;
  searchNodeUId?: string;
}
export default function CommunitySearchDialog({ open, onClose, searchNodeUId, onFilter, ...restProps }: CommunitySearchDialogProps) {
  const { t } = useTranslation();
  const [uId, setUId] = useState(searchNodeUId);
  //处理关闭弹id
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx: {
          margin: 0,
          width: "calc(100% - 32px)",
        },
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.rwa.community.dialog.filter.search")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="body1" color="text.secondary">
              {t("bd.rwa.community.dialog.filter.uid.number")}
            </Typography>
          </Box>
          <TextField
            fullWidth
            placeholder={t("bd.rwa.community.dialog.filter.uid.number.placeholder")}
            variant="outlined"
            onFocus={(e)=>{
              setTimeout(()=> {
                e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }, 500)
            }}
            sx={{
              "& .MuiOutlinedInput-input": {
                paddingLeft: "0.3rem",
              },
            }}
            value={uId}
            onChange={(e) => setUId(e.target.value)}
            slotProps={{
              input: {
                autoComplete: "off",
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 0 }}>
                    <Icon className="icon-glass-grey" sx={{ width: "0.8rem", height: "0.8rem" }} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button
          variant="contained"
          sx={{ ml: 1 }}
          onClick={() => {
            onFilter?.(uId);
            setUId("");
            onClose();
          }}
        >
          {t("bd.rwa.community.dialog.filter.search")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
