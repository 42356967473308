import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

export interface FilterDialogValue {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  currency?: string;
}

interface FilterDialogProps extends Omit<DialogProps, "onClose"> {
  value: FilterDialogValue;
  tokenSelectList?: DictDataOption[];
  onFilter: (newValue: FilterDialogValue) => void;
  onClose: () => void;
}

export default function FilterDialog({
  value,
  open,
  onClose,
  onFilter,
  tokenSelectList = [
    {
      label: "USDT",
      value: "USDT",
    },
    {
      label: "KC",
      value: "KC",
    },
  ],
  ...restProps
}: FilterDialogProps) {
  const [stateValue, setStateValue] = useState<FilterDialogValue>({
    currency: "All",
    startDate: null,
    endDate: null
  });
  const [visible, setVisible] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const prevVisible = useRef(visible);
  useEffect(() => {
    if (prevVisible.current && !visible) {
      anchorRef.current!.focus();
    }
    prevVisible.current = visible;
  }, [visible]);
  const { t } = useTranslation();

  useEffect(() => {
    setStateValue(value);
  }, []);

  const handleReset = () => {
    setStateValue({ currency: "All", endDate: null, startDate: null });
  };

  const handleConfirm = () => {
    onFilter(stateValue);
    onClose();
  };
  return (
    <Dialog
      className="trade-filter-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx:{
          margin: 0,
          width:'calc(100% - 32px)'
        }
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.dialog.tilte.filter")}</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Box className="form-item">
          <Typography className="form-item-key">{t("bd.dialog.start.date")}</Typography>
          <DatePicker
            className="form-item-value"
            value={stateValue.startDate}
            onChange={(newValue) => setStateValue({ ...stateValue, startDate: dayjs(newValue) })}
          />
        </Box>

        <Box className="form-item">
          <Typography className="form-item-key">{t("bd.dialog.end.date")}</Typography>
          <DatePicker
            className="form-item-value"
            value={stateValue.endDate}
            onChange={(newValue) => setStateValue({ ...stateValue, endDate: dayjs(newValue) })}
          />
        </Box>

        <Box className="form-item" sx={{ mb: 0 }}>
          <Typography className="form-item-key">{t("bd.dialog.token.choice")}</Typography>
          <TextField
            variant="outlined"
            fullWidth
            sx={{
              "& .MuiInputBase-root": {
                paddingLeft: "0",
              },
            }}
            slotProps={{
              input: {
                readOnly: true,
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Select
                        fullWidth
                        variant="standard"
                        sx={{
                          "&:before": {
                            borderBottom: "none !important",
                          },
                          "&:after": {
                            borderBottom: "none !important",
                          },
                        }}
                        value={stateValue.currency}
                        onChange={(event) =>{
                          setStateValue({ ...stateValue, currency: event.target.value as string });
                        }}
                      >
                        {(
                          [
                            {
                              label: "bd.select.all",
                              value: "All",
                            },
                          ] as DictDataOption[]
                        )
                          .concat(tokenSelectList)
                          ?.map((r) => {
                            return (
                              <MenuItem key={r.value} value={r.value}>
                                {t(r.label)}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </InputAdornment>
                  </>
                ),
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button variant="outlined" onClick={handleReset} color="primary" style={{ flexGrow: 1 }}>
          {t("button.reset")}
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="primary" style={{ flexGrow: 1, minWidth: "unset" }}>
          {t("button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
