import { useAppLang, toggleLangMode } from "@/lang";
import { Box, BoxProps, Button, ClickAwayListener, Grow, MenuList, Paper, Popper } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { type SyntheticEvent, type MouseEvent,  type KeyboardEvent, useState, useRef, useEffect } from "react";

interface OptionItem {
  i18n: LangType;
  title: string;
}

const options: OptionItem[] = [
  {
    i18n: "en_US",
    title: "English",
  },
  {
    i18n: "zh_CN",
    title: "简体中文",
  },
  // {
  //   i18n: "zh_TW",
  //   title: "繁体中文",
  // },
  // {
  //   i18n: "vi_VN",
  //   title: "Tiếng Việt",
  // },
  // {
  //   i18n: "ja_JP",
  //   title: "日本語",
  // },
  // {
  //   i18n: "ko_KR",
  //   title: "한국어",
  // },
  // {
  //   i18n: "th_TH",
  //   title: "ไทย",
  // },
];

const optionsMap: any = {};

options.forEach((r) => {
  optionsMap[r.i18n] = r;
});

export default function SimpleListMenu(props: BoxProps) {
  const { lang } = useAppLang();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleMenuItemClick = (
      event: MouseEvent<HTMLElement>,
      lang: LangType,
  ) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
          return;
      }

      setOpen(false);
      toggleLangMode(lang);
  };

  return (
    <Box {...props}>
      <Button
        ref={anchorRef}
        id="composition-button"
        variant="outlined"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{ minWidth: "6.5rem", borderRadius: "1rem", height: "1.75rem" }}
        onClick={handleToggle}
      >
        {optionsMap[lang].title}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                  {options.map((option) => (
                    <MenuItem
                      key={option.i18n}
                      selected={option.i18n === lang}
                      onClick={(event) => handleMenuItemClick(event, option.i18n)}
                      sx={{ width: "6.5rem" }}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
