import { Typography, TypographyProps } from "@mui/material";

interface AddressLabelProps extends TypographyProps {
    address?: string,
    viewMode?: 'brief' | 'detail'
    size?: 'small' | 'medium'
}

export default function AddressLabel({ address, viewMode, size, sx, ...restProps }: AddressLabelProps) {
    let addessRender = address
    if (address && viewMode != "detail") {
        addessRender = address.substring(0, 5) + '....' + address.substring(address.length - 6, address.length);
    }

    return <Typography
        {...restProps}
        variant={size == 'small' ? 'caption' : 'body1'}
        sx={{
            ...sx,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-all',
            overflowWrap: 'break-word'
        }}
    >
        {addessRender}
    </Typography>
}