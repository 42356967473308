import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Container, Icon, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FissionSettingFilterDialog from "@/pages/rwa/dialog/fission-setting-filter-dialog";
import { queryTeamMemberRecord } from "@/api/userinfo";
import { SysRelationChangeLogVo } from "@/api/types";

export default function FissionSettingHistory() {
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const pageSize = useRef(10);
  const [total, setTotal] = useState(0);
  const [uId, setUid] = useState('')
  const { t } = useTranslation();
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const renderItem = useCallback((item: SysRelationChangeLogVo) => {
    return (
      <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
        <CardContent>
          <Box>
            <Typography color="text.disabled" component="span">
              UID
            </Typography>
            <Typography component="span">{item.uid}</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.profile.fission.setting.history.display.location.id")}
            </Typography>
            <Typography component="span">{item.afterUid}</Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.rwa.profile.fission.setting.history.completers")}
            </Typography>
            <Typography color="text.disabled" component="span">
              {item.createName || "--"}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.order.create.date")}
            </Typography>
            <Typography color="text.disabled" component="span">
              {item.createTime || "--"}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }, []);

  const fetchData = async (page: number): Promise<SysRelationChangeLogVo[]> => {
    const data = await queryTeamMemberRecord({ pageNum: page,  uid: uId, pageSize: pageSize.current });
    setTotal(data.total);
    return data.rows || [];
  };

  const ListHeader = (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Icon className="icon-search" onClick={() => setOpenFilterDialog(true)} />
    </Container>
  );

  return (
    <Box className="page-fission-setting-history" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        header={ListHeader}
        pageSize={pageSize.current}
        total={total}
        idField="relationChangeId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
      <FissionSettingFilterDialog uId={uId} open={openFilterDialog} onClose={() => setOpenFilterDialog(false)}  onFilter={(_uId:string)=>{
        setUid(_uId)
        scrollListRef.current?.reload();

      }} />
    </Box>
  );
}
