import { Box, Button, Container, InputAdornment, TextField, Typography } from "@mui/material";
import "./invite.less";
import kryptonLogo from "@/assets/images/kryptonLogo.gif";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "@/api/userinfo";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
// import QRCode from "qrcode";
import { enqueueSnackbar } from "notistack";
import {  useCopyToClipboard } from 'react-use'
export default function Invite() {
  const { t } = useTranslation();
  const [inviteCode, setInviteCode] = useState("");
  const queryCleint = useQueryClient();
  const { data: userInfo } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryCleint
  );
  useEffect(() => {
    if (userInfo?.data) {
      setInviteCode(userInfo.data.inviteCode);
    }
  }, [userInfo?.data]);
  const [state, copyToClipboard] = useCopyToClipboard();
  return (
    <Box className="invite-page">
      <Box sx={{ pt: "40%", textAlign: "center" }}>
        <img className="logo" src={kryptonLogo} style={{ height: "6rem" }} />
      </Box>
      <Box className="space" sx={{ width: "100%", height: "10rem" }}></Box>
      <Container sx={{ paddingBottom: "5rem" }}>
        <Box marginBottom={1}>
          <Typography>{t("bd.rwa.invite.code.title")}</Typography>
        </Box>
        <Box marginBottom={1}>
          <TextField
            fullWidth
            sx={{
              backgroundColor: "background.default",
              "& .MuiInputBase-root": {
                color: "var(--mui-palette-text-primary)",
              },
            }}
            value={inviteCode}
            slotProps={{
              input: {
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography component="span" color="text.primary">
                      {t("bd.rwa.invite.code.label")}
                    </Typography>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
        <Box marginBottom={3}>
          <Typography variant="body2" color="text.secondary">
            {t("bd.rwa.invite.code.tips")}
          </Typography>
        </Box>
        <Box
          marginBottom={5}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            gap: "0.5rem",
          }}
        >
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  fullWidth*/}
          {/*  onClick={() => {*/}
          {/*    QRCode.toDataURL(inviteCode).then((url: string) => {*/}
          {/*      const link = document.createElement("a");*/}
          {/*      link.href = url;*/}
          {/*      link.download = "invite.png";*/}
          {/*      document.body.appendChild(link);*/}
          {/*      link.click();*/}
          {/*      setTimeout(() => {*/}
          {/*        document.body.removeChild(link);*/}
          {/*      }, 100);*/}
          {/*    });*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {t("bd.rwa.invite.code.generate.image")}*/}
          {/*</Button>*/}
          <Button
            variant="contained"
            fullWidth
            onClick={async () => {
              copyToClipboard(inviteCode)
              if(!state.error) {
                enqueueSnackbar(t("bd.rwa.invite.code.copy.success"), { variant: "success",  autoHideDuration: 2000 });
              } else {
                enqueueSnackbar(state.error.message,{ variant: "error",  autoHideDuration: 2000 })
              }
            }}
          >
            {t("bd.rwa.invite.code.copy")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
