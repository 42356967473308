import request from '@/utils/request';
import type { AppNonceBody, GenerateNonceVo, LoginData, LoginResult } from './types';
import { AxiosPromise } from 'axios';

// App端固定客户端授权id
const clientId = process.env.REACT_APP_CLIENT_ID;

/**
 * 生成随机字符串
 * @param data {AppNonceBody}
 * @returns
 */
export function generateNonce(params: AppNonceBody): AxiosPromise<GenerateNonceVo> {
  return request({
    url: '/auth/generateNonce',
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: 'post',
    data: params
  });
}

/**
 *  登录
 * @param data {LoginData}
 * @returns
 */
export function login(data: LoginData): AxiosPromise<LoginResult> {
  const params = {
    ...data,
    clientId: data.clientId || clientId,
    grantType: data.grantType || 'wallet'
  };
  return request({
    url: '/auth/login',
    headers: {
      isToken: false,
      isEncrypt: true,
      repeatSubmit: false
    },
    method: 'post',
    data: params
  });
}

/**
 * 注销
 */
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}