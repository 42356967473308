import { Box } from "@mui/material";
import PackageFragment from "../rwa/package";
import BindInviteFragement from "../rwa/bind-invite";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserInfo } from "@/api/userinfo";
import { useState, useEffect } from "react";

export default function DigitalHuman() {
  const queryClient = useQueryClient();
  const [hadBindInviter, setHadBindInviter] = useState(false);
  const { data } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryClient
  );
  useEffect(() => {
    if (data?.data && data.data.inviteMemberId !== null) {
      setHadBindInviter(true);
    }
  }, [data?.data]);

  //如果绑定了邀请人，进入套餐购买界面
  //如果没有绑定，则进入绑定邀请人界面
  return (
    <Box className="page-home tab-digital-human" height="100%">
      {hadBindInviter ? <PackageFragment /> : <BindInviteFragement onSuccess={()=> setHadBindInviter(true)} />}
    </Box>
  );
}
