import React, { useState, useEffect, useCallback, useRef, ReactNode, forwardRef, useImperativeHandle, ForwardedRef } from "react";
import { List, CircularProgress, Container, Typography, ListProps, Box, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface InfiniteScrollListProps<T> extends ListProps {
  header?: ReactNode;
  fetchData: (page: number) => Promise<T[]>;
  total?: number;
  pageSize?: number;
  renderItem: (item: T, index?: number, data?: T[]) => ReactNode;
  idField: keyof T;
}

export interface InfiniteScrollListRef {
  reload: () => void;
  items: any[];
  setItems:  React.Dispatch<React.SetStateAction<any[]>>;
}

const InfiniteScrollList = forwardRef<InfiniteScrollListRef, InfiniteScrollListProps<any>>(
  <T extends Record<string, any>>(
    { header, fetchData, renderItem, idField, total = 0, pageSize = 10 }: InfiniteScrollListProps<T>,
    ref: ForwardedRef<InfiniteScrollListRef>
  ) => {
    const [items, setItems] = useState<T[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const pageRef = useRef<number>(1);
    const [isEmpty, setIsEmpty] = useState(false);
    const isLoadingRef = useRef<boolean>(false);
    const { t } = useTranslation();
    const reload = useCallback(async () => {
      const firstPageData = await fetchData(1);
      setItems(firstPageData);
      if (firstPageData.length === 0) {
        setIsEmpty(true);
      }
      setLoading(false);
    }, [fetchData]);

    useImperativeHandle(ref, () => ({
      reload,
      items,
      setItems
    }));

    const loadMoreItems = useCallback(async () => {
      if (isLoadingRef.current || !hasMore) {
        return;
      }
      isLoadingRef.current = true;
      if (Math.ceil(total / pageSize) - pageRef.current >= 1) {
        pageRef.current = pageRef.current + 1;
      }
      const newItems = await fetchData(pageRef.current);
      if (newItems.length > 0) {
        setItems((prevItems) => [...prevItems, ...newItems]);
      } else {
        setHasMore(false);
      }
      isLoadingRef.current = false;
    }, [fetchData, hasMore]);

    useEffect(() => {
      if (items.length === 0) {
        (async () => {
          setLoading(true);
          const firstPageData = await fetchData(pageRef.current);
          if (pageRef.current === 1) {
            if (firstPageData.length === 0) {
              setIsEmpty(true);
            } else {
              setIsEmpty(false);
            }
          }
          setItems(firstPageData);
          if (firstPageData.length === 0) {
            setHasMore(false);
          }
          setLoading(false);
        })();
      }
    }, []);

    useEffect(() => {
      if (total) {
        const totalPages = Math.ceil(total / pageSize);
        if (totalPages - pageRef.current <= 0) {
          setHasMore(false);
        }
      } else {
        setItems([]);
        setLoading(true);
        setIsEmpty(false);
        setHasMore(true);
        pageRef.current = 1;
      }
    }, [total, pageSize, pageRef.current]);

    const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
      const target = event.currentTarget;
      if (target.scrollHeight - (target.scrollTop + target.clientHeight) < 5 && hasMore && !isLoadingRef.current) {
        loadMoreItems();
      }
    };

    return (
      <>
        {!isEmpty && (
          <List onScroll={handleScroll} style={{ height: "100%", overflow: "auto", padding: 0 }}>
            {header}
            {items.map((item,index) => (
              <React.Fragment key={item[idField] as string}>{renderItem(item, index, items)}</React.Fragment>
            ))}

            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {!hasMore && (
              <Container sx={{ textAlign: "center", p: 1, color: "action.disabled" }}>
                <Typography variant="body2" component="span">
                  {t("bd.infinite.scroll.list.nomoredata")}
                </Typography>
              </Container>
            )}
          </List>
        )}
        {isEmpty && (
          <>
            {header}
            <Container sx={{ textAlign: "center", p: 1, color: "text.disabled", height: "100%" }}>
              <Box display="flex" justifyContent="center" sx={{ pt: "10%" }}>
                <Icon className="icon-empty" sx={{ height: "6rem", width: "8.67rem" }} />
              </Box>
              <Typography variant="body2" component="span" sx={{ fontSize: "1rem", lineHeight: "1.67rem" }}>
                {t("bd.list.empty")}
              </Typography>
            </Container>
          </>
        )}
      </>
    );
  }
);

InfiniteScrollList.displayName = "InfiniteScrollList";
export default InfiniteScrollList;
