import { swapMethods } from "@/api/global";
import { submitSwapOrder } from "@/api/trade";
import { AccountPortfolioVO } from "@/api/types";
import { SwapSettingVo } from "@/api/types/swapSetting";
import { getAccountPortfolioInfo } from "@/api/userinfo";
import { ReactComponent as History } from "@/assets/images/history.svg";
import PasswordInput from "@/components/password-input";
import PortfolioInput, { PortfolioInputValue, PortfolioItem } from "@/components/portfolio-input";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, SvgIcon, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo,  useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { bigNumberFormat } from "@/utils/common";

interface SwapDailogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}

export default function SwapDailog({ open, onClose, ...restProps }: SwapDailogProps) {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const queryClient = useQueryClient();

  const [amount, setAmount] = useState<number>(0);

  const [fromPortfolio, setFromPortfolio] = useState<PortfolioInputValue>({
    amount: "",
    portfolio: null,
  });
  const [toPortfolio, setToPortfolio] = useState<PortfolioInputValue>({
    amount: "",
    portfolio: null,
  });

  const [password, setPassword] = useState<string>("");
  const { data: swapMethodsResponse, isSuccess } = useQuery(
    {
      queryKey: ["swapMethods"],
      queryFn: swapMethods,
    },
    queryClient
  );
  const { data: accountPortfolio } = useQuery(
    {
      queryKey: ["accountPortfolio"],
      queryFn: getAccountPortfolioInfo,
    },
    queryClient
  );
  useEffect(() => {
    if(!swapMethodsResponse?.data.length && isSuccess && open){
      enqueueSnackbar(t('bd.trade.type.swap.method.empty.message'), { variant:'warning', autoHideDuration: 2000 })
      onClose();
    }
  }, [swapMethodsResponse?.data, isSuccess, open]);

  const accountPortfolioMap = useMemo(() => {
    const portfolioData: Record<string | number, AccountPortfolioVO> = {};
    accountPortfolio?.data.reduce((acc: Record<string, AccountPortfolioVO>, current: AccountPortfolioVO) => {
      if (current.portfolio?.portfolioCode) {
        acc[current.portfolio?.portfolioId] = current;
      }
      return acc;
    }, portfolioData);
    return portfolioData;
  }, [accountPortfolio]);

  /**
   * 计算兑换得到的资产数额
   * @returns 返回能得到的数额
   */
  useEffect(() => {
    if (!fromPortfolio.amount) {
      return;
    }

    const key = fromPortfolio.portfolio?.id + "#" + toPortfolio.portfolio?.id;
    const matchedSetting = swapPairMatch[key];
    if (!matchedSetting) {
      return;
    }

    const fromAmount = BigNumber(fromPortfolio.amount);
    const serviceFeeType = matchedSetting.deductedServiceFeeType;
    const deductedServiceFee = matchedSetting.deductedServiceFee;
    let actualAount = fromAmount;
    if (serviceFeeType == 0) {
      //不扣除服务费
    } else if (serviceFeeType == 1) {
      //固定金额
      actualAount = fromAmount.minus(deductedServiceFee);
    } else if (serviceFeeType == 2) {
      //扣除比例
      actualAount = fromAmount.times(1 - deductedServiceFee);
    }
    const swapRate = matchedSetting.swapRate;
    const outAmount = actualAount.times(new BigNumber(swapRate)).toNumber()
    // .toFormat(2, BigNumber.ROUND_DOWN);
    setToPortfolio({
      ...toPortfolio,
      amount: outAmount,
    });
  }, [fromPortfolio]);


  //动态计算兑出资产的余额
  useEffect(() => {
    if (fromPortfolio.portfolio?.id) {
      const balance = accountPortfolioMap[fromPortfolio.portfolio.id]?.balance;
      if (balance != null) {
        setAmount(balance);
        return;
      }
    }
    setAmount(0);
  }, [fromPortfolio, accountPortfolioMap]);

  //计算交换匹配数据
  const swapPairMatch = useMemo(() => {
    const match: Record<string, SwapSettingVo> = {};
    const swapMethodList = swapMethodsResponse?.data ?? [];
    swapMethodList.forEach((r) => {
      match[r.fromPortfolio?.portfolioId + "#" + r.toPortfolio?.portfolioId] = r;
    });
    return match;
  }, [swapMethodsResponse]);

  //计算交换匹配数据
  const swapMatch = useMemo(() => {
    const swapMethodList = swapMethodsResponse?.data ?? [];
    return swapMethodList.map((r) => {
      return {
        settingId: r.swapSettingId,
        from: {
          id: r.fromPortfolio?.portfolioId + "" ?? "",
          label: t(r.fromPortfolio?.portfolioCode) ?? "",
        },
        to: {
          id: r.toPortfolio?.portfolioId + "" ?? "",
          label: t(r.toPortfolio?.portfolioCode) ?? "",
        },
      };
    });
  }, [swapMethodsResponse, t]);

  //兑出项列表
  const fromItems = useMemo(() => {
    const fromPortfolioMap: Record<string, PortfolioItem> = {};
    swapMatch.forEach((current) => {
      const key = current.from.id;
      fromPortfolioMap[key] = current.from;
    });
    return Object.values(fromPortfolioMap);
  }, [swapMethodsResponse]);

  //兑入项列表
  const toItems = useMemo(() => {
    const toPortfolioMap: Record<string, PortfolioItem> = {};
    swapMatch.forEach((current) => {
      const key = current.to.id;
      const fromId = current.from.id;
      if (key != null && fromId == fromPortfolio.portfolio?.id) {
        toPortfolioMap[key] = current.to;
      }
    });
    return Object.values(toPortfolioMap);
  }, [swapMethodsResponse, fromPortfolio.portfolio]);

  const swallAllEnable = amount && BigNumber(amount).comparedTo(BigNumber(0)) > 0;
  const canSubmit = password && amount && fromPortfolio.amount && BigNumber(amount).comparedTo(BigNumber(fromPortfolio.amount)) >= 0;

  const swapAllEndAdornment = (
    <Typography
      variant="body2"
      component="span"
      onClick={() => {
        if (!swallAllEnable) return;
        setFromPortfolio({
          ...fromPortfolio,
          amount,
        });
      }}
      sx={{
        width: "7.5rem",
        flexGrow: 1,
        display: swallAllEnable ? "inline-block" : "none",
        textDecoration: "underline",
        textAlign: "right",
      }}
    >
      {t("bd.swap.action.swapAll")}
    </Typography>
  );

  const changePasswordEndAdornment = (
    <Typography
      variant="body2"
      component="span"
      sx={{
        width: "7.5rem",
        flexGrow: 1,
        color: "primary.main",
        textDecoration: "underline",
        textAlign: "right",
      }}
    >
      {t("bd.input.password.reset")}
    </Typography>
  );

  useEffect(() => {
    setFromPortfolio({
      amount: "",
      portfolio: swapMatch && swapMatch.length > 0 ? swapMatch[0].from : null,
    });
    setToPortfolio({
      amount: "",
      portfolio: swapMatch && swapMatch.length > 0 ? swapMatch[0].to : null,
    });
  }, [swapMethodsResponse]);

  useEffect(() => {
    // const selectedToItem = toItems.find((r) => r.id == toPortfolio.portfolio?.id);
      setToPortfolio({
        amount: '',
        portfolio: toItems[0],
      });
  }, [toItems]);

  //处理关闭弹框
  const handleClose = () => {
    setFromPortfolio({
      amount: "",
      portfolio: null,
    });
    onClose();
  };

  const afterSwapSuccess = () => {
    //清空闪兑金额
    setFromPortfolio({
      ...fromPortfolio,
      amount: "",
    });

    setToPortfolio({
      ...toPortfolio,
      amount: "",
    });

    setPassword("");

    //刷新余额
    queryClient.invalidateQueries({ queryKey: ["accountPortfolio"] });

    //关闭兑换框
    onClose();
  };

  //处理提交兑换
  const handleSwap = async () => {
    const key = fromPortfolio.portfolio?.id + "#" + toPortfolio.portfolio?.id;
    const matchedSetting = swapPairMatch[key];
    //兑换设置编号
    const swapSettingId = matchedSetting.swapSettingId;
    //兑换金额
    const fromAmount = fromPortfolio.amount;

    const { data } = await submitSwapOrder({
      settingId: swapSettingId,
      swapAmount: fromAmount,
      password,
    });

    console.log("submitSwapOrder", data);
    enqueueSnackbar(t("bd.swap.success.message"), { variant: "success", autoHideDuration: 2000 });
    afterSwapSuccess();
  };

  return (
    <Dialog
      className="trade-swap-dialog"
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx: {
          margin: 0,
          width: "calc(100% - 32px)",
        },
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.trade.type.swap")}</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Box className="form-item" sx={{ mb: 4 }}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography className="form-item-key">{t("bd.swap.fromPortofolio")}</Typography>
            <Typography className="form-item-key">{t("portfolio.avaible") + ": " + bigNumberFormat(amount, 2)}</Typography>
          </Box>

          <PortfolioInput
            value={fromPortfolio}
            onChange={(val) => {
              setFromPortfolio({
                ...val,
                amount: (val.amount as string).replace(/\D/g, ""),
              });
            }}
            onFocus={(e) => {
              setTimeout(() => {
                e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
              }, 500);
            }}
            placeholder={t("bd.swap.fromPortofolio.placeholder")}
            items={fromItems}
            selectable
            inputable
            // endAdornment={swapAllEndAdornment}
          />
        </Box>

        <Box className="form-item" sx={{ mb: 4 }}>
          <Typography className="form-item-key">{t("bd.swap.toPortfolio")}</Typography>
          <PortfolioInput
            onFocus={(e) => {
              setTimeout(() => {
                e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
              }, 500);
            }}
            value={toPortfolio}
            onChange={(val) => setToPortfolio(val)}
            items={toItems}
            selectable
          />
        </Box>

        <Box className="form-item" sx={{ mb: 3.5 }}>
          <Typography className="form-item-key">{t("bd.withdraw.label.password")}</Typography>
          <PasswordInput
            value={password}
            onFocus={(e) => {
              setTimeout(() => {
                e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
              }, 500);
            }}
            placeholder={t("bd.input.password.placeholder")}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button
          variant="outlined"
          sx={{ flexGrow: 1 }}
          onClick={() => {
            naviate("/app/swap-history");
          }}
        >
          <SvgIcon component={History} inheritViewBox />
        </Button>

        <Button variant="contained" disabled={!canSubmit} sx={{ ml: 1, flexGrow: 2 }} onClick={handleSwap}>
          {t("button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
