import { Box, Button, Card, CardContent, CardProps, Container, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BigNumber from "bignumber.js";
import { auth } from "@/api/userinfo";
import { packageList } from "@/api/global";
import { PackageVO } from "@/api/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "@/api/userinfo";
import "./index.less";
import BuyDialog from "./dialog/buy-dialog";
import ChargeReinvestDialog from "./dialog/charge-reinvest-dialog";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

interface AreaItemInfo {
  name: string;
  value: string;
  rate: string;
}

interface ItemProps extends AreaItemInfo, CardProps {
  index?: number;
  pkgList: PackageVO[];
}

const Item = ({ name, value, rate, pkgList, index, ...restProps }: ItemProps) => {
  const { t } = useTranslation();
  return (
    <Card {...restProps}>
      <CardContent sx={{ position: "relative", padding: '16px 21px 16px 16px' }}>
        <Box>
          <Typography>{t(name)}</Typography>

          <Divider className="divider-subtile" sx={{ height: 2, width: index === pkgList.length -1 ? "9%" :"20%",  mb: 0.5 }} />

          <Box className="gather-value-box" display="flex" flexDirection="row" alignItems="center">
            <Typography sx={{ fontSize: "1.0625rem", color: "text.secondary" }} component="span">
              {value}&nbsp;
              <Typography component="span" sx={{ fontSize: 13 }}>{t("page.digitalHuman.gatherValue")}</Typography>
            </Typography>
          </Box>
        </Box>

        <Box position="absolute" top="13%" right="0.3rem">
          <Typography
            sx={{
              color: "text.secondary",
              transform: "rotate(45deg)",
              textAlign: "center",
              fontSize: 15
            }}

          >
            {"×" + rate}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const fmt = {
  decimalSeparator: ".",
  groupSeparator: ",",
  groupSize: 3,
  secondaryGroupSize: 2,
};

export default function PackageFragment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [pkgList, setPkgList] = useState<PackageVO[]>([]);
  const [openBuyDialog, setOpenBuyDialog] = useState(false);
  const queryClient = useQueryClient();
  const { data: userInfo } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryClient
  );
  const [openChargeReinvestDialog, setOpenChargeReinvestDialog] = useState(false);
  useEffect(() => {
    packageList().then((response) => {
      const { data } = response;
      setPkgList(data);
    });
  }, []);

  return (
    <Box className="fragment-package">
      <Container sx={{ my: "1" }}>
        <Grid container spacing={2.25} className="area-info-container" marginTop={1}>
          {pkgList.map((r, index) => {
            let size = 6;
            let buttonAddonClass = "card-size-normal";

            if (pkgList.length % 2 == 1 && index == pkgList.length - 1) {
              size = 12;
              buttonAddonClass = "card-size-full";
            }

            return (
              <Grid className="card-item-container" key={index} size={size}>
                <Item
                  className={`${buttonAddonClass} card-item card-num-` + (index + 1)}
                  name={r.objectCode}
                  index={index}
                  pkgList={pkgList}
                  value={BigNumber(r.price).toFormat(2, BigNumber.ROUND_UP, fmt)}
                  rate={BigNumber(r.lever).div(100).toFixed(2)}
                />
              </Grid>
            );
          })}
        </Grid>
        {userInfo?.data.packageInfo ? (
          <Box sx={{ my: 2, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  const { data } = await auth({
                    type: "passwordCheck",
                  });
                  setOpenBuyDialog(true);
                  console.log("auth response ", data);
                } catch (e) {
                  console.warn("check password fail", e);
                }
              }}
              sx={{
                flexGrow: 1,
                mr: 1,
              }}
            >
              {t("page.digitalHuman.buy")}
            </Button>

            <Button
              variant="contained"
              onClick={async () => {
                try {
                  const { data } = await auth({
                    type: "passwordCheck",
                  });
                  setOpenChargeReinvestDialog(true);
                  console.log("auth response ", data);
                } catch (e) {
                  console.warn("check password fail", e);
                }
              }}
              sx={{
                flexGrow: 1,
                ml: 1,
              }}
            >
              {t("page.digitalHuman.reinvest")}
            </Button>
          </Box>
        ) : (
          <Box sx={{ my: 2, display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  const { data } = await auth({
                    type: "passwordCheck",
                  });
                  setOpenBuyDialog(true);
                  console.log("auth response ", data);
                } catch (e) {
                  console.warn("check password fail", e);
                }
              }}
              sx={{
                flexGrow: 1,
                mr: 1,
              }}
            >
              {t("page.digitalHuman.buy")}
            </Button>
          </Box>
        )}
      </Container>
      <BuyDialog open={openBuyDialog} onClose={() => setOpenBuyDialog(false)} onOk={() => {
          navigate(0)
      }} />
      <ChargeReinvestDialog
        open={openChargeReinvestDialog}
        onOk={() =>   navigate(0)}
        onClose={() => {
          setOpenChargeReinvestDialog(false);
        }}
      />
    </Box>
  );
}
