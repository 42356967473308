import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {  useCopyToClipboard } from 'react-use'
import { enqueueSnackbar } from "notistack";

interface CdkSuccessDialogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  cdKey?: string;
}

export default function CdkSuccessDialog({ open, onClose, cdKey = '', ...restProps }: CdkSuccessDialogProps) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState<boolean>(false);
  const [state, copyToClipboard] = useCopyToClipboard();
  //处理关闭弹框
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} {...restProps} fullWidth
    PaperProps={{
      sx:{
        margin: 0,
        width:'calc(100% - 32px)'
      }
    }}
    >
      <DialogTitle>{t("bd.rwa.package.buy.cdk.success.dialog.title")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: "1rem", py: 2 }}>
        <Box>
          <Box>
            <Typography variant="body2" color="text.secondary">
              {t("bd.trade.type.cdk")}
            </Typography>
          </Box>
          <TextField
            variant="outlined"
            fullWidth
            autoComplete="off"
            sx={{
              mt: 1,
              "& .MuiInputBase-root": {
                paddingLeft: "0",
              },
              "& .MuiOutlinedInput-input.Mui-disabled": {
                "-webkit-text-fill-color": "#fff",
              },
            }}
            onFocus={(e)=>{
              setTimeout(()=> {
                e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }, 500)
            }}
            value={cdKey}
            disabled
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => {
                      copyToClipboard(cdKey)
                      if(!state.error) {
                        setCopied(true);
                        enqueueSnackbar(t("bd.rwa.invite.code.copy.success"), { variant: "success",  autoHideDuration: 2000 });
                      } else {
                        setCopied(false);
                        enqueueSnackbar(state.error.message,{ variant: "error",  autoHideDuration: 2000 })
                      }

                    }}
                  >
                    <Icon className={copied ? "icon-action-success-normal" : "icon-action-copy-normal"} sx={{ width: "17px", height: "16px" }} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button variant="contained" sx={{ ml: 1, flexGrow: 2 }} onClick={()=> onClose()}>
          {t("button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
