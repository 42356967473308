import { useTopAppBar } from "@/components/top-app-bar/provider";
import AppLayout from "@/layout/app";
import HomeLayout from "@/layout/home";
import { Manipulator } from "@/layout/manipulator";
import ErrorPage from "@/pages/error/ErrorPage";
import BurningProfile from "@/pages/home/burning-profile";
import DashBoard from "@/pages/home/dashboard";
import DigitalHuman from "@/pages/home/digital-human";
import TradeCharge from "@/pages/trade/charge";
import TradeChargeHistory from "@/pages/trade/charge-history";
import TradeSwapHistory from "@/pages/trade/swap-history";
import TradeWithdraw from "@/pages/trade/withdaw";
import TradeWithdrawHistory from "@/pages/trade/withdraw-history";
/**
 * 通知页面
 */
import AnnouncementList from "@/pages/announcement/announcement-list";
import AnnouncementDetail from "@/pages/announcement/announcement-detail";
import ContactCustomer from "@/pages/support/contact-customer";
import RWAProfile from "@/pages/home/rwa-profile";
import { ReactNode, useEffect } from "react";
import { createBrowserRouter, Navigate, RouteObject, useRoutes } from "react-router-dom";
import CdkHistory from "@/pages/rwa/package/cdk-history";
import InBox from "@/pages/support/InBox";
import PackageHistory from "@/pages/rwa/package/package-history";
import ReplyChat from "@/pages/support/reply-chat";
import TransferHistory from "@/pages/rwa/transfer-history";
import AiLargeModelBonus from "@/pages/rwa/ai-large-model-bonus";
import TogetherCreateBonus from "@/pages/rwa/together-create-bonus";
import IntelligenceBonus from "@/pages/rwa/intelligence-bonus";
import SpecialBonus from  "@/pages/rwa/special-bonus";
import ActiveCdk from "@/pages/rwa/active-cdk"
import Invite from '@/pages/home/invite'
import FissionSettingHistory from "@/pages/rwa/fission-setting-history";
import Income from "@/pages/rwa/Income";
import TrainingHistory from "@/pages/rwa/training-history";
import Community from  "@/pages/home/community";
import { useToken } from "@/stores/modules/user";
export type RouteMetaInfo = {
  title?: string;
  isAuth?: boolean;
  showBackButton?: boolean;
  menuMode?: MenuMode;
};

interface WithMetaInfoProps {
  children: ReactNode;
  meta?: RouteMetaInfo;
}

const WithMetaInfo = ({ children, meta }: WithMetaInfoProps) => {
  const { setShowBackButton, setTitle, setMenuMode } = useTopAppBar();
  const token = useToken();
  useEffect(() => {
    if (meta) {
      setTitle(meta.title ?? "");
      setShowBackButton(meta.showBackButton ?? false);
      setMenuMode(meta.menuMode ?? "KC");
    }
    return () => {
      setTitle("");
      setShowBackButton(false);
      setMenuMode("KC");
    };
  }, [meta]);
  if(meta?.isAuth && !token){
    return   <Navigate to="/home/dashboard" replace />
  }
  return children;
};

const routeList: RouteObject[] = [
  {
    path: "/",
    id: "root",
    index: true,
    element: <Navigate to="/home/dashboard" />,
  },
  {
    path: "home",
    element: <HomeLayout />,
    children: [
      {
        path: "dashboard",
        element: <DashBoard />,
      },
      {
        path: "digitalHuman",
        element: (
          <WithMetaInfo
            meta={{
              showBackButton: false,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <DigitalHuman />
          </WithMetaInfo>
        ),
      },
      {
        path: "burningProfile",
        element: (
          <WithMetaInfo
            meta={{
              title: "nav.bottom.mine",
              showBackButton: false,
              menuMode: "KC",
              isAuth: true,
            }}
          >
            <BurningProfile />
          </WithMetaInfo>
        ),
      },
      {
        path: "rwaProfile",
        element: (
          <WithMetaInfo
            meta={{
              title: "page.title.rwaProfile",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <RWAProfile />
          </WithMetaInfo>
        ),
      },
      {
        path: "invite",
        element: (
          <WithMetaInfo
            meta={{
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <Invite />
          </WithMetaInfo>
        ),
      },
      {
        path: "income",
        element: (
          <WithMetaInfo
            meta={{
              title: "page.title.income",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <Income />
          </WithMetaInfo>
        ),
      },
      {
        path: "community",
        element: (
          <WithMetaInfo
            meta={{
              title: "page.title.community",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <Community />
          </WithMetaInfo>
        ),
      }
    ],
  },
  {
    path: "app",
    element: <AppLayout />,
    children: [
      {
        path: "charge",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.trade.type.charge",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TradeCharge />
          </WithMetaInfo>
        ),
      },
      {
        path: "charge-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.trade.title.charge.history",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TradeChargeHistory />
          </WithMetaInfo>
        ),
      },
      {
        path: "withdraw",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.trade.type.withdraw",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TradeWithdraw />
          </WithMetaInfo>
        ),
      },
      {
        path: "withdraw-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.trade.title.withdraw.history",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TradeWithdrawHistory />
          </WithMetaInfo>
        ),
      },
      {
        path: "swap-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.trade.title.swap.history",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TradeSwapHistory />
          </WithMetaInfo>
        ),
      },
      {
        path: "training-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.training.history.title",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TrainingHistory />
          </WithMetaInfo>
        ),
      },
      {
        path: "announcement",
        element: (
          <WithMetaInfo
            meta={{
              title: "nav.menu.announcement",
              isAuth: true,
            }}
          >
            <AnnouncementList />
          </WithMetaInfo>
        ),
      },
      {
        path: "announcement-detail",
        element: (
          <WithMetaInfo
            meta={{
              title: "nav.menu.announcement",
              showBackButton: true,
              isAuth: true,
            }}
          >
            <AnnouncementDetail />
          </WithMetaInfo>
        ),
      },
      {
        path: "cdk-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.rwa.title.cdk.history",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <CdkHistory />
          </WithMetaInfo>
        ),
      },
      {
        path: "package-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.rwa.title.package.history",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <PackageHistory />
          </WithMetaInfo>
        ),
      },
      {
        path: "active-cdk",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.rwa.title.cdk.history",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <ActiveCdk />
          </WithMetaInfo>
        ),
      },
      {
        path: "transfer-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.rwa.profile.transfer.log",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TransferHistory />
          </WithMetaInfo>
        ),
      },
      {
        path: "fissionSetting-history",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.rwa.profile.fission.setting.history",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <FissionSettingHistory />
          </WithMetaInfo>
        )
      },
      {
        path: "support",
        element: (
          <WithMetaInfo
            meta={{
              title: "nav.menu.customer",
              showBackButton: true,
              isAuth: true,
            }}
          >
            <ContactCustomer />
          </WithMetaInfo>
        ),
      },
      {
        path: "chat",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.support.service",
              showBackButton: true,
              isAuth: true,
            }}
          >
            <ReplyChat />
          </WithMetaInfo>
        ),
      },
      {
        path: "inbox",
        element: (
          <WithMetaInfo
            meta={{
              title: "bd.support.inbox",
              showBackButton: true,
              isAuth: true,
            }}
          >
            <InBox />
          </WithMetaInfo>
        ),
      },
      {
        path: "ai-OutermModelBonus",
        element: (
          <WithMetaInfo
            meta={{
              title: "page.title.report.bonus.eco",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <AiLargeModelBonus />
          </WithMetaInfo>
        ),
      },
      {
        path: "togetherCreateBonus",
        element: (
          <WithMetaInfo
            meta={{
              title: "page.title.report.bonus.initial",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <TogetherCreateBonus />
          </WithMetaInfo>
        ),
      },
      {
        path: "intelligenceBonus",
        element: (
          <WithMetaInfo
            meta={{
              title: "page.title.report.bonus.intelligence",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <IntelligenceBonus />
          </WithMetaInfo>
        ),
      },
      {
        path: "specialBonus",
        element: (
          <WithMetaInfo
            meta={{
              title: "page.title.report.bonus.special",
              showBackButton: true,
              menuMode: "RWA",
              isAuth: true,
            }}
          >
            <SpecialBonus />
          </WithMetaInfo>
        ),
      }
    ],
  },
];

const routeMap: Record<string, RouteObject> = {};
const routeKeyPath: Record<string, string> = {};

function flatRoutePath(routeList: RouteObject[], parentPath = "") {
  for (const route of routeList) {
    const realPath = `${parentPath}/${route.path}`.replace(/\/+/g, "/").replace(/^\/|\/$/g, "");
    routeMap[realPath] = route;
    if (!route.children) {
      const key = route.id ?? route.path ?? "";
      routeKeyPath[key] = realPath;
    } else {
      flatRoutePath(route.children, realPath);
    }
  }
}

flatRoutePath(routeList);

export function getRouteByPath(path: string) {
  return routeMap[path];
}

export function getRouteByPath2(locationArg?: Partial<Location> | string) {
  return useRoutes(routeList, locationArg);
}

export function getRealPathByKey(key: string) {
  return routeKeyPath[key];
}

const routes = createBrowserRouter([
  {
    element: <Manipulator />,
    path: "/",
    errorElement: <ErrorPage />,
    children: routeList,
  },
]);

export { routes };
