import cache from "@/plugins/cache";
import { AppState } from "@/stores/app";
import { PaletteMode } from "@mui/material";
import { useSnapshot } from "valtio";

const ThemeKey = 'App-Theme';
const defaultTheme = 'dark'

export const toggleThemeMode = (currentThemeMode: PaletteMode) => {
    const nextMode = (currentThemeMode === 'light' ? 'dark' : 'light')
    cache.local.set(ThemeKey, nextMode);
    AppState.themeMode = nextMode;
};

export const useAppTheme = () => {
    const snap = useSnapshot(AppState);
    const appTheme = (snap.themeMode || cache.local.get(ThemeKey) || defaultTheme) as unknown as PaletteMode
    return { appTheme };
}
