import { Components, CssVarsTheme, Theme } from "@mui/material";
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-date-pickers/AdapterDayjs';

const ComponentsConfig: Components<Omit<Theme, 'components' | 'palette'> & CssVarsTheme> = {
    MuiButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                variants: [
                    {
                        props: { variant: 'contained' },
                        style: {
                            background: 'linear-gradient(90deg, #19C6FF, #2AF5BA)',
                            height: '2.5rem',
                            minWidth: '10rem',
                            borderStyle: 'solid',
                            borderRadius: '1.25rem',
                            fontWeight: 'bold'
                        }
                    },
                    {
                        props: { variant: 'outlined' },
                        style: {
                            height: '2.5rem',
                            "--variant-outlinedColor": theme.vars.palette.text.secondary,
                            borderRadius: '1.25rem',
                            fontWeight: 'bold'
                        }
                    }
                ],
            }),
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: ({ theme }) => ({
                variants: [
                    {
                        props: { variant: 'outlined' },
                        style: {
                            '& .MuiInputBase-root': {
                                color: theme.vars.palette.text.secondary,
                                backgroundColor:  theme.vars.palette.background.paper,
                                '&.Mui-focused fieldset': {
                                    borderColor: theme.vars.palette.primary.dark,
                                },
                            },
                            '& .MuiInputBase-input': {
                                padding: '0.635rem',
                                '&::placeholder': {
                                    color: theme.vars.palette.grey[50]
                                }
                            },
                        }
                    }
                ]
            })
        }
    },
    MuiAppBar: {
        styleOverrides: {
            root: ({ theme }) => ({
                variants: [
                    {
                        props: { position: 'fixed' },
                        style: {
                            "--mui-palette-AppBar-darkBg": theme.vars.palette.common.black
                        }
                    }
                ]
            })
        }
    },
    MuiTabs: {
        styleOverrides: {
            root: ({ theme }) => ({
                variants: [
                    {
                        props: { variant: 'fullWidth' },
                        style: {
                            height: 'inherit',
                            minHeight: 'inherit',
                            borderRadius: '0.25rem',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderImage: theme.vars.palette.primary.main,
                        }
                    }
                ]
            }),

        }
    },
    MuiTab: {
        styleOverrides: {
            root: ({ theme }) => ({
                variants: [
                    {
                        props: { fullWidth: true },
                        style: {
                            height: '1.87rem',
                            minHeight: '1.87rem',
                            padding: 0,
                            backgroundColor: theme.vars.palette.background.default,
                        }
                    },
                    {
                        props: { fullWidth: true, selected: true },
                        style: {
                            color: theme.vars.palette.text.secondary + "!important",
                            backgroundColor: theme.vars.palette.primary.main,
                        }
                    }
                ]
            }),
        }
    },
    MuiDialog: {
        styleOverrides: {
            root: ({ theme }) => ({
                '& .MuiPaper-root': {
                    borderRadius: '0.875rem',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: theme.vars.palette.primary.dark,
                    backgroundImage: 'none',
                    backgroundColor: theme.vars.palette.background.default
                },
                "& .MuiDialogTitle-root": {
                    textAlign: 'center'
                }
            })
        }
    },
    MuiDateCalendar: {
        styleOverrides: {
            root: {
                width: 'unset'
            }
        }
    }
}

export default ComponentsConfig