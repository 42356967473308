import { Component, type ComponentType, type PropsWithChildren, type ReactNode } from "react";

export interface ErrorBoundaryProps extends PropsWithChildren {
  fallback?: ReactNode | ComponentType<{ error: unknown }>;
}

interface ErrorBoundaryState {
  error?: unknown;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {};

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const {
      state: { error },
      props: { fallback: Fallback, children },
    } = this;

    if ("error" in this.state) {
      if (typeof Fallback === "function") {
        return <Fallback error={error} />;
      } else {
        return Fallback as ReactNode;
      }
    }
    return children;
  }
}
