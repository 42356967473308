import { Box, Button, Card, CardContent, Container, Divider, Grid2, Icon, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SwitchTab from "@/components/switch-tab";
import CommunitySearchDialog from "@/pages/rwa/dialog/community-search-dialog";
import { queryRelationInvite, queryRelationLeader } from "@/api/userinfo";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import { CommunityPerformanceVo, queryCommunityPerformance } from "@/api/bonus";
import { TreeView, TreeNode } from "mui-lazy-tree-view";
import { useQuery } from "@tanstack/react-query";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import { bigNumberFormat } from "@/utils/common";

const HarvestCard = ({ collapsed, harvestPortfolios }: { collapsed: boolean; harvestPortfolios: CommunityPerformanceVo["harvestPortfolios"] }) => {
  const { t } = useTranslation();
  return (
    <Card
      className="list-item-card"
      sx={collapsed ? { bgcolor: "#0A3566", borderRadius: 1.5 } : { display: "none", bgcolor: "#0A3566", borderRadius: 1.5 }}
    >
      <CardContent sx={{ paddingBottom: "0.5rem !important", padding: 1.5 }}>
        {(harvestPortfolios || [])
          .filter((item) => {
            if (item.portfolio?.portfolioCode === "bd.portfolio.code.harvest" && BigNumber(item.balance || "0.00").comparedTo(0) <= 0) {
              return false;
            }
            return true;
          })
          .map((item, index) => (
            <Box key={index}>
              <Typography color="#595959" component="span">
                {t(item.portfolio.portfolioCode)}
              </Typography>
              <Typography component="span">{bigNumberFormat(item.balance, item.portfolio.portfolioDecimal)}</Typography>
            </Box>
          ))}
      </CardContent>
    </Card>
  );
};

export default function Community() {
  const handleChange = () => {
    setAtiveIndex((prev) => (prev === 0 ? 1 : 0));
    setSearchNodeUId("");
    setCommunity([]);
  };
  const [communitySearchDialogOpen, setCommunitySearchDialogOpen] = useState(false);
  const { t } = useTranslation();
  const [activeIndex, setAtiveIndex] = useState(0);
  const [community, setCommunity] = useState<TreeNode[]>([]);
  const [searchNodeUId, setSearchNodeUId] = useState("");
  const { data } = useQuery({
    queryKey: ["communityPerformance"],
    queryFn: queryCommunityPerformance,
  });
  const items = [
    {
      label: t("bd.rwa.community.share"),
      component: null,
    },
    {
      label: t("bd.rwa.community.fissile"),
      component: null,
    },
  ];
  const CommunityFragement = useCallback(() => {
    let expandedKey: string[] = [];
    if (community.length) {
      expandedKey = [community[0].key];
    }
    return (
      <Box sx={{ mt: 2, minHeight: "10rem", bgcolor: "#091625", pt: 2 }}>
        <TreeView
          treeData={community}
          titleRender={(node) => {
            return (
              <Typography component="span">
                UID:{node.data?.uid}&nbsp;&nbsp;
                {activeIndex ? `${t("bd.portfolio.code.planting")}:${BigNumber(node.data?.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}` : ""}
              </Typography>
            );
          }}
          expanded={expandedKey}
          lazyLoadFn={({ key, children }) => {
            return new Promise<TreeNode[]>(async (resolve) => {
              if (children && children.length) {
                resolve([]);
                return;
              }
              let res;
              if (activeIndex) {
                res = await fetchRelationLeader(key);
              } else {
                res = await fetchElationInvite(key);
              }
              resolve(
                (res.members || []).map((item) => {
                  return (
                    !item.teamMemberCount
                      ? {
                          title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                          key: (item.uid || "").toString(),
                          data: item,
                        }
                      : {
                          title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                          key: (item.uid || "").toString(),
                          children: [],
                          data: item,
                        }
                  ) as TreeNode;
                })
              );
            });
          }}
        />
      </Box>
    );
  }, [community]);
  const fetchElationInvite = async (searchNodeUid?: string) => {
    const { data } = await queryRelationInvite({
      searchNodeUid,
    });
    return data;
  };
  const fetchRelationLeader = async (searchNodeUid?: string) => {
    const { data } = await queryRelationLeader({
      searchNodeUid,
    });
    return data;
  };
  const [collapsed, setCollapsed] = useState(false);
  const [showMoreHarvest, setShowMoreHarvest] = useState(true);
  useEffect(() => {
    if (!data?.data.harvestPortfolios?.length) {
      setShowMoreHarvest(false);
    } else if (data?.data.harvestPortfolios?.length) {
      const harvestExtList = data?.data.harvestPortfolios.filter((item) => {
        if (item.portfolio?.portfolioCode === "bd.portfolio.code.harvest" && BigNumber(item.balance || "0.00").comparedTo(0) <= 0) {
          return false;
        }
        return true;
      });
      if (harvestExtList.length === 0) {
        setShowMoreHarvest(false);
      } else {
        setShowMoreHarvest(true);
      }
    } else {
      setShowMoreHarvest(true);
    }
  }, [data?.data.harvestPortfolios]);
  useEffect(() => {
    if (!activeIndex) {
      (async () => {
        const { members = [], searchNodeMember } = await fetchElationInvite();

        setCommunity(
          members?.length
            ? [
                {
                  title: `UID:${searchNodeMember.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(searchNodeMember.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                  key: (searchNodeMember.uid || "").toString(),
                  data: {
                    memberId: searchNodeMember.memberId,
                    pv: searchNodeMember.pv,
                    uid: searchNodeMember.uid,
                  },
                  children: members.map((item) =>
                    !item.teamMemberCount
                      ? {
                          title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                          key: (item.uid || "").toString(),
                          data: item,
                        }
                      : {
                          title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                          key: (item.uid || "").toString(),
                          children: [],
                          data: item,
                        }
                  ),
                },
              ]
            : [
                {
                  title: `UID:${searchNodeMember.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(searchNodeMember.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                  key: (searchNodeMember.uid || "").toString(),
                  data: {
                    memberId: searchNodeMember.memberId,
                    pv: searchNodeMember.pv,
                    uid: searchNodeMember.uid,
                  },
                },
              ]
        );
      })();
    } else {
      (async () => {
        const { members = [], searchNodeMember } = await fetchRelationLeader();
        setCommunity(
          members?.length
            ? [
                {
                  title: `UID:${searchNodeMember.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(searchNodeMember.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                  key: (searchNodeMember.uid || "").toString(),
                  data: {
                    memberId: searchNodeMember.memberId,
                    pv: searchNodeMember.pv,
                    uid: searchNodeMember.uid,
                  },
                  children: members.map((item) =>
                    !item.teamMemberCount
                      ? {
                          title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                          key: (item.uid || "").toString(),
                          data: item,
                        }
                      : {
                          title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                          key: (item.uid || "").toString(),
                          children: [],
                          data: item,
                        }
                  ),
                },
              ]
            : [
                {
                  title: `UID:${searchNodeMember.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(searchNodeMember.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                  key: (searchNodeMember.uid || "").toString(),
                  data: {
                    memberId: searchNodeMember.memberId,
                    pv: searchNodeMember.pv,
                    uid: searchNodeMember.uid,
                  },
                },
              ]
        );
      })();
    }
  }, [activeIndex]);
  const harvestEtBalanceTotal = data?.data.harvestPortfolios?.reduce((acc, cur) => BigNumber(cur.balance!).plus(BigNumber(acc)).toNumber(), 0);
  return (
    <Container className="community-page">
      <Card
        sx={{
          borderRadius: 3.5,
        }}
      >
        <CardContent>
          <Box>
            <Typography variant="h6" color="text.primary">
              {t("bd.rwa.community.my.performance")}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" color="#595959">
                {t("bd.rwa.community.my.performance")}
              </Typography>
              <Typography variant="h5" color="text.primary">
                {BigNumber(
                  BigNumber(data?.data.summaryVo?.selfBv || "0.0000").toNumber() +
                    BigNumber(data?.data.summaryVo?.selfHistorySumBv || "0.0000").toNumber() || "0.0000"
                ).toFormat(2)}
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Grid2 container sx={{ mt: 1 }}>
                <Grid2 size={5}>
                  <Box>
                    <Typography sx={{ color: "#595959" }}>{t("bd.portfolio.code.planting")}</Typography>
                    <Typography sx={{ mt: 0.5 }}>
                      {BigNumber(data?.data.plantingPortfolio?.balance || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}
                    </Typography>
                  </Box>
                </Grid2>
                <Grid2 size={2}>
                  <Box sx={{ height: "100%" }} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Divider orientation="vertical" flexItem />
                  </Box>
                </Grid2>
                <Grid2 size={5}>
                  <Box>
                    <Typography sx={{ color: "#595959" }}>{t("total.generated.value")}</Typography>
                    {showMoreHarvest ? (
                      <Typography sx={{ mt: 0.5 }} onClick={() => setCollapsed(!collapsed)}>
                        <Typography component="span" sx={{ verticalAlign: "top" }}>
                          {BigNumber(harvestEtBalanceTotal || "0.00").toFormat(2, BigNumber.ROUND_DOWN)}
                        </Typography>
                        {!collapsed ? <ArrowDropDownSharp sx={{ color: "text.secondary" }} /> : <ArrowDropUpSharp sx={{ color: "text.secondary" }} />}
                      </Typography>
                    ) : (
                      <Typography component="span" sx={{ verticalAlign: "top" }}>
                        {BigNumber(harvestEtBalanceTotal || "0.00").toFormat(2, BigNumber.ROUND_DOWN)}
                      </Typography>
                    )}
                  </Box>
                </Grid2>
              </Grid2>
            </Box>
            <HarvestCard collapsed={collapsed} harvestPortfolios={data?.data.harvestPortfolios} />
          </Box>
        </CardContent>
      </Card>
      <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 1, mb: 2 }}>
        <Grid2 size={6}>
          <Card
            sx={{
              borderRadius: 3.5,
            }}
          >
            <CardContent>
              <Box>
                <Typography>{t("bd.rwa.community.left.area.performance")}</Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography color="#595959">{t("bd.portfolio.code.planting")}</Typography>
                  <Typography color="text.primary">
                    {BigNumber(data?.data.summaryVo?.leftBv || "0.0000")
                      .plus(BigNumber(data?.data.summaryVo?.leftInheritBv || "0.0000"))
                      .toFormat(2, BigNumber.ROUND_DOWN)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={6}>
          <Card
            sx={{
              borderRadius: 3.5,
            }}
          >
            <CardContent>
              <Box>
                <Typography>{t("bd.rwa.community.right.area.total.performance")}</Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography color="#595959">{t("bd.portfolio.code.planting")}</Typography>
                  <Typography color="text.primary">
                    {BigNumber(data?.data.summaryVo?.rightBv || "0.0000")
                      .plus(BigNumber(data?.data.summaryVo?.rightInheritBv || "0.0000"))
                      .toFormat(2, BigNumber.ROUND_DOWN)}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Card
        sx={{
          borderRadius: 3.5,
        }}
      >
        <CardContent>
          <Box display="flex">
            <Box flex={1}>
              <SwitchTab defaultTabIndex={activeIndex} onChange={handleChange} items={items} />
            </Box>
            <Button variant="contained" sx={{ minWidth: "7rem", height: "2rem" }} onClick={() => setCommunitySearchDialogOpen(true)}>
              <Typography>
                <Icon className="icon-glass" sx={{ width: "0.7rem", height: "0.7rem", mr: 1 }} />
                {t("bd.rwa.community.search.id")}
              </Typography>
            </Button>
          </Box>
          <CommunityFragement />
        </CardContent>
      </Card>
      <CommunitySearchDialog
        searchNodeUId={searchNodeUId}
        onFilter={async (value) => {
          setSearchNodeUId(value || "");
          let result;
          if (!activeIndex) {
            result = await fetchElationInvite(value);
          } else {
            result = await fetchRelationLeader(value);
          }
          const { members = [], searchNodeMember } = result;
          setCommunity(
            members?.length
              ? [
                  {
                    title: `UID:${searchNodeMember.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(searchNodeMember.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                    key: (searchNodeMember.uid || "").toString(),
                    data: {
                      memberId: searchNodeMember.memberId,
                      pv: searchNodeMember.pv,
                      uid: searchNodeMember.uid,
                    },
                    children: members.map((item) =>
                      !item.teamMemberCount
                        ? {
                            title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                            key: (item.uid || "").toString(),
                            data: item,
                          }
                        : {
                            title: `UID:${item.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(item.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                            key: (item.uid || "").toString(),
                            children: [],
                            data: item,
                          }
                    ),
                  },
                ]
              : [
                  {
                    title: `UID:${searchNodeMember.uid} ${t("bd.portfolio.code.planting")}:${BigNumber(searchNodeMember.pv || "0.0000").toFormat(2, BigNumber.ROUND_DOWN)}`,
                    key: (searchNodeMember.uid || "").toString(),
                    data: {
                      memberId: searchNodeMember.memberId,
                      pv: searchNodeMember.pv,
                      uid: searchNodeMember.uid,
                    },
                  },
                ]
          );
        }}
        onClose={() => setCommunitySearchDialogOpen(false)}
        open={communitySearchDialogOpen}
      />
    </Container>
  );
}
