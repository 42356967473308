import { PaletteMode, PaletteOptions } from "@mui/material";

import dark from "../config/mode/dark";
import light from "../config/mode/light";

const PlatteConfig: Record<PaletteMode, PaletteOptions> = {
    dark: {
        mode: "dark",
        ...dark
    },
    light: {
        mode: "light",
        ...light
    }
}

export default PlatteConfig