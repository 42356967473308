import { Box } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import "./App.less";
import { initI18n } from './lang';
import { routes } from "./router";
import { useAxiosInterceptors } from "./utils/request";

initI18n();

const HttpInterceptor = () => {
  useAxiosInterceptors();
  return <></>
}

function App() {
  return (
    <Box id="app" className="app" sx={{color: 'text.primary' }}>
      <HttpInterceptor />
      <RouterProvider router={routes} />
    </Box>
  );
}

export default App;
