import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  TextField, Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

interface FissionSettingFilterDialogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  uId: string;
  // setUid: React.Dispatch<React.SetStateAction<string>>;
  onFilter: (uid: string)=> void;
}

export default function FissionSettingFilterDialog({ open, onClose, onFilter, uId, ...restProps }: FissionSettingFilterDialogProps) {
  const { t } = useTranslation();
  const [userId, setUserId] = useState(uId)
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx:{
          margin: 0,
          width:'calc(100% - 32px)'
        }
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.dialog.tilte.filter")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent  sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Box sx={{ mt: 3 }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1" color="text.secondary">
              UID
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            value={userId}
            onFocus={(e)=>{
              setTimeout(()=> {
                e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }, 500)
            }}
            placeholder={t("bd.support.contact.coustomer.content.placeholder")}
            onChange={(e)=> setUserId(e.target.value)}
            sx={{
              mt: 1,
              "& .MuiInputBase-root": {
                paddingLeft: "0",
              },

            }}
            slotProps={{
              input: {
                autoComplete: "off",
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button variant="outlined" sx={{ flexGrow: 1 }} onClick={()=> setUserId('')}>
          {t("button.reset")}
        </Button>
        <Button variant="contained" sx={{ ml: 1, flexGrow: 2 }} onClick={async () => {
          onClose()
          setUserId('')
          onFilter(userId)
        }}>
          {t("button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
