import { Checkbox, CheckboxProps, Icon, TextField, TextFieldProps } from "@mui/material"
import { useState } from "react"

const ViewSecretInfoCheckBox = (props: CheckboxProps) => {
    return <Checkbox
        {...props}
        icon={<Icon className="icon-eye-brief" sx={{ width: '17px', height: '16px' }} />}
        checkedIcon={<Icon className="icon-eye-detail" component='div' sx={{ width: '17px', height: '16px' }} />}
        inputProps={{ 'aria-label': 'view or hide password' }}
        sx={{ width: '25px', height: '25px', mr: 'var(--mui-spacing)', padding: 0 }}
    />
}

export default function PasswordInput({ value, placeholder, onChange,...restProps }: TextFieldProps) {
    const [visible, setVisible] = useState<boolean>(false);

    return (
      <TextField
        {...restProps}
        variant="outlined"
        fullWidth
        autoComplete="off"
        placeholder={placeholder}
        type={visible ? "text" : "password"}
        name="one-time-code"
        sx={{
          mt: 1,
          "& .MuiInputBase-root": {
            paddingLeft: "0",
          },
        }}
        slotProps={{
          input: {
            endAdornment: <ViewSecretInfoCheckBox onChange={() => setVisible(!visible)} />,
          },
        }}
        value={value}
        onChange={onChange}
      />
    );
}