import { getRealPathByKey, getRouteByPath } from "@/router";
import { createContext, useEffect, useState } from "react";
import { Outlet, RouteObject, useLocation, useNavigate } from "react-router-dom";

const RouteContext = createContext<Partial<RouteObject>>({});

export function Manipulator() {
  const location = useLocation();
  const navigate = useNavigate();
  const [route, setRoute] = useState<Partial<RouteObject>>({});

  useEffect(() => {
    const currentRoute: RouteObject = getRouteByPath(location.pathname.replace(/^\/|\/$/g, ""));
    setRoute(currentRoute);
    if (currentRoute) {
      if (currentRoute.children && currentRoute.children.length > 0) {
        //是布局组件
        const firstChildRoute = currentRoute.children[0];
        const key = firstChildRoute.id ?? firstChildRoute.path ?? "";
        const routeFullPath = getRealPathByKey(key);
        if (routeFullPath) {
          navigate(routeFullPath, { replace: true });
        }
      }
    }
  }, [location]);

  return (
    <RouteContext.Provider value={route}>
      <Outlet />
    </RouteContext.Provider>
  );
}

export { RouteContext };
