import { login as loginApi } from "@/api/login";
import { getToken, removeToken, setToken } from "@/utils/auth";
import { enqueueSnackbar } from "notistack";
import { proxy, useSnapshot } from "valtio";
import { t } from 'i18next'
interface UserStore {
    token: Nullable<string>
}

const initialObject: UserStore = {
    token: null
}

export const UserState = proxy(initialObject);

export function useToken() {
    const snap = useSnapshot(UserState);
    if (snap.token == null) {
        const token = getToken();
        if (token != null) {
            UserState.token = token;
        }
    }
    return snap.token
}

export const login = async (walletAddress: string, sig: string) => { 
    const { data } = await loginApi({
        address: walletAddress,
        signature: sig,
        clientId: process.env.REACT_APP_CLIENT_ID,
        grantType: 'wallet'
    });
    console.log("loginResponse ", data);
    if (data !== null) {
        setToken(data.access_token);
        UserState.token = data.access_token;
    }
    enqueueSnackbar(t("login.success.message"), { variant: 'success',  autoHideDuration: 2000 });
}

export const logout = async () => {
    console.log("kkkk logout");
    removeToken();
}
