import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactNode, useEffect } from "react";
import { WagmiProvider } from 'wagmi'
import { ConnectKitProvider } from "connectkit";
import { config } from './config'
import { logout, useToken } from "@/stores/modules/user";

const queryClient = new QueryClient()

export default function Web3Provider({ children }: { children: ReactNode }) {
    const token = useToken()
    // 监听账户地址改变
    useEffect(() => {
        const handleAccountsChanged = async () => {
           if(token) {
            await logout()
            window.location.href = '/'
           }
        };
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", handleAccountsChanged);
        }
        return () => {
            if (window.ethereum) {
                window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
            }
        };
    }, [token]);
    return <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
            <ConnectKitProvider theme='midnight'>
                {children}
            </ConnectKitProvider>
        </QueryClientProvider>
    </WagmiProvider>
}

