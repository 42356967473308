import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { fetchCustomerServiceChatList } from "@/api/support";
import { CustomerServiceChatRecordVO } from "@/api/types/customerService";
export default function InBox() {
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const [total, setTotal] = useState(0);
  const pageSize = useRef(10);
  const navigate = useNavigate();
  const renderItem = useCallback((item: CustomerServiceChatRecordVO) => {
    return (
      <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}  onClick={()=> navigate("/app/chat", {
        state: { conversationId: item.conversationId }
      })}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="h6"
              noWrap
              color="text.secondary"
              sx={{ width: "10rem" }}
            >
              {item.subject}
            </Typography>
            <Typography color="text.disabled" variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
              {item.createTime}
            </Typography>
          </Box>
          <Box>
				  <Typography color="text.disabled" component="span">
            {item.lastMessage?.messageType ? <img width={30} src={item.lastMessage.message} /> : item.lastMessage?.message}
				  </Typography>
				</Box>
        </CardContent>
      </Card>
    );
  }, []);

  const fetchData = async (page: number): Promise<CustomerServiceChatRecordVO[]> => {
    const data = await fetchCustomerServiceChatList({
      pageNum: page,
      pageSize: pageSize.current,
    });
    const rowData = data?.rows ?? [];
    setTotal(data.total);
    console.log("fetchData page", page, "data=>", data, rowData);
    return rowData;
  };
  return (
    <Box className="page-inbox" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        pageSize={pageSize.current}
        total={total}
        idField="conversationId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
    </Box>
  );
}
