import request from "@/utils/request";
import { AxiosPromise } from "axios";
import {
  ReportChargeOrderForm,
  ChainChargeRecordVO,
  QueryChargeOrderParam,
  QueryWithdrawOrderParam,
  QuerySwapOrderParam,
  QueryTransferOrderParam
} from "./types";
import { SubmitSwapOrderForm, SwapRecordVO } from "./types/swapSetting";
import  { SubmitTransferOrderForm } from "./types/transferSetting";
import  { TransferRecordVO} from './types/transferRecord'
import { ReportWithdrawOrderForm as SubmitWithdrawOrderForm } from "./types/withdrawSetting";
import { WithdrawRecordVO } from "./types/withdrawRecord";

/**
 * 上报充值订单
 * @returns
 */
export function reportChargeOrder(data: ReportChargeOrderForm): AxiosPromise<ChainChargeRecordVO> {
  return request({
    url: "/app/trade/report/charge/order",
    method: "post",
    data: data,
  });
}


/**
 * 查询充值记录列表
 * @param query
 * @returns {*}
 */
export const listChargeOrder = (query?: QueryChargeOrderParam): AxiosPromise<ChainChargeRecordVO[]> => {
  return request({
    url: "/app/trade/list/charge/order",
    method: "get",
    params: query,
  });
};

/**
 * 提交提现订单
 * @param data
 * @returns
 */
export function submitWithdrawOrder(data: SubmitWithdrawOrderForm): AxiosPromise<WithdrawRecordVO> {
  return request({
    url: "/app/trade/submit/withdraw/order",
    method: "post",
    data,
  });
}

/**
 * 查询提出记录列表
 * @param params
 * @returns
 */
export function listWithdrawOrder(params?: QueryWithdrawOrderParam): AxiosPromise<WithdrawRecordVO[]> {
  return request({
    url: "/app/trade/list/withdraw/order",
    method: "get",
    params,
  });
}

/**
 * 提交闪兑订单
 * @returns
 */
export function submitSwapOrder(data: SubmitSwapOrderForm): AxiosPromise<SwapRecordVO> {
  return request({
    url: '/app/trade/submit/swap/order',
    method: 'post',
    data: data,
    headers: {
      isToken: true,
      isEncrypt: true,
      repeatSubmit: false
    },
  });
}

/**
 * 查询闪兑记录列表
 */
export function listSwapOrder(params: QuerySwapOrderParam): AxiosPromise<SwapRecordVO[]> {
  return request({
    url: '/app/trade/list/swap/order',
    method: 'get',
    params,
  });
}

/**
 * 提交转账订单
 * @param data
 */
export function submitTransferOrder(data: SubmitTransferOrderForm): AxiosPromise<TransferRecordVO>  {
  return request({
    url: '/app/trade/submit/transfer/order',
    method: 'post',
    data,
  });
}
/**
 * 查询转账记录列表
 * @param params
 */
export function listTransferOrder(params?: QueryTransferOrderParam): AxiosPromise<TransferRecordVO[]> {
  return request({
    url: '/app/trade/list/transfer/order',
    method: 'get',
    params,
  });
}