import TopAppBar from "@/components/top-app-bar";
import { TopAppBarProvider } from "@/components/top-app-bar/provider";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export default function AppLayout() {
    return (
        <Box className="layout layout-app">
            <TopAppBarProvider>
                <TopAppBar />
                <Box className="page-content" sx={{ pt: '56px',  bgcolor: "#091625",  height: '100vh', position: 'relative' }}>
                    <Outlet />
                </Box>
            </TopAppBarProvider>
        </Box>
    );
}