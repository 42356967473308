import request from "@/utils/request";
import { AxiosPromise } from "axios";
import { CreateCoustomerServiceChatForm, CustomerServiceChatRecordMessageVO, CustomerServiceChatRecordVO, QueryCustomerServiceChatRecordParams } from "./types/customerService";

/**
 * 创建客服聊天
 * @param data
 * @returns
 */
export function createCustomerServiceChat(data: CreateCoustomerServiceChatForm): AxiosPromise<any> {
  return request({
    url: "/app/conversation/create",
    method: "post",
    data,
  });
}

/**
 * 查询客服聊天列表
 * @param params
 * @returns
 */
export function fetchCustomerServiceChatList(params: QueryCustomerServiceChatRecordParams): AxiosPromise<CustomerServiceChatRecordVO[]> {
  return request({
    url: "/app/conversation/list",
    method: "get",
    params,
  });
}

/**
 * 查询客服聊天记录
 * @param params
 * @returns
 */
export function fetchCustomerServiceChatRecord(params: QueryCustomerServiceChatRecordParams, conversationId: string): AxiosPromise<CustomerServiceChatRecordMessageVO[]> {
  return request({
    url: `/app/conversation/detail/${conversationId}/list`,
    method: "get",
    params,
  });
}

/**
 * 回复客服会话
 * @param data
 */
export  function  replyCustomerServiceChat(data:{
  message: string;
  messageType: '0' | '1'
}, conversationId: string) {
  return request({
    url: `/app/conversation/reply/${conversationId}`,
    method: "post",
    data,
  });
}