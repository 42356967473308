import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Icon, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchBuyCdkOrder } from "@/api/package";
import { BuyCdkOrderVO } from "@/api/types/package";
import { useCopyToClipboard } from "react-use";
import { enqueueSnackbar } from "notistack";
import { bigNumberFormat } from "@/utils/common";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
const HarvestCard = ({ collapsed, payInfo }: { collapsed: boolean; payInfo: { amount: string; portfolio: string }[] }) => {
  const { t } = useTranslation();
  return (
    <Card
      className="list-item-card"
      sx={collapsed ? { bgcolor: "#0A3566", borderRadius: 1.5 } : { display: "none", bgcolor: "#0A3566", borderRadius: 1.5 }}
    >
      <CardContent sx={{ paddingBottom: "0.5rem !important", padding: 1.5 }}>
        {payInfo.map((item, index) => (
          <Box key={index}>
            <Typography color="#595959" component="span">
              {t(item.portfolio)}
            </Typography>
            <Typography component="span">{bigNumberFormat(item.amount)}</Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};
export default function SdkHistory() {
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const pageSize = useRef(10);
  const { t } = useTranslation();
  const [copiedIndex, setCopiedIndex] = useState<string>("0");
  const [total, setTotal] = useState(0);
  const [state, copyToClipboard] = useCopyToClipboard();
  const renderItem = useCallback(
    (item: BuyCdkOrderVO & { collapsed: boolean }, index?: number, data?: any[]) => {
      return (
        <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
          <CardContent>
            <Box>
              <Typography component="span" color="text.secondary">
                {t(item.packageCode)}
              </Typography>
              <Typography component="span">
                {item.status ? t("bd.rwa.package.buy.hisotry.cdk.status.used") : t("bd.rwa.package.buy.hisotry.cdk.status.unused")}
              </Typography>
            </Box>
            <Box>
              <Typography color="#595959" component="span">
                {t("package.buy.type.buy")}
              </Typography>
              <Typography
                component="span"
                onClick={() => {
                  const newItems = [...(data || [])];
                  newItems[index || 0].collapsed = !newItems[index || 0].collapsed;
                  scrollListRef.current?.setItems(newItems);
                }}
              >
              {bigNumberFormat(item.recordAmount)}
                <Typography component="span" color="text.secondary" sx={{ verticalAlign: "-0.4rem" }}>
                  {item.collapsed ? <ArrowDropDownSharp /> : <ArrowDropUpSharp />}
                </Typography>
              </Typography>
            </Box>
            <HarvestCard collapsed={item.collapsed} payInfo={item.payInfo} />
            <Box>
              <Typography color="#595959" component="span">
                {t("bd.rwa.package.buy.hisotry.cdk.serial.number")}
              </Typography>
              <Typography component="span">
                {item.cdKey}
                {copiedIndex === item.cdKeyRecordId ? (
                  <Icon className="icon-action-success" sx={{ verticalAlign: -5, ml: 1 }} />
                ) : (
                  <Icon
                    className="icon-action-copy"
                    sx={{ verticalAlign: -5, ml: 1 }}
                    onClick={() => {
                      copyToClipboard(item.cdKey);
                      if (!state.error) {
                        setCopiedIndex(item.cdKeyRecordId);
                        enqueueSnackbar(t("bd.rwa.invite.code.copy.success"), { variant: "success", autoHideDuration: 2000 });
                      } else {
                        enqueueSnackbar(state.error.message, { variant: "error", autoHideDuration: 2000 });
                      }
                    }}
                  />
                )}
              </Typography>
            </Box>
            <Box>
              <Typography color="#595959" component="span">
                {t("bd.order.create.date")}
              </Typography>
              <Typography color="#595959" component="span">
                {item.createTime || "--"}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      );
    },
    [copiedIndex]
  );

  const fetchData = async (page: number): Promise<BuyCdkOrderVO[]> => {
    const data = await fetchBuyCdkOrder({ pageNum: page, pageSize: pageSize.current });
    setTotal(data.total);
    return data.rows.map((item) => ({ ...item, collapsed: false })) || [];
  };
  return (
    <Box className="page-charge-history" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        pageSize={pageSize.current}
        total={total}
        idField="cdKeyRecordId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
    </Box>
  );
}
