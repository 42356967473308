import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  SvgIcon,
  Typography,
  TextField,
  InputAdornment,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as History } from "@/assets/images/history.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAccountPortfolioInfo } from "@/api/userinfo";
import { transferMethods } from "@/api/global";
import BigNumber from "bignumber.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AccountPortfolioVO } from "@/api/types";
import {submitTransferOrder } from "@/api/trade";
import UpdatePasswordDailog from "@/components/update-password-dialog";
import { enqueueSnackbar } from "notistack";
import PasswordInput from "@/components/password-input";
import { bigNumberFormat } from "@/utils/common";
interface TransferDailogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
}

export default function TransferDialog({ open, onClose, ...restProps }: TransferDailogProps) {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const queryClient = useQueryClient();
  const [updatePasswordOpen, setUpdatePasswordOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");
  const [receiver, setReceiver] = useState("");
  const [transferType, setTransferType] = useState(0);
  const { data: accountPortfolioInfo } = useQuery(
    {
      queryKey: ["accountPortfolio"],
      queryFn: getAccountPortfolioInfo,
    },
    queryClient
  );
  //处理关闭弹框
  const handleClose = () => {
    onClose();
  };
  const { data, isSuccess } = useQuery({
    queryKey: ["transferMethods"],
    queryFn: transferMethods,
  });
    useEffect(() => {
      if(!data?.data.length && isSuccess && open){
        enqueueSnackbar(t('bd.trade.type.transfer.method.empty.message'), { variant:'warning', autoHideDuration: 2000 })
        onClose();
      }
    }, [data?.data, isSuccess, open]);

  const current = accountPortfolioInfo?.data.find((item) => item.portfolio?.portfolioCode === data?.data[transferType]?.portfolio?.portfolioCode);
  return (
    <>
      <Dialog
        className="trade-swap-dialog"
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          component: "form",
          sx:{
            margin: 0,
            width:'calc(100% - 32px)'
          }
        }}
        {...restProps}
      >
        <DialogTitle>{t("bd.trade.type.transfer")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {t("bd.rwa.profile.transfer.dialog.transfer.amount")}
              </Typography>
              <Typography variant="body2">
                {t("portfolio.avaible")}:
                {bigNumberFormat(current?.balance,current?.portfolio!.portfolioDecimal)}
              </Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t("bd.rwa.profile.transfer.dialog.transfer.amount.placeholder")}
              value={amount}
              onFocus={(e)=>{
                setTimeout(()=> {
                  e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 500)
              }}
              sx={{
                mt: 1,
                "& .MuiInputBase-root": {
                  paddingLeft: "0",
                },
              }}
              onChange={(e) => setAmount(e.target.value)}
              slotProps={{
                input: {
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        setAmount(
                          BigNumber((current || ({} as AccountPortfolioVO)).balance || "0.0000").toFixed(2, BigNumber.ROUND_DOWN)
                        )
                      }
                    >
                      <Link>{t("bd.swap.action.transferAll")}</Link>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Select
                        fullWidth
                        variant="standard"
                        value={transferType}
                        sx={{
                          "&:before": {
                            borderBottom: "none !important",
                          },
                          "&:after": {
                            borderBottom: "none !important",
                          },
                        }}
                        onChange={(e) => {
                          setAmount("");
                          setTransferType(e.target.value as number);
                        }}
                      >
                        {data?.data.map((item, index) => (
                          <MenuItem key={index} value={index}>
                            {t(item.portfolio?.portfolioCode)}
                          </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {t("bd.rwa.profile.transfer.dialog.transfer.receiver")}UID
              </Typography>
            </Box>
            <TextField
              value={receiver}
              slotProps={{
                input: {
                  autoComplete: "off",
                },
              }}
              onFocus={(e)=>{
                setTimeout(()=> {
                  e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 500)
              }}
              onChange={(e) => setReceiver(e.target.value)}
              fullWidth
              sx={{
                mt: 1,
              }}
              variant="outlined"
              placeholder={`${t("bd.rwa.profile.transfer.dialog.transfer.receiver.placeholder")}UID`}
            />
          </Box>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {t("bd.withdraw.label.password")}
              </Typography>
            </Box>
            <PasswordInput
              placeholder={t("bd.input.password.placeholder")}
              onFocus={(e)=>{
                setTimeout(()=> {
                  e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }, 500)
              }}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {/*<TextField*/}
            {/*  fullWidth*/}
            {/*  sx={{*/}
            {/*    mt: 1,*/}
            {/*  }}*/}
            {/*  value={password}*/}
            {/*  variant="outlined"*/}
            {/*  placeholder={t("bd.input.password.placeholder")}*/}
            {/*  onChange={(e) => setPassword(e.target.value)}*/}
            {/*  slotProps={{*/}
            {/*    input: {*/}
            {/*      autoComplete: "off",*/}
            {/*      endAdornment: (*/}
            {/*        <InputAdornment position="end" onClick={()=> setUpdatePasswordOpen(true)}>*/}
            {/*          <Link>{t("bd.input.password.reset")}</Link>*/}
            {/*        </InputAdornment>*/}
            {/*      ),*/}
            {/*    },*/}
            {/*  }}*/}
            {/*/>*/}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: "0.625rem",
            pb: "1.25rem",
            mx: "1rem",
          }}
        >
          <Button variant="outlined" sx={{ flexGrow: 1 }} onClick={() => naviate("/app/transfer-history")}>
            <SvgIcon component={History} inheritViewBox />
          </Button>

          <Button variant="contained" sx={{ ml: 1, flexGrow: 2 }}
                  disabled={!(amount && password && receiver)}
                  onClick={ async ()=> {
             try {
               await  submitTransferOrder({
                 amount,
                 password,
                 uid:receiver,
                 settingId: data?.data[transferType].transferSettingId
               })
               onClose();
               setPassword("");
               setReceiver("");
               setTransferType(0);
               setAmount("");
               enqueueSnackbar(t("bd.rwa.profile.transfer.dialog.transfer.success"), { variant: "success",  autoHideDuration: 2000 });
             }catch (e) {
              console.log(e)
             }
          }}>
            {t("button.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <UpdatePasswordDailog open={updatePasswordOpen} onClose={()=> setUpdatePasswordOpen(false)} />
    </>
  );
}
