import type { FC } from "react";
import { Container, Card, CardContent, Typography, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import { NoticeRecordVO } from "@/api/types/noticeRecord";
import  { getNoticeDetail } from "@/api/announcement";
import { useQuery, useQueryClient } from "@tanstack/react-query";
const AnnouncementDetail: FC = () => {
  const location = useLocation();
  console.log(location.state)
  const notice = location.state.notice as NoticeRecordVO;
  const queryClient = useQueryClient()

  const { data } = useQuery({
      queryKey: ['noticeDetail', notice.noticeId],
      queryFn: ()=> getNoticeDetail(notice.noticeId)
  }, queryClient);
  return (
    <Container className="page-announcement" sx={{ my: 3 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div" sx={{ color: "text.secondary", mb: 3 }}>
            {data?.data.noticeTitle}
          </Typography>
          <Typography sx={{ color: "text.disabled", mb: 2 }}>{data?.data.createTime}</Typography>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body2" sx={{ color: "#ccc", lineHeight: 2, fontSize: 15 }}
           dangerouslySetInnerHTML={{ __html: data?.data.noticeContent as string }} />
        </CardContent>
      </Card>
    </Container>
  );
};
export default AnnouncementDetail;
