import { listWithdrawOrder } from "@/api/trade";
import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { useDictState, useDictTableState } from "@/stores/modules/dict";
import { Box, Card, CardContent, Container, Icon, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { useCallback, useEffect, useRef, useState } from "react";

import TradeFilterDialog, { FilterDialogValue } from "@/components/trade-filter-dialog";
import { useTranslation } from "react-i18next";
import "./withdraw-history.less";
import { useQuery } from "@tanstack/react-query";
import { withdrawMethods } from "@/api/global";
import { WithdrawRecordVO } from "@/api/types/withdrawRecord";

export default function TradeWithdrawHistory() {
  const { data: bdPortfolioDict } = useDictTableState("bd_portfolio");
  const { data: withdrawRecordStatus } = useDictState("wf_business_status");
  const [openFilterDailog, setOpenFilterDailog] = useState(false);
  const { t } = useTranslation();
  const pageSize = useRef(10);
  const [total, setTotal] = useState(0);
  const [] = useState([
    {
      label: "bd.select.all",
      value: "All",
    },
  ]);

  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const [filterValue, setFilterValue] = useState<FilterDialogValue>({
    currency: "All",
    startDate: null,
    endDate: null
  });
  const getTokenDictLabel = (dictCode: string | number) => {
    return bdPortfolioDict?.find((r) => r.code == dictCode)?.label;
  };

  const getStatusDictLabel = (status: string | number) => {
    return withdrawRecordStatus?.find((r) => r.value == status)?.label;
  };

  const renderItem = useCallback(
    (item: WithdrawRecordVO) => {
      return (
        <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "flex-end !important" }}>
              <Typography color="text.disabled" component="span">
                {t(getStatusDictLabel(item.status))}
              </Typography>
            </Box>
            <Box>
              <Typography color="text.disabled" component="span">
                {t("bd.withdraw.label")}
              </Typography>
              <Typography component="span">

                {BigNumber(item.portfolioAmount).toNumber()}
                {" " + t(getTokenDictLabel(item.portfolioId))}
              </Typography>
            </Box>
            <Box>
              <Typography color="text.disabled" component="span">
                {t("bd.order.create.date")}
              </Typography>
              <Typography color="text.disabled" component="span">
                {item.createTime}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      );
    },
    [bdPortfolioDict, withdrawRecordStatus]
  );

  const fetchData = async (page: number): Promise<WithdrawRecordVO[]> => {
    const data = await listWithdrawOrder({
      pageNum: page,
      pageSize: pageSize.current,
      params: {
        beginTime: filterValue.startDate?.format("YYYY-MM-DD"),
        endTime: filterValue.endDate?.format("YYYY-MM-DD"),
      },
      portfolioId: filterValue.currency === "All" ? undefined : filterValue.currency,
    });
    setTotal(data.total);
    console.log("fetchData page", page, "data=>", data);
    return data?.rows ?? [];
  };

  //筛选数据变化，立即重新加载数据
  useEffect(() => {
    if (filterValue.startDate && filterValue.endDate) {
      scrollListRef.current?.reload();
    }
  }, [filterValue]);

  const handleOnFilter = (newValue: FilterDialogValue) => {
    console.log("handleOnFilter ===> ", JSON.stringify(newValue));
    setFilterValue(newValue);
  };

  const ListHeader = (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Icon className="icon-select-date" onClick={() => setOpenFilterDailog(true)} />
    </Container>
  );

  const { data } = useQuery({
    queryKey: ["withdrawMethods"],
    queryFn: withdrawMethods,
  });

  return (
    <Box className="page-withdraw-history" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        pageSize={pageSize.current}
        total={total}
        // header={ListHeader}
        idField="withdrawRecordId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
      <TradeFilterDialog
        open={openFilterDailog}
        tokenSelectList={data?.data.map((r) => ({
          label: r.receivedToken?.tokenSymbol,
          value: r.portfolioId,
        }))}
        value={filterValue}
        onClose={() => setOpenFilterDailog(false)}
        onFilter={handleOnFilter}
      />
    </Box>
  );
}
