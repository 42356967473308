import { PaletteOptions } from '@mui/material/styles';

const DarkTheme: PaletteOptions = {
    primary: {
        main: '#27EDC6',
        dark: '#16477f',
        contrastText: '#ffffff'
    },
    secondary: {
        main: '#1565c0',
        contrastText: '#ffffff'
    },
    background: {
        default: '#091625',
        paper: '#042245',
    },
    text: {
        primary: '#27EDC6',
        secondary: '#ffffff',
        disabled: '#8c8c8c',

    },
    common: {
        black: '#020C18'
    }
}

export default DarkTheme