import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";

import Box from "@mui/material/Box";
import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import "./index.less";

import TopAppBar from "@/components/top-app-bar";
import { TopAppBarProvider } from "@/components/top-app-bar/provider";
import { useAuthCheck } from "@/utils/auth";
import { URL_JUMP_BANK } from "@/constant/global";

interface TabConfig {
  path?: string;
  needAuth?: boolean;
  externalUrl?: boolean;
  text: string;
  icon?: React.ReactNode;
}

interface NavbarContainerProps extends HTMLAttributes<HTMLDivElement> {
  tabs: Array<TabConfig>;
}

const NavIcon = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const combinedClassName = `nav-icon ${className || ""}`.trim();
  return <div className={combinedClassName} {...props} />;
};

const tabs: TabConfig[] = [
  {
    path: "/home/dashboard",
    text: "nav.bottom.home",
    icon: <NavIcon className="icon-home" />,
  },
  {
    path: URL_JUMP_BANK,
    externalUrl: true,
    text: "nav.bottom.bank",
    icon: <NavIcon className="icon-bank" />,
  },
  {
    path: "/home/digitalHuman",
    text: "nav.bottom.rwa",
    needAuth: true,
    icon: <NavIcon className="icon-rwa" />,
  },
  {
    // path: URL_JUMP_ANDY_BOT,
    externalUrl: true,
    text: "nav.bottom.bot",
    icon: <NavIcon className="icon-bot" />,
  },
  {
    path: "/home/burningProfile",
    text: "nav.bottom.mine",
    needAuth: true,
    icon: <NavIcon className="icon-mine" />,
  },
];

function NavbarContainer({ tabs, className }: NavbarContainerProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const authCheck = useAuthCheck();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const combinedClassName = `nav-bar-container ${className || ""}`.trim();

  let idx = 0;
  if (currentPath) {
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].path == currentPath) {
        idx = i;
        break;
      }
    }
  }

  if (location.state) {
    const { activeTab } = location.state;
    if (activeTab) {
      idx = activeTab;
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const config = tabs[newValue];

    if (!config.path) {
      return;
    }

    if (config.externalUrl) {
      window.location.href = config.path;
      return;
    }

    if (config.needAuth) {
      const success = authCheck();
      if (!success) {
        return;
      }
    }

    if (currentPath != config.path) {
      navigate(config.path, {
        replace: true,
      });
    }
  };

  return (
    <BottomNavigation showLabels className={combinedClassName} value={idx} onChange={handleChange} sx={{ bgcolor: '#020C18 '}}>
      {tabs.map((tab, index) => {
        return <BottomNavigationAction sx={{ color: "text.disabled" }} className="navigation-item" key={index} label={t(tab.text)} icon={tab.icon} />;
      })}
    </BottomNavigation>
  );
}

export default function HomeLayout() {
  return (
    <Box className="layout layout-home" >
      <TopAppBarProvider>
        <TopAppBar />
        <Box className="page-content" sx={{  bgcolor: "#042245",  py: "56px", height: "100vh", position: "relative" }}>
          <Outlet />
        </Box>
        <Paper className="navigation" sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
          <NavbarContainer tabs={tabs} />
        </Paper>
      </TopAppBarProvider>
    </Box>
  );
}
