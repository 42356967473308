import { listSwapOrder } from "@/api/trade";
import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { useDictState } from "@/stores/modules/dict";
import { Box, Card, CardContent, Container, Icon, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import {  swapMethods } from "@/api/global";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import "./charge-history.less";
import { SwapRecordVO, SwapSettingVo } from "@/api/types/swapSetting";
import { useQuery } from "@tanstack/react-query";

export default function TradeSwapHistory() {
  const { data: chargeRecordStatus } = useDictState("wf_business_status");
  const { t } = useTranslation();
  const scrollListRef = useRef<InfiniteScrollListRef>(null);

  const pageSize = useRef(10);
  const [total, setTotal] = useState(0);

  const getStatusDictLabel = (status: string | number) => {
    return chargeRecordStatus?.find((r) => r.value == status)?.label;
  };
  const { data: swapMethodsResponse } = useQuery({
    queryKey: ["swapMethods"],
    queryFn: swapMethods,
  });
  const swapPairMatch = useMemo(() => {
    const swapMethodList = swapMethodsResponse?.data ?? [];
    return (id: string | number, key: string) => {
      return swapMethodList.find(r => r[key as keyof typeof r] === id)
    }
  }, [swapMethodsResponse])
  const renderItem = useCallback(
    (item: SwapRecordVO) => {
      return (
        <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
          <CardContent>
            <Box alignItems="center" sx={{  justifyContent: 'flex-end !important' }}>
              <Typography color="text.disabled" component="span">{t(`swap.${getStatusDictLabel(item.status)}`)}</Typography>
            </Box>
            <Box alignItems="center">
              <Typography  component="span">
                {BigNumber(item.payPortfolioAmount).toFormat(swapPairMatch(item.fromPortfolioId, 'fromPortfolioId')?.fromPortfolio?.portfolioDecimal ||2)}&nbsp;{ t(swapPairMatch(item.fromPortfolioId, 'fromPortfolioId')?.fromPortfolio?.portfolioCode) }
              </Typography>
              <Icon sx={{ ml: 1 }} className="icon-dot-arrow" />
              <Typography component="span">
                {BigNumber(item.receivedPortfolioAmount ?? item.willReceivePortfolioAmount).toFormat(swapPairMatch(item.toPortfolioId, 'toPortfolioId')?.toPortfolio?.portfolioDecimal ||2)}&nbsp;{ t(swapPairMatch(item.toPortfolioId, 'toPortfolioId')?.toPortfolio?.portfolioCode) }
              </Typography>
            </Box>
            <Box alignItems="center">
              <Typography color="text.disabled" component="span">
                {t("bd.order.create.date")}
              </Typography>
              <Typography color="text.disabled" component="span">
                {item.createTime}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      );
    },
    [chargeRecordStatus, swapPairMatch]
  );

  const fetchData = async (page: number): Promise<SwapRecordVO[]> => {
    const data = await listSwapOrder({
      pageNum: page,
      pageSize: pageSize.current,
    });
    const rowData = data?.rows ?? [];
    setTotal(data.total);
    console.log("fetchData page", page, "data=>", data, rowData);
    return rowData;
  };
  return (
    <Box className="page-charge-history" sx={{ height: "100%", position: "relative" }}>
       <InfiniteScrollList
          ref={scrollListRef}
          pageSize={pageSize.current}
          total={total}
          idField="swapRecordId"
          renderItem={renderItem}
          fetchData={fetchData}
        />
    </Box>
  );
}
