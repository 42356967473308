import request from '@/utils/request';
import { AxiosPromise } from 'axios';
import type { ChainChargeSettingVO, DictDataVO, PackageVO, AccountPortfolioVO } from './types';
import { SwapSettingVo } from './types/swapSetting';
import { WithdrawSettingVO } from './types/withdrawSetting';
import { TransferSettingVO } from "@/api/types/transferSetting";

// 上传文件到oss对象存储
export function uploadFilesToOss(data: FormData):AxiosPromise<{fileName: string;
  ossId: string;
  url: string;
}> {
  return request({
    url: '/app/conversation/upload',
    method: 'POST',
    data
  })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType: string, isTable = false): AxiosPromise<DictDataVO[]> {
    return request({
      url: '/app/global/dict/type/' + dictType,
      method: 'get',
      params: {
        isTable: isTable
      }
    });
}
  
/**
 * 查询套餐列表
 */
export function packageList(): AxiosPromise<PackageVO[]> {
    return request({
        url: '/app/global/package/list',
        method: 'get'
    });
}

/**
 * 查询充值方法列表
 */
export function chargeMethods(): AxiosPromise<ChainChargeSettingVO[]> {
    return request({
        url: '/app/global/charge/methods',
        method: 'get'
    });
}

/**
 * 查询闪兑方法列表
 */
export function swapMethods(): AxiosPromise<SwapSettingVo[]> {
    return request({
        url: '/app/global/swap/methods',
        method: 'get'
    });
}

/**
 * 查询提现方法列表
 */
export function withdrawMethods(): AxiosPromise<WithdrawSettingVO[]> {
  return request({
    url: "/app/global/withdraw/methods",
    method: "get",
  });
}

/**
 * 查询转账方法列表
 */
export function  transferMethods(): AxiosPromise<TransferSettingVO[]> {
  return request({
    url: "/app/global/transfer/methods",
    method: "get",
  });
}


/**
 * 请求资产列表信息
 * @param data
 */
export function getUserPortfolioInfo(): AxiosPromise<AccountPortfolioVO[]> {
    return request({
        url: '/app/user/portfolioInfo',
        method: 'get'
    });
}