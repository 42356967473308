import { useSetPasswordDialog } from '@/components/set-password-dialog';
import { HttpStatus } from '@/enums/RespEnum';
import { logout } from '@/stores/modules/user';
import { getToken } from '@/utils/auth';
import { blobValidate, tansParams } from '@/utils/common';
import { decryptBase64, decryptWithAes, encryptBase64, encryptWithAes, generateAesKey } from '@/utils/crypto';
import { errorCode } from '@/utils/errorCode';
import { decrypt, encrypt } from '@/utils/jsencrypt';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import FileSaver from 'file-saver';
import cache from '@/plugins/cache';
import  { useTranslation } from 'react-i18next'
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from 'notistack';

const encryptHeader = 'encrypt-key';

// 是否显示重新登录
export const isRelogin = { show: false };
export const globalHeaders = () => {
  return {
    Authorization: 'Bearer ' + getToken(),
    clientid: process.env.REACT_APP_CLIENT_ID,
    "X-Chain-Support-Id": process.env.REACT_APP_CHAIN_SUPPORT_ID,
  };
};

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers['clientid'] = process.env.REACT_APP_CLIENT_ID;
axios.defaults.headers['X-Chain-Support-Id'] = process.env.REACT_APP_CHAIN_SUPPORT_ID;

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 50000
});

//拦截器是否增加
let interceptorSteuped = false

export const useAxiosInterceptors = () => {
  const confirm = useConfirm();
  const { t } = useTranslation()
  // const { lang } = useAppLang();
  const { showSetPasswordDialog } = useSetPasswordDialog();

  if (interceptorSteuped) {
    return;
  }

  // 请求拦截器
  service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    // 对应国际化资源文件后缀
    config.headers['Content-Language'] = cache.local.get('App-Lang') || 'en_US';

    const isToken = config.headers?.isToken === false;
    // 是否需要防止数据重复提交
    const isRepeatSubmit = config.headers?.repeatSubmit === false;
    // 是否需要加密
    const isEncrypt = config.headers?.isEncrypt === 'true';

    if (getToken() && !isToken) {
      config.headers['Authorization'] = 'Bearer ' + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }

    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      };
      const sessionObj = cache.session.getJSON('sessionObj');
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        cache.session.setJSON('sessionObj', requestObj);
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 500; // 间隔时间(ms)，小于此时间视为重复提交
        if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
          const message = '数据正在处理，请勿重复提交';
          console.warn(`[${s_url}]: ` + message);
          return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON('sessionObj', requestObj);
        }
      }
    }
    if (process.env.REACT_APP_ENCRYPT === 'true') {
      // 当开启参数加密
      if (isEncrypt && (config.method === 'post' || config.method === 'put')) {
        // 生成一个 AES 密钥
        const aesKey = generateAesKey();
        config.headers[encryptHeader] = encrypt(encryptBase64(aesKey));
        console.log(config.data, 'datalogin')
        config.data = typeof config.data === 'object' ?
          encryptWithAes(JSON.stringify(config.data), aesKey)
          :
          encryptWithAes(config.data, aesKey);
      }
    }
    // FormData数据去请求头Content-Type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }
    return config;
  },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  // 响应拦截器
  service.interceptors.response.use((res: AxiosResponse) => {
    if (process.env.REACT_APP_ENCRYPT === 'true') {
      // 加密后的 AES 秘钥
      const headers: any = res.headers
      const keyStr = headers && headers[encryptHeader];
      // 加密
      if (keyStr != null && keyStr != '') {
        const data = res.data;
        // 请求体 AES 解密
        const base64Str = decrypt(keyStr);
        // base64 解码 得到请求头的 AES 秘钥
        const aesKey = decryptBase64(base64Str.toString());
        // aesKey 解码 data
        const decryptData = decryptWithAes(data, aesKey);
        // 将结果 (得到的是 JSON 字符串) 转为 JSON
        res.data = JSON.parse(decryptData);
      }
    }
    // 未设置状态码则默认成功状态
    const code = res.data.code || HttpStatus.SUCCESS;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default'];
    // 二进制数据则直接返回
    if (res.request?.responseType === 'blob' || res.request?.responseType === 'arraybuffer') {
      return res.data;
    }

    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        confirm({
          title: t("request.dialog.logout.title"),
          description: t("request.dialog.warning.logout.message"),
          confirmationText: t("request.dialog.warning.logout.confirm"),
          hideCancelButton: true,
          allowClose: false
        }).then(() => {
          isRelogin.show = false;
          logout().then(() => {
            location.href = process.env.REACT_APP_CONTEXT_PATH;
          });
        }).catch(() => {
          isRelogin.show = false;
        });
      }
      return Promise.reject(new Error("无效的会话，或者会话已过期，请重新登录。"));
    } else if (code === HttpStatus.SERVER_ERROR) {
      if (res.data.msg == 'member.aut.password.not.set') {
        showSetPasswordDialog();
        //等待用户设置密码
        return Promise.reject(new Error(msg));
      }else if(res.data.msg === 'user.security.trade.permission.failure'){
        enqueueSnackbar(t('bd.withdaw.submit.fail.message'), { variant: 'error', autoHideDuration: 2000 });
       return Promise.reject(new Error(msg));
      }  else {
        enqueueSnackbar(t(msg), { variant: 'error', autoHideDuration: 2000 });
        return Promise.reject(new Error(msg));
      }
    } else if (code === HttpStatus.WARN) {
      enqueueSnackbar(msg, { variant: 'warning', autoHideDuration: 2000 });
      return Promise.reject(new Error(msg));
    } else if (code !== HttpStatus.SUCCESS) {
      enqueueSnackbar(msg, { variant: 'error', autoHideDuration: 2000 });
      return Promise.reject(new Error('error'));
    } else {
      return Promise.resolve(res.data);
    }
  },
    (error: any) => {
      let { message } = error;
      if (message == 'Network Error') {
        message = 'Network Error';
      } else if (message.includes('timeout')) {
        message = 'System Timeout';
      } else if (message.includes('Request failed with status code')) {
        message = 'Server Encountered  ' + message.substr(message.length - 3) + ' failure';
      }
      // enqueueSnackbar(message, { variant: 'error', autoHideDuration: 1000 });
      return Promise.reject(error);
    }
  );

  interceptorSteuped = true;
}

export const useDownload = () => {
  // 通用下载方法
  async function download(url: string, params: any, fileName: string) {
    // downloadLoadingInstance = ElLoading.service({ text: '正在下载数据，请稍候', background: 'rgba(0, 0, 0, 0.7)' });
    // prettier-ignore
    try {
      const resp: any = await service.post(url, params, {
        transformRequest: [
          (params_1: any) => {
            return tansParams(params_1);
          }
        ],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob'
      });
      const isLogin = blobValidate(resp);
      if (isLogin) {
        const blob = new Blob([resp]);
        FileSaver.saveAs(blob, fileName);
      } else {
        const resText = await resp.data.text();
        const rspObj = JSON.parse(resText);
        const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default'];
        enqueueSnackbar(errMsg, { variant: 'error' });
      }
      // downloadLoadingInstance.close();
    } catch (r) {
      console.error(r);
      enqueueSnackbar('An error occurred when downloading the file. Please contact the administrator', { variant: 'error', autoHideDuration: 2000 });
      // downloadLoadingInstance.close();
    }
  }

  return { download };
}

// 导出 axios 实例
export default service;
