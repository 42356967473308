import type { FC } from "react";
import { useCallback, useRef, useState } from "react";
import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Icon, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./announcement-list.less";
import { useNavigate } from "react-router-dom";
import { listNotice } from "@/api/announcement";
import { NoticeRecordVO } from "@/api/types/noticeRecord";
const AnnouncementList: FC = () => {
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const pageSize = useRef(10);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const fetchData = async (page: number): Promise<NoticeRecordVO[]> => {
    const data = await listNotice({
      pageNum: page,
      pageSize: pageSize.current,
    });
    setTotal(data.total);
    const rowData = data?.rows ?? [];
    return rowData;
  };
  const renderItem = useCallback((item: NoticeRecordVO) => {
    return (
      <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
        <CardContent>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6" color="text.secondary" sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
            }}>
              {item.noticeTitle}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {item.createTime}
            </Typography>
            <Typography component="span">
              <Link
                underline="none"
                onClick={() => {
                  navigate("/app/announcement-detail", { state: { notice: item } });
                }}
              >
                {t("bd.announcement.check.detail")}
                <Icon className="icon-arrow" sx={{ verticalAlign: "-0.35rem" }} />
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }, []);
  return (
    <Box className="page-announcement-list" sx={{ height: "100%", position: "relative" }}>
        <InfiniteScrollList
          ref={scrollListRef}
          pageSize={pageSize.current}
          total={total}
          idField="noticeId"
          renderItem={renderItem}
          fetchData={fetchData}
        />
    </Box>
  );
};
export default AnnouncementList;
