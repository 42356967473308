import { createTheme, ThemeProvider } from "@mui/material";
import { enUS, jaJP, koKR, Localization, thTH, viVN, zhCN, zhTW } from '@mui/material/locale';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { enUS as enUS_dp, jaJP as jaJP_dp, koKR as koKR_dp, viVN as viVN_dp, zhCN as zhCN_dp, zhHK as zhHK_dp } from '@mui/x-date-pickers/locales';
import React, { useMemo } from "react";

import { useAppLang } from "@/lang";
import { useAppTheme } from "@/theme";
import ComponentsConfig from '@/theme/config/components';
import PlatteConfig from '@/theme/config/platte';
import TypographyConfig from '@/theme/config/typography';

import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/th';
import 'dayjs/locale/vi';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';

const locales: Record<LangType, Localization> = {
    'en_US': enUS,
    'zh_CN': zhCN,
    'zh_TW': zhTW,
    'vi_VN': viVN,
    'ja_JP': jaJP,
    'ko_KR': koKR,
    'th_TH': thTH
}

const datePickerLocales: Record<string, any> = {
    'en_US': enUS_dp,
    'zh_CN': zhCN_dp,
    'zh_TW': zhHK_dp,
    'vi_VN': viVN_dp,
    'ja_JP': jaJP_dp,
    'ko_KR': koKR_dp
}

const dayJsLocales: Record<LangType, string> = {
    'en_US': 'en',
    'zh_CN': 'zh-cn',
    'zh_TW': 'zh-tw',
    'vi_VN': 'vi',
    'ja_JP': 'ja',
    'ko_KR': 'ko',
    'th_TH': 'th'
};

export default function BdThemeProvider({ children }: { children: React.ReactNode }) {
    const { lang } = useAppLang()
    const { appTheme } = useAppTheme()

    const theme = useMemo(
        () =>
            createTheme({
                cssVariables: true,
                palette: PlatteConfig[appTheme],
                typography: TypographyConfig,
                components: ComponentsConfig,
            }, locales[lang], datePickerLocales[lang]),

        [appTheme, lang],
    );

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayJsLocales[lang] as any}>
                {children}
            </LocalizationProvider>
        </ThemeProvider>);
}