import { getDicts } from "@/api/global";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export function useDictState(dictType: string): UseQueryResult<DictDataOption[], any> {
    return useQuery({
        queryKey: ['dict', { dictType, isTable: false }],
        queryFn: async () => {
            const data = await getDicts(dictType);
            return data.data.map(r => ({
                label: r.dictLabel,
                code: r.dictCode,
                value: r.dictValue,
            }))
        }
    })
}

export function useDictTableState(dictType: string): UseQueryResult<DictDataOption[], any> {
    return useQuery({
        queryKey: ['dict', { dictType, isTable: true }],
        queryFn: async () => {
            const data = await getDicts(dictType, true);
            return data.data.map(r => ({
                label: r.dictLabel,
                code: r.dictCode,
                value: r.dictValue,
            }))
        }
    })
}