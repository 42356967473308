import LogoNav from "@/assets/images/logo-nav.png";
import { URL_JUMP_ANDY_BOT, URL_JUMP_BANK } from "@/constant/global";
import { logout, useToken } from "@/stores/modules/user";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Drawer,
  DrawerProps,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { Fragment, ReactNode,  useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCard from "../account-card";
import "./index.less";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { getUserInfo } from "@/api/userinfo";
import { AccountVO } from "@/api/types";
interface MenuDrawerProps extends DrawerProps {
  mode: MenuMode;
}

interface MenuConfigItem {
  title: string;
  icon?: ReactNode;
  children?: MenuConfigItem[];
  disabled?: boolean;
  onClick?: () => void;
}

export default function MenuDrawer({ mode, ...restProps }: MenuDrawerProps) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState<AccountVO | undefined>();
  const token = useToken();
  const queryUserInfo = () => {
    getUserInfo().then(({ data: _userInfo }) => {
      setUserInfo(_userInfo);
    }).catch(err => {
      console.log(err);
    });
  };
  if (mode === "RWA" && !userInfo) {
    queryUserInfo();
  }

  const walletItems = [
    {
      title: "nav.bottom.home",
      icon: <Icon className="icon-menu-wallet" />,
      disabled: !token,
      onClick: () => {
        restProps.onClose && restProps.onClose({}, "backdropClick");
        navigate("/home/dashboard", {
          replace: true,
        });
      },
    },
  ];

  const serviceItems = [
    {
      title: "nav.menu.announcement",
      icon: <Icon className="icon-menu-announcement" />,
      disabled: !token,
      onClick: () => {
        console.log("announcement");
        restProps.onClose && restProps.onClose({}, "backdropClick");
        navigate("/app/announcement", {
          replace: true,
        });
      },
    },
    {
      title: "nav.menu.customer",
      icon: <Icon className="icon-menu-customer" />,
      disabled: !token,
      children: [
        {
          title: "nav.menu.customer",
          onClick: () => {
            restProps.onClose && restProps.onClose({}, "backdropClick");
            navigate("/app/support", {
              replace: true,
            });
          },
        },
        {
          title: "nav.menu.mailbox",
          onClick: () => {
            console.log("mailbox");
            restProps.onClose && restProps.onClose({}, "backdropClick");
            navigate("/app/inbox", {
              replace: true,
            });
          },
        },
      ],
    },
  ];

  const burMenuConfigList: MenuConfigItem[][] = [
    walletItems,
    [
      {
        title: "nav.bottom.bank",
        icon: <Icon className="icon-menu-bank" />,
        onClick: () => {
          restProps.onClose && restProps.onClose({}, "backdropClick");
          window.location.href = URL_JUMP_BANK;
        },
      },
      {
        title: "nav.menu.rwa",
        icon: <Icon className="icon-menu-rwa" />,
        disabled: !token,
        onClick: () => {
          restProps.onClose && restProps.onClose({}, "backdropClick");
          navigate("/home/digitalHuman", {
            replace: true,
          });
        },
      },
    ],
    [
      {
        title: "nav.menu.wsk",
        disabled: !token,
        icon: <Icon className="icon-menu-wsk" />,
      },
      {
        title: "nav.menu.enterainment",
        icon: <Icon className="icon-menu-andybot" />,
        disabled: !token,
        onClick: () => {
          restProps.onClose && restProps.onClose({}, "backdropClick");
          // window.location.href = URL_JUMP_ANDY_BOT;
        },
      },
      {
        title: "nav.menu.zootopia",
        disabled: !token,
        icon: <Icon className="icon-menu-zootopia" />,
      },
    ],
    serviceItems,
  ];
  const rwaMenuConfigList: MenuConfigItem[][] = [
    walletItems,
    [
      {
        title: "page.title.package",
        icon: <Icon className="icon-menu-package" />,
        onClick: () => {
          restProps.onClose && restProps.onClose({}, "backdropClick");
          navigate("/home/digitalHuman", {
            replace: true,
          });
        },
      },
      {
        title: "page.title.income",
        icon: <Icon className="icon-menu-income" />,
        onClick: () => {
          if (userInfo?.activated === "N") {
            return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
          }
          restProps.onClose && restProps.onClose({}, "backdropClick");
          navigate("/home/income", {
            state: {
              activeTab: 2,
            },
          });
        },
      },
    ],
    [
      {
        title: "page.title.bonusReport",
        icon: <Icon className="icon-menu-bonus-report" />,
        children: userInfo?.setting?.showBucket
          ? [
              {
                title: "page.title.report.bonus.eco",
                onClick: () => {
                  if (userInfo?.activated === "N") {
                    return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
                  }
                  restProps.onClose && restProps.onClose({}, "backdropClick");
                  navigate("/app/ai-OutermModelBonus", {
                    replace: true,
                  });
                },
              },
              {
                title: "page.title.report.bonus.initial",
                onClick: () => {
                  if (userInfo?.activated === "N") {
                    return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
                  }
                  restProps.onClose && restProps.onClose({}, "backdropClick");
                  navigate("/app/togetherCreateBonus", {
                    replace: true,
                  });
                },
              },
              {
                title: "page.title.report.bonus.intelligence",
                onClick: () => {
                  if (userInfo?.activated === "N") {
                    return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
                  }
                  restProps.onClose && restProps.onClose({}, "backdropClick");
                  navigate("/app/intelligenceBonus", {
                    replace: true,
                  });
                },
              },
              {
                title: "page.title.report.bonus.special",
                onClick: () => {
                  if (userInfo?.activated === "N") {
                    return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
                  }
                  restProps.onClose && restProps.onClose({}, "backdropClick");
                  navigate("/app/specialBonus", {
                    replace: true,
                  });
                },
              },
            ]
          : [
              {
                title: "page.title.report.bonus.eco",
                onClick: () => {
                  if (userInfo?.activated === "N") {
                    return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
                  }
                  restProps.onClose && restProps.onClose({}, "backdropClick");
                  navigate("/app/ai-OutermModelBonus", {
                    replace: true,
                  });
                },
              },
              {
                title: "page.title.report.bonus.initial",
                onClick: () => {
                  if (userInfo?.activated === "N") {
                    return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
                  }
                  restProps.onClose && restProps.onClose({}, "backdropClick");
                  navigate("/app/togetherCreateBonus", {
                    replace: true,
                  });
                },
              },
              {
                title: "page.title.report.bonus.intelligence",
                onClick: () => {
                  if (userInfo?.activated === "N") {
                    return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
                  }
                  restProps.onClose && restProps.onClose({}, "backdropClick");
                  navigate("/app/intelligenceBonus", {
                    replace: true,
                  });
                },
              },
            ],
      },
    ],
    [
      {
        title: "page.title.community",
        icon: <Icon className="icon-menu-community" />,
        onClick: () => {
          if (userInfo?.activated === "N") {
            return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
          }
          restProps.onClose && restProps.onClose({}, "backdropClick");
          navigate("/home/community", {
            state: {
              activeTab: 2,
            },
          });
        },
      },
      {
        title: "page.title.invite",
        icon: <Icon className="icon-menu-invite" />,
        onClick: () => {
          if (userInfo?.activated === "N") {
            return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
          }
          restProps.onClose && restProps.onClose({}, "backdropClick");
          navigate("/home/invite", {
            state: {
              activeTab: 2,
            },
          });
        },
      },
    ],
    [
      {
        title: "page.title.rwaProfile",
        icon: <Icon className="icon-menu-rwa-profile" />,
        onClick: () => {
          if (userInfo?.activated === "N") {
            return enqueueSnackbar(t("user.not.active.error.message"), { variant: "error", autoHideDuration: 2000 });
          }
          restProps.onClose && restProps.onClose({}, "backdropClick");
          navigate("/home/rwaProfile", {
            state: {
              activeTab: 2,
            },
          });
        },
      },
    ],
    serviceItems,
  ];

  const menuConfigList = mode == "RWA" ? rwaMenuConfigList : burMenuConfigList;
  const [menuActive, setMenuActive] = useState<Record<string, boolean>>({});

  const handleListItemClick = (item: MenuConfigItem, indexs: number[]) => {
    if (item.children && item.children.length > 0) {
      //console.log("handleListItemClick", item, indexs);
      const updateKey = `item-${indexs[0]}-${indexs[1]}`;
      //console.log("handleListItemClick 2", menuActive, updateKey, menuActive[updateKey]);
      setMenuActive({
        ...menuActive,
        [`${updateKey}`]: !menuActive[updateKey],
      });
      item.children[indexs[2]]?.onClick?.();
    } else {
      item.onClick?.();
    }
  };

  const handleLogout = async () => {
    restProps.onClose && restProps.onClose({}, "backdropClick");

    confirm({
      title: t("button.logout"),
      description: t("logout.confirm.description"),
      cancellationText: t("button.close"),
      confirmationText: t("button.confirm"),
      titleProps: {
        sx: {
          textAlign: "left !important",
        },
      },
      dialogActionsProps: {
        sx: {
          justifyContent: "center",
        },
      },
      cancellationButtonProps: {
        variant: "contained",
        style: {
          height: "30px",
          flexGrow: 1,
          minWidth: "unset",
        },
      },
      confirmationButtonProps: {
        variant: "contained",
        style: {
          height: "30px",
          flexGrow: 1,
          minWidth: "unset",
        },
      },
    })
      .then(async () => {
        const result = await logout();
        console.warn("handleLogout", result);
        window.location.href = "/";
      })
      .catch((error) => {
        console.log("logout canceled", error);
      });
  };

  return (
    <Drawer {...restProps} className="menu-drawer">
      <Container
        role="presentation"
        sx={{
          overflowY: "scroll",
          height: "100vh",
          backgroundColor: "background.default",
          py: 4,
        }}
      >
        <Box display="flex" justifyContent="center">
          <img className="logo log-nav" src={LogoNav} style={{ height: "1.5rem" }}></img>
        </Box>

        <AccountCard variant="menu" size="small" sx={{ mt: 2.5, mb: 1, backgroundImage: "none" }} />

        {menuConfigList.map((r, index) => {
          return (
            <Card key={index} sx={{ borderRadius: "1rem", my: 1 }}>
              <CardContent sx={{ p: "0 !important" }}>
                <List
                  className={`menu-list menu-list-${index}`}
                  sx={{
                    p: 0,
                    "&  .MuiListItemButton-root": {
                      "&:not(:last-child)": {
                        position: "relative",

                        "&::after": {
                          content: '""',
                          position: "absolute",
                          left: "1rem",
                          right: 0,
                          bottom: 0,
                          height: "1px",
                          backgroundColor: "divider",
                        },
                      },
                    },
                  }}
                >
                  {r.map((j, idx) => {
                    return (
                      <Fragment key={`menu-item menu-item-${idx}`}>
                        <ListItemButton onClick={handleListItemClick.bind(null, j, [index, idx])} disabled={j.disabled}>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              paddingRight: 1,
                              "& .MuiIcon-root": {
                                fontSize: "0.75rem",
                              },
                            }}
                          >
                            {j.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(j.title)}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "0.75rem",
                              },
                            }}
                          />

                          {j.children ? (
                            menuActive["item-" + index + "-" + idx] ? (
                              <ExpandLess sx={{ color: "primary.dark" }} />
                            ) : (
                              <ExpandMore sx={{ color: "primary.dark" }} />
                            )
                          ) : (
                            <ArrowForwardIos sx={{ fontSize: "88%", color: "primary.dark" }} />
                          )}
                        </ListItemButton>

                        {j.children ? (
                          <Collapse in={menuActive["item-" + index + "-" + idx]} timeout="auto" unmountOnExit>
                            <List disablePadding>
                              {j.children.map((k, idex) => {
                                return (
                                  <ListItemButton key={idex} onClick={handleListItemClick.bind(null, j, [index, idx, idex])}>
                                    <ListItemText
                                      primary={t(k.title)}
                                      sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: "0.75rem",
                                        },
                                      }}
                                    />
                                  </ListItemButton>
                                );
                              })}
                            </List>
                          </Collapse>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    );
                  })}
                </List>
              </CardContent>
            </Card>
          );
        })}

        <Button
          fullWidth
          disabled={!token}
          sx={{
            my: 1,
            height: "2.5rem",
            borderRadius: "1rem",
            backgroundColor: "background.paper",
            backgroundImage: "var(--mui-overlays-2)",
            color: "text.primary",
          }}
          onClick={handleLogout}
        >
          {t("button.logout")}
        </Button>
      </Container>
    </Drawer>
  );
}
