import kryptonLogo from '@/assets/images/kryptonLogo.gif';
import AccountCard from '@/components/account-card';
import { Box, Card, CardContent, CardProps, Container, Icon, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './dashboard.less';
import { useToken } from "@/stores/modules/user";
import { useAuthCheck } from "@/utils/auth";
interface ItemProps extends CardProps {
    title: string
    subTitle?: string,
    path?: string;
    externalUrl?: boolean;
}

const Item = ({ title, subTitle, ...restProps }: ItemProps) => {
    return <Card {...restProps}>
        <CardContent sx={{ padding: "0.75rem", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box>
                <Typography>
                    {title}
                </Typography>
                <Typography>
                    {subTitle}
                </Typography>
            </Box>
            <Icon className='icon-arrow-right' />
        </CardContent>
    </Card>
}

export default function DashBoard() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const token = useToken();
    const authCheck = useAuthCheck();
    return <Box className='page-home tab-dashboard'>
        <Box className="part-1">
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: '1' }}>
                <Box sx={{ mt: 2.25 }}>
                    <img className="logo" src={kryptonLogo} />
                </Box>
                <AccountCard
                    variant='home' sx={{ mt: 2.25, mb: 3.75, width: '100%', backgroundImage: 'none' }} />
            </Container>
        </Box>

        <Container sx={{ marginBottom: '1rem' }}>
            <Grid container spacing={2.25}>
                <Grid size={6}>
                    <Grid>
                        <Grid size={12}>
                            <Item
                                className="item-link item-1"
                                sx={{ aspectRatio: '330 / 200', bgcolor: 'transparent' }}
                                title={t('page.dashboard.linkcard.title.bank')}
                                onClick={() => {
                                    window.location.href = 'https://www.spacestation.company/'
                                }}
                            />
                        </Grid>

                        <Grid size={12} sx={{ mt: 1 }}>
                            <Item
                                className="item-link item-2"
                                sx={{ aspectRatio: '330 / 200', bgcolor: 'transparent' }}
                                title={t('nav.menu.wsk')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid size={6}>
                    <Item
                        className="item-link item-3"
                        sx={{ aspectRatio: '330 / 417', bgcolor: 'transparent' }}
                        title={t('page.dashboard.linkcard.title.ai')}
                        onClick={() => {
                            const success = authCheck();
                            if (success && token) {
                                navigate('/home/digitalHuman', { replace: true })
                            }
                        }}
                    />
                </Grid>

                <Grid size={6}>
                    <Item
                        className="item-link item-4"
                        sx={{ aspectRatio: '330 / 200', bgcolor: 'transparent' }}
                        title={t('nav.menu.enterainment')}
                        onClick={() => {
                            // window.location.href = 'https://andy-bot.com/'
                        }}
                    />
                </Grid>

                <Grid size={6}>
                    <Item
                        className="item-link item-5"
                        sx={{ aspectRatio: '330 / 200', bgcolor: 'transparent' }}
                        title="Krypton MEME"
                    />
                </Grid>
            </Grid>
        </Container>
    </Box>
}