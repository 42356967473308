import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { useDictState } from "@/stores/modules/dict";
import { useTranslation } from "react-i18next";
import { BdAccountPortfolioChangeVo, queryTrainngValueIncomeRecord } from "@/api/bonus";
import { bigNumberFormat } from "@/utils/common";
export default function TrainingHistory() {
  const scrollListRef = useRef<InfiniteScrollListRef>(null);
  const pageSize = useRef(10);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const { data: chargeRecordStatus } = useDictState("portfolio_change_origin_type");
  const renderItem = useCallback((item: BdAccountPortfolioChangeVo) => {
    const type = chargeRecordStatus?.find(current => Number(current.value) === Number(item.originType))
    return (
      <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
        <CardContent>
          <Box>
            <Typography color="text.disabled" component="span">
              {t(type?.label)}
            </Typography>
            <Typography component="span" color={item.changeType === 2 ? "#E47D55" : undefined}>
              {item.changeType === 1 ? "+" : "-"}&nbsp;{bigNumberFormat(item.amount)}
              <Typography component="span" sx={{ ml:1}}>
                {t(item.portfolio.portfolioCode)}
                </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography color="text.disabled" component="span">
              {t("bd.order.create.date")}
            </Typography>
            <Typography color="text.disabled" component="span">
              {item.processDate || "--"}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }, [chargeRecordStatus]);
  const fetchData = async (pageNum: number): Promise<BdAccountPortfolioChangeVo[]> => {
    const data = await queryTrainngValueIncomeRecord({
      pageNum,
      pageSize: pageSize.current,
    });
    setTotal(data.total);
    return data.rows || [];
  };
  return (
    <Box className="page-training-history" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        pageSize={pageSize.current}
        total={total}
        idField="changeId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
    </Box>
  );
}
