import { createContext, ReactNode, useContext, useState } from 'react';

interface TopAppBarContextValue {
    title: string;
    setTitle: (title: string) => void;

    showBackButton: boolean;
    setShowBackButton: (show: boolean) => void;

    menuMode: MenuMode;
    setMenuMode: (mode: MenuMode) => void;
}

const TopAppBarContext = createContext<TopAppBarContextValue | null>(null);

export const TopAppBarProvider = ({ children }: { children?: ReactNode }) => {
    const [title, setTitle] = useState<string>('');
    const [showBackButton, setShowBackButton] = useState<boolean>(false);
    const [menuMode, setMenuMode] = useState<MenuMode>('KC');

    return <TopAppBarContext.Provider value={{
        title,
        setTitle,
        showBackButton,
        setShowBackButton,
        menuMode,
        setMenuMode
    }}>
        {children}
    </TopAppBarContext.Provider>
}

export const useTopAppBar = () => {
    const context = useContext(TopAppBarContext);
    if (context === null) {
        throw new Error('useTopAppBar must be used within a TopAppBarProvider');
    }
    return context;
};