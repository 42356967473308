import { Button, ClickAwayListener, Grow, MenuList, Paper, Popper, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { type KeyboardEvent, type MouseEvent, type SyntheticEvent, useEffect, useRef, useState } from "react";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

interface DropdownMenuProps {
  items:  {
    title: string;
    key: string
  }[];
  selectedKey?: string;
  variant?: 'text' | 'outlined'
  showArrow: boolean;
  onSelect?: (key: string) => void
}

export default  function DropdownMenu ({ items, selectedKey, onSelect, variant = "text", showArrow = false }: DropdownMenuProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleMenuItemClick = (
    event: MouseEvent<HTMLElement>,
    key: string
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    onSelect && onSelect(key)
    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        variant={variant}
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{ minWidth: "6.5rem", borderRadius: "1rem", height: "1.75rem" }}
        onClick={handleToggle}
      >
        <Typography component="span">{items.find(item => item.key == selectedKey)?.title}</Typography>
        {showArrow && <ArrowDropDownIcon />}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" sx={{
        zIndex: 999
      }} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                  {items.map((option) => (
                    <MenuItem
                      key={option.key}
                      selected={option.key === selectedKey}
                      sx={{ width: "6.5rem" }}
                      onClick={(event) =>handleMenuItemClick(event, option.key)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}