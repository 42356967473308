import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { BdBonusIntelligenceVo } from "@/api/bonus";
import BigNumber from "bignumber.js";
interface RightAreaDialogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  bonusIntelligence?: BdBonusIntelligenceVo;
}
export default function RightAreaDialog({ open, onClose, bonusIntelligence, ...restProps }: RightAreaDialogProps) {
  //处理关闭弹框
  const handleClose = () => {
    onClose();
  };
  const { t } = useTranslation();
  return (
    <Dialog
      className="right-area-dialog"
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx: {
          margin: 0,
          width: "calc(100% - 32px)",
        },
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.rwa.bonus.right.area")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Card sx={{ border: "none !important", backgroundColor: "#042245 !important" }}>
          <CardContent>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                <Typography component="span" color="#595959">
                  B/F BV
                </Typography>
                <Typography component="span">{BigNumber(bonusIntelligence?.rightHistorySumBv || "0.0000").toFormat(2)}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                <Typography component="span" color="#595959">
                  {t("bd.rwa.bonus.new.bv")}
                </Typography>
                <Typography component="span">{BigNumber(bonusIntelligence?.rightBv || "0.0000").toFormat(2)}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button variant="contained" sx={{ ml: 1 }} onClick={() => onClose()}>
          {t("button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
