import { listChargeOrder } from "@/api/trade";
import { ChainChargeRecordVO } from "@/api/types";
import InfiniteScrollList, { InfiniteScrollListRef } from "@/components/infinite-scroll-list";
import { useDictState, useDictTableState } from "@/stores/modules/dict";
import { Box, Card, CardContent, Container, Icon, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { useCallback, useEffect, useRef, useState } from "react";

import TradeFilterDialog, { FilterDialogValue } from "@/components/trade-filter-dialog";
import { useTranslation } from "react-i18next";
import "./charge-history.less";

export default function TradeChargeHistory() {
  const { data: sysChainTokenDict } = useDictTableState("sys_chain_token");
  const { data: chargeRecordStatus } = useDictState("wf_business_status");
  const [openFilterDailog, setOpenFilterDailog] = useState(false);
  const { t } = useTranslation();
  const pageSize = useRef(10);
  const [total, setTotal] = useState(0);
  const scrollListRef = useRef<InfiniteScrollListRef>(null);

  const [filterValue, setFilterValue] = useState<FilterDialogValue>({
    currency: "All",
    startDate: null,
    endDate: null
  });

  const getTokenDictLabel = (dictCode: string | number) => {
    return sysChainTokenDict?.find((r) => r.code == dictCode)?.label;
  };

  const getStatusDictLabel = (status: string | number) => {
    return chargeRecordStatus?.find((r) => r.value == status)?.label;
  };

  const renderItem = useCallback(
    (item: ChainChargeRecordVO) => {
      return (
        <Card className="list-item-card" sx={{ mx: 2, my: 1, borderRadius: 3.5 }}>
          <CardContent>
            <Box>
              <Typography color="text.disabled" component="span">
                {t("bd.order.status")}
              </Typography>
              <Typography>{t(getStatusDictLabel(item.status))}</Typography>
            </Box>
            <Box>
              <Typography color="text.disabled" component="span">
                {t("bd.order.amount")}
              </Typography>
              <Typography component="span">
                {BigNumber(item.claimSendTokenAmount).toNumber()}
                {" " + getTokenDictLabel(item.chargeTokenId)}
              </Typography>
            </Box>
            <Box>
              <Typography color="text.disabled" component="span">
                {t("bd.order.create.date")}
              </Typography>
              <Typography color="text.disabled" component="span">
                {item.createTime}
              </Typography>
            </Box>
            <Box>
              <Typography color="text.disabled" component="span">
                {t("bd.order.number")}
              </Typography>
              <Typography color="text.disabled">{item.chargeRecordId}</Typography>
            </Box>
          </CardContent>
        </Card>
      );
    },
    [sysChainTokenDict, chargeRecordStatus]
  );

  const fetchData = async (page: number): Promise<ChainChargeRecordVO[]> => {
    const data = await listChargeOrder({
      pageNum: page,
      pageSize: pageSize.current,
      params: {
        beginTime: filterValue.startDate?.format("YYYY-MM-DD"),
        endTime: filterValue.endDate?.format("YYYY-MM-DD"),
      },
      tokenSymbol: filterValue.currency ?? undefined,
    });
    const rowData = data?.rows ?? [];
    setTotal(data.total);
    console.log("fetchData page", page, "data=>", data, rowData);
    return rowData;
  };

  //筛选数据变化，立即重新加载数据
  useEffect(() => {
    if(filterValue.startDate && filterValue.endDate) {
      scrollListRef.current?.reload();
    }
  }, [filterValue]);

  const handleOnFilter = (newValue: FilterDialogValue) => {
    console.log("handleOnFilter ===> ", JSON.stringify(newValue));
    setTotal(0)
    setFilterValue(newValue);
  };

  const ListHeader = (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Icon className="icon-select-date" onClick={() => setOpenFilterDailog(true)}></Icon>
    </Container>
  );
  return (
    <Box className="page-charge-history" sx={{ height: "100%", position: "relative" }}>
      <InfiniteScrollList
        ref={scrollListRef}
        pageSize={pageSize.current}
        total={total}
        // header={ListHeader}
        idField="chargeRecordId"
        renderItem={renderItem}
        fetchData={fetchData}
      />
      <TradeFilterDialog open={openFilterDailog} value={filterValue} onClose={() => setOpenFilterDailog(false)} onFilter={handleOnFilter} />
    </Box>
  );
}
