import { getUserPortfolioInfo, withdrawMethods } from "@/api/global";
import { submitWithdrawOrder } from "@/api/trade";
import type { WithdrawSettingVO } from "@/api/types/withdrawSetting";
import { ReactComponent as History } from "@/assets/images/history.svg";
import SwitchTab from "@/components/switch-tab";
import { Box, BoxProps, Button, Container, SvgIcon, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import UpdatePasswordDailog from "@/components/update-password-dialog";
import PortfolioInput, { PortfolioInputValue } from "@/components/portfolio-input";
import PasswordInput from "@/components/password-input";
import { bigNumberFormat } from "@/utils/common";

interface WithdrawFragmentProps extends BoxProps {
  setting: WithdrawSettingVO;
}

const WithdrawFragment = ({ setting, ...restProps }: WithdrawFragmentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [withdrawAmount, setWithdrawAmount] = useState<PortfolioInputValue>({
    amount: "",
    portfolio: {
      id: setting.portfolio?.portfolioId ?? "",
      label: t(setting.portfolio?.portfolioCode),
    },
  });

  const [withdrawPassword, setWithdrawPassword] = useState("");
  const [openUpdatePasswordDailog, setOpenUpdatePasswordDailog] = useState(false);
  const queryClient = useQueryClient();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (withdrawAmount && withdrawAmount.amount && withdrawPassword && BigNumber(withdrawAmount.amount).mod(10).comparedTo(0) === 0) {
        setIsDisabled(false)
    } else  {
      setIsDisabled(true)
    }

  }, [withdrawAmount, withdrawPassword]);

  const { data } = useQuery(
    {
      queryKey: ["getUserPortfolioInfo"],
      queryFn: getUserPortfolioInfo,
    },
    queryClient
  );

  let balanceCash = "0.00";

  if (data?.data) {
    const [accountPortfolio] = data.data.filter((item) => item.portfolio?.portfolioId == setting.portfolioId);
    const decimal = accountPortfolio?.portfolio?.portfolioDecimal ?? 2;
    if (accountPortfolio == null) {
      balanceCash = bigNumberFormat('0.00', decimal);
    } else {
      balanceCash = bigNumberFormat(accountPortfolio.balance, decimal);
    }
  }
  const afterWithdrawSuccess = async () => {
    //清空提现金额
    setWithdrawAmount({
      ...withdrawAmount,
      amount: 0,
    });

    setWithdrawPassword("");
    //刷新余额
    await queryClient.invalidateQueries({ queryKey: ["getUserPortfolioInfo"] });
  };

  const handleWithdraw = async () => {
    try {
      const data = {
        settingId: setting.withdrawSettingId,
        amount: withdrawAmount.amount,
        password: withdrawPassword,
      };
      const reportResult = await submitWithdrawOrder(data);
      // console.log("reportWithdrawOrder", reportResult);
      enqueueSnackbar(t("bd.withdaw.submit.success.message"), { variant: "success",  autoHideDuration: 2000 });
      await afterWithdrawSuccess();
    } catch (error) {
      console.log("send token tx error", error);
      // enqueueSnackbar(t("bd.withdaw.submit.fail.message") + error, { variant: "error",  autoHideDuration: 2000 });
    }
  };

  return (
    <Box className="withdraw-frament" sx={{ mt: 1 }} {...restProps}>
      <Box className="field-amount">
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {t("bd.withdraw.label")}
          </Typography>
          <Box className="wallet-balance" display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography>{t("portfolio.avaible") + ": " + balanceCash }</Typography>
          </Box>
        </Box>

        <PortfolioInput
          value={withdrawAmount}
          onChange={(val) => {
            setWithdrawAmount({
              ...val,
              amount: (val.amount as string).replace(/\D/g, '')
            });
          }}
          placeholder={t("bd.withdaw.amount.placeholder")}
          inputable
        />
      </Box>

      <Box className="field-password">
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {t("bd.withdraw.label.password")}
          </Typography>
        </Box>

        <PasswordInput
          value={withdrawPassword}
          placeholder={t("bd.input.password.placeholder")}
          onChange={(e) => {
            setWithdrawPassword(e.target.value);
          }}
        />
      </Box>

      <Box sx={{ mt: 5 }} display="flex" flexDirection="row" flexWrap="nowrap">
        <Button
          variant="outlined"
          sx={{ flexGrow: 1 }}
          onClick={() => {
            navigate("/app/withdraw-history");
          }}
        >
          <SvgIcon component={History} inheritViewBox />
        </Button>
        <Button variant="contained" disabled={isDisabled} sx={{ ml: 1, flexGrow: 2 }} onClick={handleWithdraw}>
          {t("button.confirm")}
        </Button>
      </Box>
      <UpdatePasswordDailog open={openUpdatePasswordDailog} onClose={() => setOpenUpdatePasswordDailog(false)} />
    </Box>
  );
};

export default function TradeWithdraw() {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    console.log(event, tabIndex);
  };

  const { data, isSuccess } = useQuery({
    queryKey: ["withdrawMethods"],
    queryFn: withdrawMethods,
  });

  const items =
    data?.data.map((r) => {
      return {
        label: t(r.portfolio?.portfolioCode),
        component: <WithdrawFragment setting={r} />,
      };
    }) || [];
  useEffect(() => {
    if(!data?.data.length && isSuccess){
      enqueueSnackbar(t('bd.trade.type.withdraw.method.empty.message'), { variant:'warning', autoHideDuration: 2000 })
      naviate('/home/burningProfile', { replace: true })
    }
  }, [data?.data, isSuccess]);
  return (
    <Container className="page-trade-withdraw" sx={{ my: 1 }}>
      <Box>
        <SwitchTab onChange={handleChange} items={items} />
      </Box>
    </Container>
  );
}
