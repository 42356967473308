import { AccountPortfolioVO } from "@/api/types";
import { auth, getUserInfo, getAccountPortfolioInfo } from "@/api/userinfo";
import AccountCard from "@/components/account-card";
import {
  Box,
  ButtonBase,
  ButtonProps,
  Card,
  CardContent,
  CardProps,
  Container,
  Divider,
  Grid2 as Grid,
  Icon,
  Paper,
  Typography,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import "./burning-profile.less";
import { useNavigate } from "react-router-dom";
import SwapDailog from "../trade/dialog/swap-dialog";
import { useEffect, useState } from "react";
import { bigNumberFormat } from "@/utils/common";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import BigNumber from "bignumber.js";

const HarvestCard = ({ collapsed, harvestExt }: { collapsed: boolean; harvestExt?: AccountPortfolioVO[] }) => {
  const { t } = useTranslation();
  return (
    <Card
      className="list-item-card"
      sx={collapsed ? { bgcolor: "#0A3566", borderRadius: 1.5 } : { display: "none", bgcolor: "#0A3566", borderRadius: 1.5 }}
    >
      <CardContent sx={{ paddingBottom: "0.5rem !important", padding: 1.5 }}>
        {(harvestExt || []).filter(item => {
          if(item.portfolio?.portfolioCode === 'bd.portfolio.code.harvest' && BigNumber(item.balance).comparedTo(0) <= 0) {
             return false;
          }
          return true
        }).map((item, index) => (
          <Box key={index}>
            <Typography color="#595959" component="span">
              {t(item.portfolio?.portfolioCode)}
            </Typography>
            <Typography component="span">{bigNumberFormat(item.balance, item.portfolio?.portfolioDecimal)}</Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

const MyPortfolioCard = (props: CardProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const { data } = useQuery(
    {
      queryKey: ["accountPortfolio"],
      queryFn: getAccountPortfolioInfo,
    },
    queryClient
  );
  const { data: userInfo } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryClient
  );
  const portfolioData: Record<string, AccountPortfolioVO> = {};
  if (data?.data) {
    data.data.reduce((acc: Record<string, AccountPortfolioVO>, current: AccountPortfolioVO) => {
      if (current.portfolio?.portfolioCode) {
        acc[current.portfolio?.portfolioCode] = current;
      }
      return acc;
    }, portfolioData);
  }

  const renderWalletsCodes = userInfo?.data.setting.showBucket
    ? [
        "bd.portfolio.code.usdt",
        "bd.portfolio.code.bur",
        "bd.portfolio.code.planting",
        "bd.portfolio.code.training",
        "bd.portfolio.code.harvest",
        "bd.portfolio.code.bonus",
      ]
    : ["bd.portfolio.code.usdt", "bd.portfolio.code.bur", "bd.portfolio.code.planting", "bd.portfolio.code.training", "bd.portfolio.code.harvest"];
  const harvestExt = data?.data.filter(
    (item) => item.portfolio?.metaInfo && item.portfolio.metaInfo.includes("harvest") && item.portfolio.metaInfo.includes("invest")
  );
  const harvestEtBalanceTotal = harvestExt?.reduce((acc, cur) => BigNumber(cur.balance).plus(BigNumber(acc)).toNumber(), 0);
  const [showMoreHarvest, setShowMoreHarvest] = useState(true);
  useEffect(() => {
    if (!harvestExt?.length) {
      setShowMoreHarvest(false);
    } else if (harvestExt?.length) {
      const harvestExtList = harvestExt.filter((item) => {
        if (item.portfolio?.portfolioCode === "bd.portfolio.code.harvest" && BigNumber(item.balance || "0.00").comparedTo(0) <= 0) {
          return false;
        }
        return true;
      });
      if (harvestExtList.length === 0) {
        setShowMoreHarvest(false);
      } else {
        setShowMoreHarvest(true);
      }
    } else {
      setShowMoreHarvest(true);
    }
  }, [harvestExt]);
  const portfolioMap: Record<string, string> = {};

  renderWalletsCodes.forEach((code) => {
    const accountPortfolio: AccountPortfolioVO = portfolioData[code];
    const decimal = accountPortfolio?.portfolio?.portfolioDecimal ?? 2;
    if (accountPortfolio == null) {
      if (code === "bd.portfolio.code.harvest") {
        portfolioMap[code] = bigNumberFormat(harvestEtBalanceTotal, decimal);
      } else {
        portfolioMap[code] = bigNumberFormat("0.00", decimal);
      }
    } else {
      if (code === "bd.portfolio.code.harvest") {
        portfolioMap[code] = bigNumberFormat(harvestEtBalanceTotal, decimal);
      } else {
        portfolioMap[code] = bigNumberFormat(accountPortfolio.balance, decimal);
      }
    }
  });

  const walletItemList = renderWalletsCodes.map((code, index) => {
    const dividerWrap = (
      <Grid className="divider" size={2} key={index}>
        <Box sx={{ height: "100%" }} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <Divider orientation="vertical" flexItem />
        </Box>
      </Grid>
    );
    return (
      <Fragment key={index}>
        {index % 2 == 1 ? dividerWrap : ""}
        <Grid className="portfolio-item" size={5}>
          <Typography sx={{ color: "#595959" }}>{code === "bd.portfolio.code.harvest" ? t("total.generated.value") : t(code)}</Typography>
          {code === "bd.portfolio.code.harvest" && showMoreHarvest ? (
            <Typography sx={{ mt: 0.5 }} onClick={() => setCollapsed(!collapsed)}>
              <Typography component="span" sx={{ verticalAlign: "top" }}>
                {portfolioMap[code]}
              </Typography>
              {!collapsed ? <ArrowDropDownSharp sx={{ color: "text.secondary" }} /> : <ArrowDropUpSharp sx={{ color: "text.secondary" }} />}
            </Typography>
          ) : (
            <Typography sx={{ mt: 0.5 }}>{portfolioMap[code]}</Typography>
          )}
        </Grid>
      </Fragment>
    );
  });

  return (
    <Card className="card-my-portfolio" {...props}>
      <CardContent>
        <Typography variant="h6">{t("page.burningprofile.myportfolio")}</Typography>
        <Grid className="portfolio-item-list" container sx={{ mt: 1 }} spacing={2}>
          {walletItemList}
        </Grid>
        <HarvestCard collapsed={collapsed} harvestExt={harvestExt} />
      </CardContent>
    </Card>
  );
};

const AppTradeBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSwapDialog, setOpenSwapDailog] = useState(false);

  const AppTradeIcon = ({ iconClass, text, sx, ...props }: ButtonProps & { iconClass: string; text: string }) => {
    let borderRadius = "0.875rem";
    if (sx && "borderRadius" in sx) {
      borderRadius = sx.borderRadius as string;
    }

    return (
      <ButtonBase sx={sx} {...props}>
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: borderRadius,
          }}
        >
          <Icon className={iconClass} />
          <Typography color="text.secondary">{text}</Typography>
        </Paper>
      </ButtonBase>
    );
  };

  return (
    <Box>
      <AppTradeIcon
        iconClass="icon-action-charge"
        text={t("bd.trade.type.charge")}
        onClick={() => navigate("/app/charge")}
        sx={{
          mr: 1,
          height: "4.5rem",
          width: "4.5rem",
          borderRadius: "0.875rem",
        }}
      />

      <AppTradeIcon
        iconClass="icon-action-withdraw"
        text={t("bd.trade.type.withdraw")}
        onClick={async () => {
          try {
            const { data } = await auth({
              type: "passwordCheck",
            });
            console.log("auth response ", data);
            navigate("/app/withdraw");
          } catch (err) {
            console.warn("check password fail", err);
          }
        }}
        sx={{
          mr: 1,
          height: "4.5rem",
          width: "4.5rem",
          borderRadius: "0.875rem",
        }}
      />

      <AppTradeIcon
        iconClass="icon-action-swap"
        text={t("bd.trade.type.swap")}
        onClick={async () => {
          try {
            const { data } = await auth({
              type: "passwordCheck",
            });
            setOpenSwapDailog(true);
            console.log("auth response ", data);
          } catch (e) {
            console.warn("check password fail", e);
          }
        }}
        sx={{
          mr: 1,
          height: "4.875rem",
          width: "4.875rem",
          borderRadius: "0.875rem",
        }}
      />

      <SwapDailog open={openSwapDialog} onClose={() => setOpenSwapDailog(false)} />
    </Box>
  );
};

export default function BurningProfile() {
  return (
    <Container className="page-home tab-burning-profile" sx={{ my: 1 }}>
      <AccountCard variant="mine" sx={{ borderRadius: 3.5 }} />
      <MyPortfolioCard sx={{ borderRadius: 3.5, my: 1 }} />
      <AppTradeBar />
    </Container>
  );
}
