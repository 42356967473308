import { packageList } from "@/api/global";
import { createActiveCdk, createBuyCdk, createBuyPackage, fetchInvestmentConfig } from "@/api/package";
import { PackageVO } from "@/api/types";
import { getAccountPortfolioInfo, getUserInfo } from "@/api/userinfo";
import { ReactComponent as History } from "@/assets/images/history.svg";
import SwitchTab from "@/components/switch-tab";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardProps,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid2,
  Icon,
  IconButton,
  InputAdornment,
  List,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { enqueueSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CdkSuccessDialog from "./cdk-success-dialog";
import EnterPasswordDialog from "./enter-password-dialog";
import { bigNumberFormat } from "@/utils/common";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import { BdAccountPackageInvestSettingItem } from "@/api/types/package";

interface BuyDailogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  onOk?: () => void;
}

interface ItemProps extends PackageVO, Omit<CardProps, "id"> {
  name: string;
  value: string;
  disabled: boolean;
  rate: string;
}

const fmt = {
  decimalSeparator: ".",
  groupSeparator: ",",
  groupSize: 3,
  secondaryGroupSize: 2,
};

const BuyFragment: React.FC<{
  items: PackageVO[];
  activePackageId: string | number;
  packagePrice?: number;
  setActivePackageId: React.Dispatch<React.SetStateAction<string | number>>;
  investmentConfigData?: BdAccountPackageInvestSettingItem[];
  setInvestmentConfigData?: React.Dispatch<React.SetStateAction<BdAccountPackageInvestSettingItem[]>>;
}> = ({ items, activePackageId, packagePrice, setActivePackageId, investmentConfigData, setInvestmentConfigData }) => {
  const { t } = useTranslation();
  const [totalTraining, setTotalTraining] = useState("0.00");
  const [collapsed, setCollapsed] = useState(false);
  const [showMoreHarvest, setShowMoreHarvest] = useState(true);
  const totalPlanting = investmentConfigData?.find((item) => item.portfolio === "bd.portfolio.code.planting");
  const harvestData = investmentConfigData?.filter((item) => item.portfolio !== "bd.portfolio.code.planting");
  const harvestDataTotal = harvestData
    ?.filter((item) => item.amount)
    ?.map((item) => Number(item.amount))
    .reduce((prev, next) => prev + next, 0);
  useEffect(() => {
    if (activePackageId) {
      const activePackage = items.find((item) => item.id === activePackageId);
      setTotalTraining(
        BigNumber(activePackage!.price || "0.00")
          .multipliedBy(BigNumber(activePackage!.lever || "0.00"))
          .div(100)
          .toFormat(2, BigNumber.ROUND_DOWN)
      );
    }
  }, [activePackageId]);
  useEffect(() => {
    if (harvestData?.length) {
      const defaultHarvestList = harvestData?.filter((item) => {
        if (item.portfolio === "bd.portfolio.code.harvest" && BigNumber(item.accountPortfolio?.balance || "0.00").comparedTo(0) > 0) {
          return true;
        }
        return false;
      });
      if (harvestData.length === 1 && !defaultHarvestList.length) {
        setShowMoreHarvest(false);
      } else {
        setShowMoreHarvest(true);
      }
    }
  }, [harvestData]);
  const renderItem = ({ id, name, disabled, value, rate, ...rest }: ItemProps) => {
    return (
      <Card
        className="list-item-card"
        sx={{
          my: 1,
          borderRadius: "0.4rem !important",
          borderColor: activePackageId !== id ?  "transparent !important":  "#27EDC6 !important",
          backgroundColor: activePackageId !== id ? "#042245 !important" : "#091625 !important",
        }}
        onClick={() => {
          if (!disabled) {
            setTotalTraining(
              BigNumber(rest.price || "0.0000")
                .multipliedBy(BigNumber(rate || "0.0000"))
                .toFormat(2, BigNumber.ROUND_DOWN)
            );
            setActivePackageId(id);
          }
        }}
      >
        <CardContent sx={{ px: 1, py: "10px !important" }}>
          <Typography variant="body2" color={disabled ? "#595959" : "text.secondary"}>
            {t(name)} {value} {t("page.digitalHuman.gatherValue")} {"×" + rate}
          </Typography>
        </CardContent>
      </Card>
    );
  };
  return (
    <>
      <Typography variant="body2" color="text.secondary" sx={{ fontSize: ".50rem", mt: 1 }}>
        {t("bd.rwa.package.buy.desc")}
      </Typography>
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {t("bd.rwa.package.buy.product")}
          </Typography>
        </Box>
        <List component={"div"} sx={{ p: 0 }}>
          {items.map((item) => (
            <Fragment key={item.id}>
              {renderItem({
                ...item,
                name: item.objectCode,
                disabled: !!item.status,
                value: BigNumber(item.price).toFormat(2, BigNumber.ROUND_DOWN, fmt),
                rate: BigNumber(item.lever).div(100).toFixed(2, BigNumber.ROUND_DOWN),
              })}
            </Fragment>
          ))}
        </List>
      </Box>
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {t("bd.rwa.package.buy.payment")}
          </Typography>
        </Box>
        {!totalPlanting ? (
          showMoreHarvest ? (
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography sx={{ fontSize: 10, my: 1, ml: 2 }}>{t("total.generated.value")}</Typography>
              </Box>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    pr: 0,
                  },
                }}
                value={harvestDataTotal ? harvestDataTotal : !collapsed ? t("click.to.close") : t("click.to.view")}
                slotProps={{
                  input: {
                    autoComplete: "off",
                    disabled: true,
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          position: "relative",
                        }}
                      >
                        {!collapsed ? (
                          <ArrowDropDownSharp sx={{ color: "text.secondary" }} onClick={() => setCollapsed(!collapsed)} />
                        ) : (
                          <ArrowDropUpSharp onClick={() => setCollapsed(!collapsed)} sx={{ color: "text.secondary" }} />
                        )}
                      </InputAdornment>
                    ),
                  },
                }}
                variant="outlined"
                fullWidth
              />
            </Box>
          ) : null
        ) : showMoreHarvest ? (
          <Grid2 container spacing={1}>
            <Grid2 size="grow">
              <Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography sx={{ fontSize: 10, my: 1, ml: 1 }}>
                    {t(totalPlanting.portfolio)}：
                    {bigNumberFormat(totalPlanting.accountPortfolio?.balance, totalPlanting.accountPortfolio?.portfolio?.portfolioDecimal)}
                  </Typography>
                </Box>
                <TextField
                  value={totalPlanting.amount}
                  type="number"
                  slotProps={{
                    input: {
                      autoComplete: "off",
                    },
                  }}
                  onFocus={(e) => {
                    setTimeout(() => {
                      e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                    }, 500);
                  }}
                  onChange={(e) => {
                    const value = e.target.value;

                    const newInvestmentConfigData = investmentConfigData?.map((item) => {
                      if (item.portfolio === totalPlanting.portfolio) {
                        item.amount = value;
                      }
                      return item;
                    });
                    setInvestmentConfigData?.(newInvestmentConfigData!);
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder={t("bd.support.contact.coustomer.content.placeholder")}
                />
              </Box>
            </Grid2>
            <Grid2
              size="auto"
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body1" color="text.secondary" sx={{ fontSize: 30 }}>
                ·
              </Typography>
            </Grid2>
            <Grid2 size="grow">
              <Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography sx={{ fontSize: 10, my: 1, ml: 2 }}>{t("total.generated.value")}</Typography>
                </Box>
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      pr: 0,
                    },
                  }}
                  value={harvestDataTotal ? harvestDataTotal : collapsed ? t("click.to.close") : t("click.to.view")}
                  slotProps={{
                    input: {
                      autoComplete: "off",
                      disabled: true,
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            position: "relative",
                          }}
                          onClick={() => setCollapsed(!collapsed)}
                        >
                          {!collapsed ? (
                            <ArrowDropDownSharp sx={{ color: "text.secondary" }} />
                          ) : (
                            <ArrowDropUpSharp sx={{ color: "text.secondary" }} />
                          )}
                        </InputAdornment>
                      ),
                    },
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Grid2>
          </Grid2>
        ) : (
          <Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography sx={{ fontSize: 10, my: 1, ml: 1 }}>
                {t(totalPlanting.portfolio)}：
                {bigNumberFormat(totalPlanting.accountPortfolio?.balance, totalPlanting.accountPortfolio?.portfolio?.portfolioDecimal)}
              </Typography>
            </Box>
            <TextField
              value={totalPlanting.amount}
              type="number"
              slotProps={{
                input: {
                  autoComplete: "off",
                },
              }}
              onFocus={(e) => {
                setTimeout(() => {
                  e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 500);
              }}
              onChange={(e) => {
                const value = e.target.value;

                const newInvestmentConfigData = investmentConfigData?.map((item) => {
                  if (item.portfolio === totalPlanting.portfolio) {
                    item.amount = value;
                  }
                  return item;
                });
                setInvestmentConfigData?.(newInvestmentConfigData!);
              }}
              variant="outlined"
              fullWidth
              placeholder={t("bd.support.contact.coustomer.content.placeholder")}
            />
          </Box>
        )}
      </Box>
      <Box sx={collapsed ? {} : { display: "none" }}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2, mb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {t("generating.value.portfolio")}
          </Typography>
        </Box>
        <Container disableGutters>
          {harvestData
            ?.filter((item) => {
              if (item.portfolio === "bd.portfolio.code.harvest" && BigNumber(item.accountPortfolio?.balance || "0.00").comparedTo(0) <= 0) {
                return false;
              }
              return true;
            })
            ?.map((item, index) => (
              <Box key={index} sx={{ mb: 1 }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ mb: 0.5 }}>
                  <Typography component="span" sx={{ fontSize: 10 }}>
                    {t(item.portfolio)}
                    <Typography component="span" sx={{ fontSize: 10, pl: 1 }} color="text.disabled">
                      {t("input.range", {
                        min: bigNumberFormat(
                          BigNumber(packagePrice || "0.00")
                            .multipliedBy(BigNumber(item.min).dividedBy(100))
                            .toString()
                        ),
                        max: bigNumberFormat(
                          BigNumber(packagePrice || "0.00")
                            .multipliedBy(BigNumber(item.max).dividedBy(100))
                            .toString()
                        ),
                      })}
                    </Typography>
                  </Typography>
                  <Typography sx={{ fontSize: 10 }}>
                    {t("balance")}：{bigNumberFormat(item.accountPortfolio?.balance, item.accountPortfolio?.portfolio?.portfolioDecimal)}
                  </Typography>
                </Box>
                <TextField
                  type="number"
                  value={item.amount}
                  slotProps={{
                    input: {
                      autoComplete: "off",
                    },
                  }}
                  disabled={!BigNumber(item.accountPortfolio?.balance || "0.00").toNumber()}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newHarvestList = [...(harvestData || [])];
                    newHarvestList[index].amount = value;
                    const newInvestmentConfigData = investmentConfigData?.map((item) => {
                      newHarvestList.forEach((harvestItem) => {
                        if (item.portfolio === harvestItem.portfolio) {
                          item.amount = harvestItem.amount;
                        }
                      });
                      return item;
                    });
                    setInvestmentConfigData?.(newInvestmentConfigData!);
                  }}
                  onFocus={(e) => {
                    setTimeout(() => {
                      e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                    }, 500);
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder={t("bd.support.contact.coustomer.content.placeholder")}
                />
              </Box>
            ))}
        </Container>
      </Box>
      <Box>
        <Typography component="span" sx={{ fontSize: 12, display: "flex", justifyContent: "flex-end", marginTop: 1, marginRight: 1 }}>
          <Icon className="icon-usd-account" sx={{ width: 15, height: 15, marginTop: "0.1rem", marginRight: 1 }} />
          {t("bd.rwa.package.buy.training", { training: totalTraining })}
        </Typography>
      </Box>
    </>
  );
};

export default function BuyDialog({ open, onClose, onOk, ...restProps }: BuyDailogProps) {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const queryClient = useQueryClient();
  const [items, setItems] = useState<PackageVO[]>([]);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [cdkSuccessDialogOpen, setCdkSuccessDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [activePackageId, setActivePackageId] = useState<string | number>(0);
  const [cdKey, setCdKey] = useState("");
  const [activeCdk, setActiveCdk] = useState("");
  const [investmentConfigData, setInvestmentConfigData] = useState<BdAccountPackageInvestSettingItem[]>([]);
  const { data: userInfo } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryClient
  );

  const { data: accountPortfolioInfo } = useQuery(
    {
      queryKey: ["accountPortfolio"],
      queryFn: getAccountPortfolioInfo,
    },
    queryClient
  );
  const { data: investmentConfig } = useQuery(
    {
      queryKey: ["investmentConfig"],
      queryFn: fetchInvestmentConfig,
    },
    queryClient
  );
  useEffect(() => {
    const investmentConfigList = investmentConfigData.map((item) => ({ ...item, amount: "" }));
    setInvestmentConfigData(investmentConfigList);
  }, [activePackageId]);
  useEffect(() => {
    if (open) {
      packageList().then((response) => {
        const { data } = response;
        data.forEach((item) => {
          if (userInfo?.data.packageInfo && !activeTab) {
            if (BigNumber(item.lever || "0.0000").comparedTo(BigNumber(userInfo?.data.packageInfo?.lever || "0.000")) <= 0) {
              item.status = 1;
            }
          }
          return item;
        });
        setItems(data);
      });
    }
  }, [open, activeTab]);
  useEffect(() => {
    setActivePackageId(items.find((item) => !item.status)?.id || 0);
  }, [activeTab, items]);
  //处理关闭弹框
  const handleClose = () => {
    onClose();
    setActiveTab(0);
  };

  useEffect(() => {
    if (investmentConfig?.data) {
      const investmentConfigList = investmentConfig?.data.map((item) => {
        accountPortfolioInfo?.data.forEach((accountPortfolio) => {
          if (accountPortfolio.portfolio?.portfolioCode === item.portfolio) {
            item.accountPortfolio = accountPortfolio;
          }
        });
        if (!item.amount) {
          item.amount = "";
        }
        return item;
      });
      setInvestmentConfigData(investmentConfigList);
    }
  }, [investmentConfig?.data, accountPortfolioInfo?.data]);

  const handleConfirm = async () => {
    if (!activeTab) {
      await createBuyPackage({
        password,
        packageId: activePackageId.toString(),
        payInfo: investmentConfigData
          .map((item) => ({
            portfolio: item.portfolio,
            amount: item.amount,
          }))
          .filter((item) => item.amount),
      });
      enqueueSnackbar(t("bd.rwa.package.buy.package.message.success"), { variant: "success", autoHideDuration: 2000 });
      setTimeout(() => {
        onOk?.();
      }, 2000);
    } else {
      const { data } = await createBuyCdk({
        password,
        packageId: activePackageId.toString(),
        payInfo: investmentConfigData
          .map((item) => ({
            portfolio: item.portfolio,
            amount: item.amount,
          }))
          .filter((item) => item.amount),
      });
      data.cdKey && setCdKey(data.cdKey);
      enqueueSnackbar(t("bd.rwa.package.buy.cdk.success.dialog.title"), { variant: "success", autoHideDuration: 2000 });
      setCdkSuccessDialogOpen(true);
    }
    onClose();
  };

  const packagePrice = items.find((item) => item.id === activePackageId)?.price;
  const investmentList = investmentConfigData.map((item) => {
    const min = BigNumber(packagePrice || "0.00").multipliedBy(BigNumber(item.min).dividedBy(100));
    const max = BigNumber(packagePrice || "0.00").multipliedBy(BigNumber(item.max).dividedBy(100));
    if (item.amount && BigNumber(item.amount).comparedTo(min) >= 0 && BigNumber(item.amount).comparedTo(max) <= 0) {
      return Number(item.amount);
    }
    return 0;
  });

  const isDisable = () => {
    if (BigNumber(investmentList.reduce((prev, next) => prev + next, 0)).comparedTo(BigNumber(packagePrice || "0.00")) === 0) {
      return false;
    }
    return true;
  };
  return (
    <>
      <EnterPasswordDialog
        onConfirm={handleConfirm}
        password={password}
        setPassword={setPassword}
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
      />
      <CdkSuccessDialog
        open={cdkSuccessDialogOpen}
        cdKey={cdKey}
        onClose={() => {
          setCdkSuccessDialogOpen(false);
          onOk?.();
        }}
      />

      <Dialog
        className="buy-dialog"
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          component: "form",
          sx: {
            margin: 0,
            width: "calc(100% - 32px)",
          },
        }}
        {...restProps}
      >
        <DialogTitle>{t("bd.digitalHuman.buy.dialog.title")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setActiveTab(0);
            onClose();
          }}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ px: "1rem", py: "0" }}>
          <Box>
            <SwitchTab
              defaultTabIndex={activeTab}
              onChange={() => {
                setActivePackageId(0);
                setPassword("");
                setActiveTab((prev) => (prev === 0 ? 1 : 0));
              }}
              items={[
                {
                  label: t("page.title.package"),
                  component: (
                    <BuyFragment
                      items={items}
                      packagePrice={packagePrice}
                      setInvestmentConfigData={setInvestmentConfigData}
                      investmentConfigData={investmentConfigData}
                      activePackageId={activePackageId}
                      setActivePackageId={setActivePackageId}
                    />
                  ),
                },
                {
                  label: t("bd.trade.type.cdk"),
                  component: (
                    <BuyFragment
                      items={items}
                      packagePrice={packagePrice}
                      investmentConfigData={investmentConfigData}
                      setInvestmentConfigData={setInvestmentConfigData}
                      activePackageId={activePackageId}
                      setActivePackageId={setActivePackageId}
                    />
                  ),
                },
              ]}
            />
          </Box>
          {activeTab && !userInfo?.data.packageInfo ? (
            <>
              <DialogActions
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pt: "0.625rem",
                  pb: (activeTab && userInfo?.data.packageInfo) || !activeTab ? "1.25rem" : "8px",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  onClick={() => (activeTab ? naviate("/app/cdk-history") : naviate("/app/package-history"))}
                >
                  <SvgIcon component={History} inheritViewBox />
                </Button>
                <Button
                  variant="contained"
                  sx={{ ml: 1, flexGrow: 2 }}
                  disabled={isDisable()}
                  onClick={() => {
                    setPasswordDialogOpen(true);
                  }}
                >
                  {t("button.confirm")}
                </Button>
              </DialogActions>
              <Box sx={{ py: "0" }}>
                <Box>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    <Typography variant="body1" color="text.secondary">
                      {t("bd.rwa.package.buy.cdk.active.title")}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("bd.rwa.package.buy.cdk.active.desc")}
                    </Typography>
                  </Box>
                  <TextField
                    slotProps={{
                      input: {
                        autoComplete: "off",
                      },
                    }}
                    onFocus={(e) => {
                      setTimeout(() => {
                        e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                      }, 500);
                    }}
                    onBlur={(e) => {
                      setTimeout(() => {
                        e.target?.scrollIntoView({ behavior: "smooth", block: "end" });
                      }, 500);
                    }}
                    value={activeCdk}
                    onChange={(e) => setActiveCdk(e.target.value)}
                    variant="outlined"
                    fullWidth
                    placeholder={t("bd.rwa.package.buy.cdk.active.input.placeholder")}
                  />
                </Box>
              </Box>
            </>
          ) : null}
        </DialogContent>
        {activeTab && !userInfo?.data.packageInfo ? (
          <>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pt: "1.5rem",
                pb: "1.25rem",
                mx: "0.5rem",
              }}
            >
              <Button
                variant="contained"
                sx={{ flexGrow: 2 }}
                disabled={!activeCdk}
                onClick={async () => {
                  if (activeCdk) {
                    await createActiveCdk({ cdKey: activeCdk });
                    enqueueSnackbar(t("bd.rwa.package.buy.cdk.active.message.success"), {
                      variant: "success",
                      autoHideDuration: 2000,
                    });
                    onOk?.();
                    onClose();
                    setActiveTab(0);
                    setActiveCdk("");
                  }
                }}
              >
                {t("bd.rwa.package.buy.cdk.active.button")}
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              pt: "0.625rem",
              pb: (activeTab && userInfo?.data.packageInfo) || !activeTab ? "1.25rem" : "8px",
            }}
          >
            <Button
              variant="outlined"
              sx={{ flexGrow: 1 }}
              onClick={() => {
                activeTab ? naviate("/app/cdk-history") : naviate("/app/package-history");
              }}
            >
              <SvgIcon component={History} inheritViewBox />
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 1, flexGrow: 2 }}
              disabled={isDisable()}
              onClick={() => {
                setPasswordDialogOpen(true);
              }}
            >
              {t("button.confirm")}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
