import { generateNonce } from "@/api/login";
import { login, logout, useToken } from "@/stores/modules/user";
import { Box, Button, Card, CardContent, CardProps, Checkbox, CheckboxProps, Icon, Typography } from "@mui/material";
import { to } from "await-to-js";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { getUserInfo } from "@/api/userinfo";
import { checkIfDefaultChain, useSwitchDefaultNetwork } from "@/web3";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useAccount, useConnect, useSignMessage } from "wagmi";
import { injected } from "wagmi/connectors";
import AddressLabel from "../address-label";

import "./index.less";

const NeedLoginContent = ({ variant }: { variant?: "home" | "menu" | "mine"; size?: "small" | "medium" }) => {
  const { t } = useTranslation();
  const { connect } = useConnect();
  const { address, isConnected, chainId } = useAccount();
  const [loginButtonClicked, setLoginButtonClicked] = useState(false);
  const { switchDefaultChain } = useSwitchDefaultNetwork();
  const { signMessage, data: signedData, error: signError } = useSignMessage()
  const isDefaultChain = checkIfDefaultChain(chainId);

  useEffect(() => {
    console.log("toLogin requestSignature error", signError);
    if (signError) {
      enqueueSnackbar(signError.message, { variant: "error", autoHideDuration: 2000 });
    }
  }, [signError]);

  useEffect(() => {
    console.log("KKKKK toLogin [3] sig: ", signedData);
    if (signedData != null) {
      login(address as string, signedData as string);
    }
  }, [signedData]);

  const loginProcess = async (walletAddress: string) => {
    const [err1, nonceResponse] = await to(
      generateNonce({
        address: walletAddress,
      })
    );

    if (err1) {
      enqueueSnackbar(err1.message, { variant: "error", autoHideDuration: 2000 });
      console.log("toLogin generateNonce error", err1);
      return;
    }

    console.log("KKKKK toLogin [2] nonce: ", nonceResponse.data.nonce);

    signMessage({
      account: walletAddress as `0x{string}`,
      message: nonceResponse.data.nonce
    });
  };

  useEffect(() => {
    console.log("detect address changed", address);
    console.log("detect address isConnected", isConnected);
    console.log("detect address loginButtonClicked", loginButtonClicked);
    console.log("detect address isDefaultChain", isDefaultChain);

    //走登录流程
    if (isConnected && loginButtonClicked && isDefaultChain) {
      loginProcess(address as string).then((ret) => {
        console.log("loginProcess success", address, ret);
      });
    }

    //将按钮点击状态复位
    setLoginButtonClicked(false);
  }, [isConnected, loginButtonClicked, isDefaultChain]);
  console.log(address, '=====')
  async function toLogin() {
    //检测钱包是否安装
    if (typeof window.ethereum == "undefined") {
      enqueueSnackbar(t("global.wallet.is.not.installed"), { variant: "warning", autoHideDuration: 2000 });
      return;
    }

    console.log("try to switch default chain ...");
    switchDefaultChain();

    if (!address) {
      //先切换到对应的链
      console.log("try to connect wallet ...");
      connect({ connector: injected() });
    }
    setLoginButtonClicked(true);
  }

  return (
    <CardContent
      className="wallet-card wallet-unbind-card"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        aspectRatio: "678 / 200",
      }}
    >
      {variant == "menu" ? (
        ""
      ) : (
        <Typography variant="h6" textAlign="center" color="text.secondary">
          {t("page.dashboard.unlogin.title")}
        </Typography>
      )}

      <Button
        variant="contained"
        sx={{
          mt: 1.5,
          height: "1.75rem",
          minWidth: "10rem",
          borderRadius: "0.875rem",
          fontSize: "0.75rem",
          fontWeight: 500,
        }}
        onClick={toLogin}
      >
        {t("page.dashboard.unlogin.button")}
      </Button>
    </CardContent>
  );
};

const ViewSecretInfoCheckBox = (props: CheckboxProps) => {
  return (
    <Checkbox
      {...props}
      icon={<Icon className="icon-eye-brief" sx={{ width: "17px", height: "16px" }} />}
      checkedIcon={<Icon className="icon-eye-detail" component="div" sx={{ width: "17px", height: "16px" }} />}
      inputProps={{ "aria-label": "view wallet address breif or detail" }}
      sx={{ width: "25px", height: "25px", mr: "var(--mui-spacing)", padding: 0 }}
    />
  );
};

const AcccountInfoCard = ({ variant, size }: { variant?: "home" | "menu" | "mine"; size?: "small" | "medium" }) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const queryCleint = useQueryClient();
  const [viewMode, setViewMode] = useState<"brief" | "detail">("brief");

  const { data, error } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryCleint
  );

  if (data?.data.address != null && address != null && data?.data.address.toLocaleLowerCase() != address.toLocaleLowerCase()) {
    //如果地址不一致，清除缓存
    setTimeout(async () => {
      await logout()
      window.location.href = '/'
    }, 500);
  }

  const cardContetClass = `wallet-card wallet-bind-card variant-${variant}`;

  return (
    <CardContent
      className={cardContetClass}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        "&:last-child": {
          paddingBottom: "1rem",
        },
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography className="label" component="span" variant={size == "small" ? "caption" : "body1"}>
          UID
        </Typography>
        <Typography className="value value-uid" variant={size == "small" ? "caption" : "body1"} component="span">
          {data?.data.uid}
        </Typography>
      </Box>
      <Box>
        <Typography className="label" component="span" variant={size == "small" ? "caption" : "body1"} sx={{ float: "left" }}>
          {t("wallet.address")}
        </Typography>

        {variant == "menu" ? (
          ""
        ) : (
          <ViewSecretInfoCheckBox
            sx={{ float: "left" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                setViewMode("detail");
              } else {
                setViewMode("brief");
              }
            }}
          />
        )}

        <AddressLabel
          className="value value-address"
          size={size}
          address={data?.data.address}
          viewMode={viewMode}
          component="span"
          color="text.secondary" 
          sx={{
            float: "left",
            clear: variant == "menu" ? "none" : "left",
          }}
        />
      </Box>
    </CardContent>
  );
};

interface AccountCardProps extends Omit<CardProps, "variant"> {
  variant?: "home" | "menu" | "mine";
  size?: "small" | "medium";
}

export default function AccountCard({ variant, size, ...restProps }: AccountCardProps) {
  const token = useToken();
  return (
    <Card className="card-user-info" {...restProps}>
      {token == null ? <NeedLoginContent variant={variant} size={size} /> : <AcccountInfoCard size={size} variant={variant} />}
    </Card>
  );
}
