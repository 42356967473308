import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FilterDialogValue } from "@/components/trade-filter-dialog";

interface PreviewPictureDialogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  src: string;
}

export default function PreviewPictureDialog({ open, src, onClose, ...restProps }: PreviewPictureDialogProps) {
  //处理关闭弹框
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={open} PaperProps={{
      sx:{
        margin: 0,
        width:'calc(100% - 32px)'
      }
    }} onClose={handleClose} maxWidth="lg"  {...restProps} fullWidth sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: '1.125rem', py: '0.625rem', mt: 1 }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={src} alt={src} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
