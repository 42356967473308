import request from "@/utils/request";
import { AxiosPromise } from "axios";
import type { NoticeRecordVO, QueryNoticeParam } from "./types/noticeRecord";



/**
 * 获取通知列表
 * @returns
 */
export function listNotice(params: QueryNoticeParam): AxiosPromise<NoticeRecordVO[]> {
  return request({
    url: "/app/global/notice/list",
    method: "get",
    params
  });
}

/**
 * 获取通知详情
 */
export function getNoticeDetail(id: number): AxiosPromise<NoticeRecordVO> {
  return request({
    url: `/app/global/notice/${id}`,
    method: "get",
  });
}