import { createBuyRechargeReinvest, fetchInvestmentConfig } from "@/api/package";
import { getAccountPortfolioInfo, getUserInfo } from "@/api/userinfo";
import { ReactComponent as History } from "@/assets/images/history.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid2,
  Icon,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EnterPasswordDialog from "./enter-password-dialog";
import { enqueueSnackbar } from "notistack";
import { bigNumberFormat } from "@/utils/common";
import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import { BdAccountPackageInvestSettingItem } from "@/api/types/package";
interface ChargeReinvestDailogProps extends Omit<DialogProps, "onClose"> {
  onClose: () => void;
  onOk?: () => void;
}
export default function ChargeReinvestDialog({ open, onClose, onOk, ...restProps }: ChargeReinvestDailogProps) {
  const { t } = useTranslation();
  const naviate = useNavigate();
  const queryClient = useQueryClient();
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [totalTraining, setTotalTraining] = useState("0.00");
  const [showMoreHarvest, setShowMoreHarvest] = useState(true);
  const [reInvestmentTotal, setReInvestmentTotal] = useState("");
  const [investmentConfigData, setInvestmentConfigData] = useState<BdAccountPackageInvestSettingItem[]>([]);
  const totalPlanting = investmentConfigData?.find((item) => item.portfolio === "bd.portfolio.code.planting");
  const harvestData = investmentConfigData?.filter((item) => item.portfolio !== "bd.portfolio.code.planting");
  const harvestDataTotal = harvestData
    ?.filter((item) => item.amount)
    ?.map((item) => Number(item.amount))
    .reduce((prev, next) => prev + next, 0);
  const { data: userInfo } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryClient
  );
  useEffect(() => {
    if (harvestData?.length) {
      const defaultHarvestList = harvestData?.filter((item) => {
        if (item.portfolio === "bd.portfolio.code.harvest" && BigNumber(item.accountPortfolio?.balance || "0.00").comparedTo(0) > 0) {
          return true;
        }
        return false;
      });
      if (harvestData.length === 1 && !defaultHarvestList.length) {
        setShowMoreHarvest(false);
      } else {
        setShowMoreHarvest(true);
      }
    }
  }, [harvestData]);
  useEffect(() => {
    if (open) {
      setCollapsed(false);
      setReInvestmentTotal("");
      setTotalTraining("0.00");
    }
  }, [open]);
  const { data: accountPortfolioInfo } = useQuery(
    {
      queryKey: ["accountPortfolio"],
      queryFn: getAccountPortfolioInfo,
    },
    queryClient
  );
  const { data: investmentConfig } = useQuery(
    {
      queryKey: ["investmentConfig"],
      queryFn: fetchInvestmentConfig,
    },
    queryClient
  );
  useEffect(() => {
    if (investmentConfig?.data) {
      const investmentConfigList = investmentConfig?.data.map((item) => {
        accountPortfolioInfo?.data.forEach((accountPortfolio) => {
          if (accountPortfolio.portfolio?.portfolioCode === item.portfolio) {
            item.accountPortfolio = accountPortfolio;
          }
        });
        if (!item.amount) {
          item.amount = "";
        }
        return item;
      });
      setInvestmentConfigData(investmentConfigList);
    }
  }, [investmentConfig?.data, accountPortfolioInfo?.data]);
  useEffect(() => {
    if (userInfo?.data && userInfo.data.packageInfo) {
      const total = investmentConfigData.reduce((acc, current) => {
        acc += Number(current.amount || 0);
        return acc;
      }, 0);
      setTotalTraining(
        BigNumber(total || "0.00")
          .multipliedBy(
            BigNumber(userInfo.data.packageInfo.lever || "0.00")
              .div(100)
              .toFixed(2, BigNumber.ROUND_DOWN)
          )
          .toFormat(2, BigNumber.ROUND_DOWN)
      );
    }
  }, [userInfo, investmentConfigData]);

  const handleClose = () => {
    onClose();
  };
  const handleConfirm = async () => {
    try {
      await createBuyRechargeReinvest({
        password,
        payInfo: investmentConfigData
          .map((item) => ({
            portfolio: item.portfolio,
            amount: item.amount,
          }))
          .filter((item) => item.amount),
      });
      const investmentConfigList = investmentConfigData.map((item) => ({ ...item, amount: "" }));
      setInvestmentConfigData(investmentConfigList);
      enqueueSnackbar(t("bd.rwa.package.buy.recharge.reinvest.message.success"), { variant: "success", autoHideDuration: 2000 });
      setTimeout(() => {
        onOk?.();
      }, 2000);
    } catch (e: any) {
      console.log(e);
    }
    onClose();
  };
  const investmentList = investmentConfigData.map((item) => {
    const min = BigNumber(reInvestmentTotal || "0.00").multipliedBy(BigNumber(item.min).dividedBy(100));
    const max = BigNumber(reInvestmentTotal || "0.00").multipliedBy(BigNumber(item.max).dividedBy(100));
    if (item.amount && BigNumber(item.amount).comparedTo(min) >= 0 && BigNumber(item.amount).comparedTo(max) <= 0) {
      return Number(item.amount);
    }
    return 0;
  });
  const isDisable = () => {
    if (BigNumber(investmentList.reduce((prev, next) => prev + next, 0)).comparedTo(BigNumber(reInvestmentTotal || "0.00")) === 0) {
      return false;
    }
    return true;
  };
  return (
    <>
      <EnterPasswordDialog
        onConfirm={handleConfirm}
        password={password}
        setPassword={setPassword}
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
      />
      <Dialog
        className="charge-reinvest-dialog"
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          component: "form",
          sx: {
            margin: 0,
            width: "calc(100% - 32px)",
          },
        }}
        {...restProps}
      >
        <DialogTitle>{t("bd.rwa.package.buy.recharge.reinvest")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ px: "1rem", py: "0" }}>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary" sx={{ my: 1, ml: 1 }}>
                {t("bd.rwa.package.buy.reinvest.total")}
              </Typography>
            </Box>
            <TextField
              type="number"
              slotProps={{
                input: {
                  autoComplete: "off",
                },
              }}
              onFocus={(e) => {
                setTimeout(() => {
                  e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 500);
              }}
              value={reInvestmentTotal}
              onChange={(e) => {
                const value = e.target.value;
                setReInvestmentTotal(value);
              }}
              variant="outlined"
              fullWidth
              placeholder={t("bd.support.contact.coustomer.content.placeholder")}
            />
          </Box>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {t("bd.rwa.package.buy.payment")}
              </Typography>
            </Box>
            {!totalPlanting ? (
              showMoreHarvest ? (
                <Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography sx={{ fontSize: 10, my: 1, ml: 2 }}>{t("total.generated.value")}</Typography>
                  </Box>
                  <TextField
                    sx={{
                      "& .MuiInputBase-root": {
                        pr: 0,
                      },
                    }}
                    value={harvestDataTotal ? harvestDataTotal : !collapsed ? t("click.to.close") : t("click.to.view")}
                    slotProps={{
                      input: {
                        autoComplete: "off",
                        disabled: true,
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              position: "relative",
                            }}
                          >
                            {!collapsed ? (
                              <ArrowDropDownSharp sx={{ color: "text.secondary" }} onClick={() => setCollapsed(!collapsed)} />
                            ) : (
                              <ArrowDropUpSharp onClick={() => setCollapsed(!collapsed)} sx={{ color: "text.secondary" }} />
                            )}
                          </InputAdornment>
                        ),
                      },
                    }}
                    variant="outlined"
                    fullWidth
                  />
                </Box>
              ) : null
            ) : showMoreHarvest ? (
              <Grid2 container spacing={1}>
                <Grid2 size="grow">
                  <Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography sx={{ fontSize: 10, my: 1, ml: 1 }}>
                        {t(totalPlanting.portfolio)}：
                        {bigNumberFormat(totalPlanting.accountPortfolio?.balance, totalPlanting.accountPortfolio?.portfolio?.portfolioDecimal)}
                      </Typography>
                    </Box>
                    <TextField
                      value={totalPlanting.amount}
                      type="number"
                      slotProps={{
                        input: {
                          autoComplete: "off",
                        },
                      }}
                      onFocus={(e) => {
                        setTimeout(() => {
                          e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                        }, 500);
                      }}
                      onChange={(e) => {
                        const value = e.target.value;

                        const newInvestmentConfigData = investmentConfigData?.map((item) => {
                          if (item.portfolio === totalPlanting.portfolio) {
                            item.amount = value;
                          }
                          return item;
                        });
                        setInvestmentConfigData?.(newInvestmentConfigData!);
                      }}
                      variant="outlined"
                      disabled={!reInvestmentTotal}
                      fullWidth
                      placeholder={t("bd.support.contact.coustomer.content.placeholder")}
                    />
                  </Box>
                </Grid2>
                <Grid2
                  size="auto"
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography variant="body1" color="text.secondary" sx={{ fontSize: 30 }}>
                    ·
                  </Typography>
                </Grid2>
                <Grid2 size="grow">
                  <Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography sx={{ fontSize: 10, my: 1, ml: 2 }}>{t("total.generated.value")}</Typography>
                    </Box>
                    <TextField
                      sx={{
                        "& .MuiInputBase-root": {
                          pr: 0,
                        },
                      }}
                      value={harvestDataTotal ? harvestDataTotal : collapsed ? t("click.to.close") : t("click.to.view")}
                      slotProps={{
                        input: {
                          autoComplete: "off",
                          disabled: true,
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{
                                position: "relative",
                              }}
                              onClick={() => setCollapsed(!collapsed)}
                            >
                              {!collapsed ? (
                                <ArrowDropDownSharp sx={{ color: "text.secondary" }} />
                              ) : (
                                <ArrowDropUpSharp sx={{ color: "text.secondary" }} />
                              )}
                            </InputAdornment>
                          ),
                        },
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Grid2>
              </Grid2>
            ) : (
              <Box>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography sx={{ fontSize: 10, my: 1, ml: 1 }}>
                    {t(totalPlanting.portfolio)}：
                    {bigNumberFormat(totalPlanting.accountPortfolio?.balance, totalPlanting.accountPortfolio?.portfolio?.portfolioDecimal)}
                  </Typography>
                </Box>
                <TextField
                  value={totalPlanting.amount}
                  type="number"
                  slotProps={{
                    input: {
                      autoComplete: "off",
                    },
                  }}
                  onFocus={(e) => {
                    setTimeout(() => {
                      e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                    }, 500);
                  }}
                  onChange={(e) => {
                    const value = e.target.value;

                    const newInvestmentConfigData = investmentConfigData?.map((item) => {
                      if (item.portfolio === totalPlanting.portfolio) {
                        item.amount = value;
                      }
                      return item;
                    });
                    setInvestmentConfigData?.(newInvestmentConfigData!);
                  }}
                  variant="outlined"
                  fullWidth
                  placeholder={t("bd.support.contact.coustomer.content.placeholder")}
                />
              </Box>
            )}
          </Box>
          <Box sx={collapsed ? {} : { display: "none" }}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2, mb: 1 }}>
              <Typography variant="body2" color="text.secondary">
                {t("generating.value.portfolio")}
              </Typography>
            </Box>
            <Container disableGutters>
              {harvestData
                ?.filter((item) => {
                  if (item.portfolio === "bd.portfolio.code.harvest" && BigNumber(item.accountPortfolio?.balance || "0.00").comparedTo(0) <= 0) {
                    return false;
                  }
                  return true;
                })
                ?.map((item, index) => (
                  <Box key={index} sx={{ mb: 1 }}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ mb: 0.5 }}>
                      <Typography component="span" sx={{ fontSize: 10 }}>
                        {t(item.portfolio)}
                        <Typography component="span" sx={{ fontSize: 10, pl: 1 }} color="text.disabled">
                          {t("input.range", {
                            min: !reInvestmentTotal
                              ? "???"
                              : bigNumberFormat(
                                  BigNumber(reInvestmentTotal || "0.00")
                                    .multipliedBy(BigNumber(item.min).dividedBy(100))
                                    .toString()
                                ),
                            max: !reInvestmentTotal
                              ? "???"
                              : bigNumberFormat(
                                  BigNumber(reInvestmentTotal || "0.00")
                                    .multipliedBy(BigNumber(item.max).dividedBy(100))
                                    .toString()
                                ),
                          })}
                        </Typography>
                      </Typography>
                      <Typography sx={{ fontSize: 10 }}>
                        {t("balance")}：{bigNumberFormat(item.accountPortfolio?.balance, item.accountPortfolio?.portfolio?.portfolioDecimal)}
                      </Typography>
                    </Box>
                    <TextField
                      type="number"
                      value={item.amount}
                      slotProps={{
                        input: {
                          autoComplete: "off",
                        },
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        const newHarvestList = [...(harvestData || [])];
                        newHarvestList[index].amount = value;
                        const newInvestmentConfigData = investmentConfigData?.map((item) => {
                          newHarvestList.forEach((harvestItem) => {
                            if (item.portfolio === harvestItem.portfolio) {
                              item.amount = harvestItem.amount;
                            }
                          });
                          return item;
                        });
                        setInvestmentConfigData?.(newInvestmentConfigData!);
                      }}
                      disabled={!reInvestmentTotal || (!!reInvestmentTotal && !BigNumber(item.accountPortfolio?.balance || "0.00").toNumber())}
                      onFocus={(e) => {
                        setTimeout(() => {
                          e.target?.scrollIntoView({ behavior: "smooth", block: "start" });
                        }, 500);
                      }}
                      variant="outlined"
                      fullWidth
                      placeholder={t("bd.support.contact.coustomer.content.placeholder")}
                    />
                  </Box>
                ))}
            </Container>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontSize: 12, display: "flex", justifyContent: "flex-end", marginTop: 1, marginRight: 1 }}>
              <Icon className="icon-usd-account" sx={{ width: 15, height: 15, marginTop: "0.1rem", marginRight: 1 }} />
              {t("bd.rwa.package.buy.training", {
                training: totalTraining,
              })}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            pt: "0.625rem",
            pb: "1.25rem",
            mx: "1rem",
          }}
        >
          <Button
            variant="outlined"
            sx={{ flexGrow: 1 }}
            onClick={() => {
              naviate("/app/package-history");
            }}
          >
            <SvgIcon component={History} inheritViewBox />
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1, flexGrow: 2 }}
            disabled={isDisable()}
            onClick={() => {
              // if (!BigNumber(totalPlanting?.balance || "0.00").toNumber()) {
              //   return enqueueSnackbar(t("bd.rwa.package.buy.package.disable.error.message"), { variant: "error", autoHideDuration: 2000 });
              // }
              setPasswordDialogOpen(true);
            }}
          >
            {t("button.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
