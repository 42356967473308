import request from "@/utils/request";
import { AxiosPromise } from "axios";
import {
  AccountPortfolioVO,
  AccountVO,
  QueryRelationLeaderParam,
  QueryTeamMemberParam,
  RelationHierarchyBriefVo,
  SysMemberVo,
  SysRelationChangeLogVo,
} from "./types";

/**
 * 简单验证权限
 * @returns
 */
export function auth(param?: any): AxiosPromise<{}> {
  return request({
    url: "/app/user/auth",
    method: "get",
    params: param,
  });
}

/**
 * 查询用户信息
 * @returns
 */
export function getUserInfo(): AxiosPromise<AccountVO> {
  return request({
    url: "/app/user/userInfo",
    method: "get",
  });
}

/**
 * 查询用户的资产列表
 * @returns 用户的资产列表信息
 */
export function getAccountPortfolioInfo(): AxiosPromise<AccountPortfolioVO[]> {
  return request({
    url: "/app/user/portfolioInfo",
    method: "get",
  });
}

/**
 * 绑定邀请人
 * @returns 用户的资产列表信息
 */
export function bindInviter(inviter: string): AxiosPromise<void> {
  return request({
    url: "/app/user/bindInviter",
    method: "post",
    params: {
      inviter,
    },
  });
}

/**
 * 更新交易密码
 * @param newPassword 新密码
 * @param oldPassword 旧密码，如果是新设置，可以不填
 * @returns
 */
export function updatePassword(newPassword: string, oldPassword?: string): AxiosPromise<void> {
  const data = {
    oldPassword,
    newPassword,
  };
  return request({
    url: "/app/user/updatePassword",
    method: "put",
    headers: {
      isToken: true,
      isEncrypt: true,
      repeatSubmit: false,
    },
    data: data,
  });
}

/**
 * 查询待放置会员列表
 */
export function getPlaceTeamMemberList(): AxiosPromise<SysMemberVo[]> {
  return request({
    url: "app/user/waitingPlaceTeamMemberList",
    method: "get",
  });
}

/**
 * 放置被邀请的会员
 */
export function submitPlaceTeamMember(data: {
  /**
   * 待设置的会员编号
   */
  inviteeMemberId?: string;
  /**
   * 待设置的会员 UID
   */
  inviteeUid: string;
  /**
   * 要设置成的直接被领导的会员编号
   */
  leadByMemberId?: number;
  /**
   * 直接下级 UID 为空的时候,直接设置到领导的下级
   */
  leadByUid?: number;
  /**
   * 要设置成的领导会员编号
   */
  leaderMemberId?: number;
  /**
   * 上级 UID
   */
  leaderUid: string;
}): AxiosPromise<any> {
  return request({
    url: "/app/user/placeTeamMember",
    method: "PUT",
    data,
  });
}

/**
 * 查询放置记录列表
 */
export function queryTeamMemberRecord(params: QueryTeamMemberParam): AxiosPromise<SysRelationChangeLogVo[]> {
  return request({
    url: "/app/user/list/placeTeamMember",
    method: "get",
    params,
  });
}

/**
 * 查询团队领导列表
 * @param params
 */
export function queryRelationLeader(params?: QueryRelationLeaderParam): AxiosPromise<RelationHierarchyBriefVo> {
  return request({
    url: "/app/user/list/relation/leader",
    method: "get",
    params,
  });
}

/**
 * 查询团队邀请列表
 * @param params
 */
export function queryRelationInvite(params?: {
  /*
   * 搜索的会员编号
   */
  searchNodeMemberId?: string;
  /**
   * 搜索的会员 UID
   */
  searchNodeUid?: string;
}): AxiosPromise<RelationHierarchyBriefVo> {
  return request({
    url: "/app/user/list/relation/invite",
    method: "get",
    params,
  });
}
