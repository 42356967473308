import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import  { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

export interface FilterDialogValue {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

interface FilterDialogProps extends Omit<DialogProps, "onClose"> {
  value: FilterDialogValue;
  onFilter: (newValue: FilterDialogValue) => void;
  onClose: () => void;
}

export default function DateFilterDialog({
                                       value,
                                       open,
                                       onClose,
                                       onFilter,
                                       ...restProps
                                     }: FilterDialogProps) {
  const [stateValue, setStateValue] = useState<FilterDialogValue>({
    startDate: null,
    endDate: null
  });
  const { t } = useTranslation();
  useEffect(() => {
    setStateValue(value);
  }, []);

  const handleReset = () => {
    setStateValue({
      startDate: null,
      endDate: null
    });
  };

  const handleConfirm = () => {
    onFilter(stateValue);
    onClose();
  };
  return (
    <Dialog
      className="trade-filter-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        component: "form",
        sx:{
          margin: 0,
          width:'calc(100% - 32px)'
        }
      }}
      {...restProps}
    >
      <DialogTitle>{t("bd.dialog.tilte.filter")}</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: "1.125rem", py: "0.625rem" }}>
        <Box className="form-item">
          <Typography className="form-item-key">{t("bd.dialog.start.date")}</Typography>
          <DateTimePicker
            className="form-item-value"
            value={stateValue.startDate}
            views={['year', 'day', 'hours', 'minutes', 'seconds']}
            onChange={(newValue) => setStateValue({ ...stateValue, startDate: newValue })}
          />
        </Box>

        <Box className="form-item">
          <Typography className="form-item-key">{t("bd.dialog.end.date")}</Typography>
          <DateTimePicker
            className="form-item-value"
            value={stateValue.endDate}
            views={['year', 'day', 'hours', 'minutes', 'seconds']}
            onChange={(newValue) => setStateValue({ ...stateValue, endDate: newValue })}
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: "0.625rem",
          pb: "1.25rem",
          mx: "1rem",
        }}
      >
        <Button variant="outlined" onClick={handleReset} color="primary" style={{ flexGrow: 1 }}>
          {t("button.reset")}
        </Button>
        <Button variant="contained" onClick={handleConfirm} color="primary" style={{ flexGrow: 1, minWidth: "unset" }}>
          {t("button.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
