import { useCallback, useEffect, useRef, useState, Fragment } from "react";
import { Box, Container, TextField, InputAdornment, IconButton, Icon, Typography, Stack, styled, Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchCustomerServiceChatRecord, replyCustomerServiceChat } from "@/api/support";
import "./reply-chat.less";
import dayjs from "dayjs";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { CustomerServiceChatRecordMessageVO } from "@/api/types/customerService";
import { getUserInfo } from "@/api/userinfo";
import { uploadFilesToOss } from "@/api/global";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import PreviewPictureDialog from "@/pages/support/dialog/preview-picture-dialog";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  width: "fit-content",
  textAlign: "left",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function isCrossDay(date1: Date, date2: Date): boolean {
  const day1 = date1.getDate();
  const month1 = date1.getMonth();
  const year1 = date1.getFullYear();

  const day2 = date2.getDate();
  const month2 = date2.getMonth();
  const year2 = date2.getFullYear();

  return day1 !== day2 || month1 !== month2 || year1 !== year2;
}
const VisuallyHiddenInput = styled("input")({
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function ReplyChat() {
  const location = useLocation();
  const [items, setItems] = useState<CustomerServiceChatRecordMessageVO[]>([]);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState("");
  const [message, setMessage] = useState("");
  const lastElRef = useRef<HTMLDivElement | null>(null);
  const firstElRef = useRef<HTMLDivElement | null>(null);
  const pagePrev = useRef(1);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data: userInfo  } = useQuery(
    {
      queryKey: ["userInfo"],
      queryFn: getUserInfo,
    },
    queryClient
  );
  if (!location.state) {
    return null;
  }
  const { conversationId } = location.state as { conversationId: string };

  const fetchData = async (pageNum: number) => {
    const data = await fetchCustomerServiceChatRecord({ pageNum, pageSize: 15 }, conversationId);
    return data;
  };
  const loadMoreItems = useCallback(async () => {
    if (!hasMore) {
      return;
    }
    const data = await fetchData(pagePrev.current);
    const totalPages = Math.ceil(data.total / 15);
    if (totalPages - pagePrev.current <= 0) {
      setHasMore(false);
    }
    // console.log(items,data.rows.reverse(), 'items')
    setItems([...data.rows.reverse(), ...items]);
    setTotal(data.total);
  }, [fetchData, hasMore]);

  useEffect(() => {
    (async () => {
      if (!items.length) {
        const data = await fetchData(pagePrev.current);
        setItems(data.rows.reverse());
        setTotal(data.total);
        if (data.rows.length === 0) {
          setHasMore(false);
        }
        lastElRef.current?.scrollIntoView({ block: "end" });
      }
    })();
  }, []);
  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("file", e.target.files![0]);
    const data = await uploadFilesToOss(formData);
    await replyCustomerServiceChat(
      {
        message: data.data.url,
        messageType: "1",
      },
      conversationId
    );
    pagePrev.current = 1;
    const res = await fetchData(pagePrev.current);
    setTotal(res.total);
    setHasMore(true);
    setItems(res.rows.reverse());
    lastElRef.current?.scrollIntoView({ block: "end" });
  };
  const renderItems = useCallback((item: CustomerServiceChatRecordMessageVO, index: number) => {
    return (
      <>
        {total >= 1 ? (
          items[index - 1] ? (
            items[index - 1].createTime && isCrossDay(new Date(item.createTime), new Date(items[index - 1].createTime)) ? (
              <Typography key={index} color="text.disabled" sx={{ textAlign: "center" }}>
                {dayjs(item.createTime).format("YYYY-MM-DD")}
              </Typography>
            ) : null
          ) : index === 0 || total === 1 ? (
            <Typography key={index} color="text.disabled" sx={{ textAlign: "center" }}>
              {dayjs(item.createTime).format("YYYY-MM-DD")}
            </Typography>
          ) : null
        ) : null}
        {userInfo?.data.memberId === item.createBy ? (
          <Item
            ref={index === items.length - 1 ? lastElRef : null}
            sx={{ alignSelf: "flex-end", maxWidth: 0.8, minWidth: 0.12, mr: "1rem !important", backgroundColor: "#042245 !important" }}
            className="chat-item chat-item-arrow-right"
          >
            <Typography>
              {!item.messageType ? (
                item.message
              ) : (
                <img
                  width={40}
                  src={item.message}
                  onClick={() => {
                    setImage(item.message);
                    setOpen(true);
                  }}
                />
              )}
            </Typography>
            {/*<Typography sx={{ position: "absolute", top: -15, left: 0, fontSize: 10 }}>{dayjs(item.createTime).format("HH:mm:ss")}</Typography>*/}
          </Item>
        ) : (
          <Item
            sx={{ maxWidth: 0.8, minWidth: 0.12, ml: "1rem !important", backgroundColor: '#595959 !important' }}
            className="chat-item chat-item-arrow-left"
            ref={index === items.length - 1 ? lastElRef : null}
          >
            <Typography>
              {!item.messageType ? (
                item.message
              ) : (
                <img
                  width={40}
                  src={item.message}
                  onClick={() => {
                    setImage(item.message);
                    setOpen(true);
                  }}
                />
              )}
            </Typography>
            {/*<Typography sx={{ position: 'absolute', top: -15, right: 0, fontSize: 10 }}>{dayjs(item.createTime).format("HH:mm")}</Typography>*/}
          </Item>
        )}
      </>
    )
  }, [userInfo, total, items])
  return (
    <Box sx={{ display: "flex", height: 1, pb: 2, flexDirection: "column" }}>
      <Box
        sx={{ flex: "1", overflowY: "auto", overflowX: "hidden", mb: 2 }}
        onScroll={async (e) => {
          const target = e.currentTarget;
          if (target.scrollTop <= 0  && hasMore) {
            const totalPages = Math.ceil(total / 15);
            if (!(totalPages - pagePrev.current <= 0)) {
              pagePrev.current = pagePrev.current + 1;
              await loadMoreItems();
              lastElRef.current?.scrollIntoView({ block: "center" });
            }
          }
        }}
      >
        <Stack spacing={2} sx={{ mt: 2 }}>
          {items.map((item, index) => {
            return (
              <Fragment key={index}>
                { renderItems(item, index)}
              </Fragment>
            );
          })}
        </Stack>
      </Box>
      <Container>
        <TextField
          fullWidth
          placeholder={t("bd.support.contact.coustomer.content.placeholder")}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          slotProps={{
            input: {
              autoComplete: "off",
              endAdornment: (
                <InputAdornment position="end" sx={{ ml: 0 }}>
                  <Icon className="icon-action-image" sx={{ mr: 2 }} component="label">
                    <VisuallyHiddenInput type="file" accept=".png,.jpg" onChange={handleImageUpload} />
                  </Icon>
                  <Icon
                    className="icon-action-send"
                    onClick={async () => {
                      try {
                        await replyCustomerServiceChat(
                          {
                            message,
                            messageType: "0",
                          },
                          conversationId
                        );
                        setMessage("");
                        pagePrev.current = 1;
                        const data = await fetchData(pagePrev.current);
                           setTotal(data.total);
                           setHasMore(true);
                           setItems(data.rows.reverse());
                        lastElRef.current?.scrollIntoView({ block: "end" });
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  />
                </InputAdornment>
              ),
            },
          }}
        />
      </Container>
      <PreviewPictureDialog src={image} open={open} onClose={() => setOpen(false)} />
    </Box>
  );
}
