import kryptonLogo from "@/assets/images/kryptonLogo.gif";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import "./index.less";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { bindInviter } from "@/api/userinfo";
import { enqueueSnackbar } from "notistack";
interface BindInviteFragementProps {
  onSuccess?: () => void;
}
export default function BindInviteFragement({ onSuccess } :BindInviteFragementProps) {
  const { t } = useTranslation();
  const [inviter, setInviter] = useState("");
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: bindInviter,
    onSuccess:  async () => {
      enqueueSnackbar(t("bd.rwa.bind.invite.success.message"), { variant: "success",  autoHideDuration: 2000 });
      await queryClient.invalidateQueries({ queryKey: ["userInfo"] });
      onSuccess && onSuccess();
    },
  });

  return (
    <Box className="bind-invite-frament">
      <Box sx={{ pt: "40%", textAlign: "center" }}>
        <img className="logo" src={kryptonLogo} style={{ height: "6rem" }} />
      </Box>
      <Box className="space" sx={{ width: "100%", height: "10rem" }}></Box>
      <Container sx={{ paddingBottom: "5rem" }}>
        <Box marginBottom={1}>
          <Typography>{t("bd.digitalHuman.bind.invite.myInvite")}</Typography>
        </Box>
        <Box marginBottom={1}>
          <TextField
            value={inviter}
            onChange={(v) => setInviter(v.target.value)}
            placeholder={t("bd.digitalHuman.bind.invite.input.placeholder")}
            onFocus={(e)=>{
              setTimeout(()=> {
                e.target?.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }, 300)
            }}
            onBlur={(e)=> {
              setTimeout(()=> {
                e.target?.scrollIntoView({ behavior: 'smooth', block: 'end' })
              }, 300)
            }}
            fullWidth
            sx={{
              backgroundColor: "background.default",
            }}
          />
        </Box>
        <Box marginBottom={3}>
          <Typography variant="body2" color="text.secondary">
            {t("bd.digitalHuman.bind.invite.remainder")}
          </Typography>
        </Box>
        <Box marginBottom={5}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              mutation.mutate(inviter);
              setInviter("");
            }}
            disabled={inviter == null || inviter.trim() == ""}
          >
            {t("button.confirm")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
