import LogoNav from '@/assets/images/logo-nav.png';
import ElevationScroll from '@/components/elevation-scroll';
import ArrowBackIconIos from '@mui/icons-material/ArrowBackIos';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Icon, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';

import LangModeButton from '@/lang/lang-mode-button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.less';
import { useTopAppBar } from './provider';
import MenuDrawer from '../menu-drawer';
import { useState } from 'react';



export default function TopAppBar() {
    const navigate = useNavigate();
    const { title, showBackButton, menuMode } = useTopAppBar();
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const backButton = showBackButton ?
        <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIconIos sx={{ color: 'text.primary' }} />
        </IconButton> : null;
    const titleElement = title ? <Box sx={{ flexGrow: 1 }}>{t(title)}</Box> : <img className='logo log-nav' src={LogoNav}></img>;

    return <ElevationScroll>
        <AppBar className='top-app-bar'>
            <Toolbar>
                <Box display='flex' flexDirection='row' alignItems='center' sx={{ flexGrow: 1 }}>
                    {backButton}
                    {titleElement}
                </Box>
                <LangModeButton sx={{ marginRight: '0.4rem' }} />
                {/* 主题模式 */}
                {/* <ThemeModeButton sx={{marginRight: '0.4rem'}}/> */}
                <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setShowMenu(true)}
                >
                    <Icon className="icon-more" />
                </IconButton>
            </Toolbar>

            <MenuDrawer
                open={showMenu}
                mode={menuMode}
                // container={()=>document.querySelector("#root")}
                onClose={() => setShowMenu(false)}
                anchor='right'
            />
        </AppBar>
    </ElevationScroll>
}