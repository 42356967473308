import { initWeb3Connect, Web3Provider } from "@/web3";
import CssBaseline from '@mui/material/CssBaseline';
import { ConfirmProvider } from "material-ui-confirm";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { FC, StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";

import { ErrorBoundary } from "./components/ErrorBoundary";
import reportWebVitals from "./reportWebVitals";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "./index.less";

import WebApp from "@twa-dev/sdk";

import BdThemeProvider from "@/theme/bd-theme-provider";
import App from "./App";
import { SetPasswordDialogProvider } from "./components/set-password-dialog";
import { styled } from "@mui/material";

//初始化web3连接
initWeb3Connect();

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>An unhandled error occurred:</p>
    <blockquote>
      <code>{error instanceof Error ? error.message : typeof error === "string" ? error : JSON.stringify(error)}</code>
    </blockquote>
  </div>
);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#042245',
  },
}));
const Inner: FC = () => {
  const debug = WebApp.initDataUnsafe.start_param === "debug";
  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    if (debug) {
      import("eruda").then((lib) => lib.default.init());
    }
  }, [debug]);

  return <BdThemeProvider>
    <SnackbarProvider autoHideDuration={800} maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
     Components={{
       success: StyledMaterialDesignContent,
     }}
    >
      <SetPasswordDialogProvider>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </SetPasswordDialogProvider>
    </SnackbarProvider>
  </BdThemeProvider>
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <CssBaseline />
    <ErrorBoundary fallback={ErrorBoundaryError}>
      <Web3Provider>
        <Inner />
      </Web3Provider>
    </ErrorBoundary>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();