import request from "@/utils/request";
import { AxiosPromise } from "axios";

export interface QueryTrainngValueIncomeRecordParam extends PageQuery {
  /**
   * 日期范围参数
   */
  params?: any;
}

export interface BdAccountPortfolioChangeVo {
  /**
   * 变动的数量
   */
  // @ts-ignore
  amount: number;
  /**
   * 资产记录变动编号
   */
  changeId?: number;
  /**
   * 变动类型,
   */
  changeType?: number;
  /**
   * 是否是回滚事件, (Y是 N否)
   */
  ifRollback?: string;
  portfolio:{
    metaInfo: string[];
    portfolioCode: string;
    portfolioDecimal: number;
  };
  /**
   * 会员编号
   */
  memberId?: number;
  /**
   * 来源编号
   */
  originId?: number;
  /**
   * 来源类型
   */
  originType?: number;
  /**
   * 资产种类编号
   */
  portfolioId?: number;
  /**
   * 处理后金额
   */
  processAfterAmount?: number;
  /**
   * 处理前金额
   */
  processBeforeAmount?: number;
  /**
   * 处理日期
   */
  processDate?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 校验状态
   */
  status?: number;
  /**
   * 会员 UID
   */
  uid?: number;
}

export interface CommunityPerformanceVo {
  harvestPortfolios?: {
    /**
     * 会员资产编号
     */
    accountPortfolioId?: number;
    /**
     * 当前资产的余额
     */
    balance?: number;
    /**
     * 创建者
     */
    createBy?: number;
    /**
     * 创建时间
     */
    createTime?: Date;
    /**
     * 当前资产的冻结金额
     */
    freeze?: number;
    /**
     * 会员编号
     */
    memberId?: number;
    portfolio: {
      metaInfo: string[];
      portfolioCode: string;
      portfolioDecimal: number;
    };
    /**
     * 请求参数
     */
    params?: { [key: string]: { [key: string]: any } };
    /**
     * 资产种类编号
     */
    portfolioId?: number;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 状态
     */
    status?: number;
    /**
     * 当前资产的累计流入金额
     */
    total?: number;
    /**
     * 更新者
     */
    updateBy?: number;
    /**
     * 更新时间
     */
    updateTime?: string;
  }[];
  plantingPortfolio?: {
    /**
     * 会员资产编号
     */
    accountPortfolioId?: number;
    /**
     * 当前资产的余额
     */
    balance?: number;
    /**
     * 创建者
     */
    createBy?: number;
    /**
     * 创建时间
     */
    createTime?: Date;
    /**
     * 当前资产的冻结金额
     */
    freeze?: number;
    /**
     * 会员编号
     */
    memberId?: number;
    /**
     * 请求参数
     */
    params?: { [key: string]: { [key: string]: any } };
    /**
     * 资产种类编号
     */
    portfolioId?: number;
    /**
     * 备注
     */
    remark?: string;
    /**
     * 状态
     */
    status?: number;
    /**
     * 当前资产的累计流入金额
     */
    total?: number;
    /**
     * 更新者
     */
    updateBy?: number;
    /**
     * 更新时间
     */
    updateTime?: string;
  };
  summaryVo?: {
    /**
     * 自盈区当日的业绩
     */
    leftBv?: number;
    /**
     * 待继承右区业绩
     */
    leftInheritBv?: number;
    /**
     * 竞争区今日业绩
     */
    rightBv?: number;
    /**
     * 待继承右区业绩
     */
    rightInheritBv: number;

    /**
     * 自身当日的业绩
     */
    selfBv?: number;
    /**
     * 自身历史累计业绩
     */
    selfHistorySumBv?: number;
  };
}

/**
 * 查询自己所在社区今日业绩情况
 */
export function queryCommunityPerformance(): AxiosPromise<CommunityPerformanceVo> {
  return request({
    url: "/app/bonus/my/communityPerformance",
    method: "get",
  });
}
/**
 * 查询训练值记录
 * @param params
 */
export function queryTrainngValueIncomeRecord(params: QueryTrainngValueIncomeRecordParam): AxiosPromise<BdAccountPortfolioChangeVo[]> {
  return request({
    url: "/app/bonus/my/training/value/income",
    method: "get",
    params,
  });
}

export interface QueryBonusParam extends PageQuery {
  startDate?: string;
  endDate?: string;
}

export interface BdAccountPortfolioChangeVo {
  summary: number;
  /**
   * 变动的数量
   */
  // @ts-ignore
  amount?: number;
  /**
   * 资产记录变动编号
   */
  changeId?: number;
  /**
   * 变动类型,
   */
  changeType?: number;
  /**
   * 是否是回滚事件, (Y是 N否)
   */
  ifRollback?: string;
  /**
   * 会员编号
   */
  memberId?: number;
  /**
   * 来源编号
   */
  originId?: number;
  /**
   * 来源类型
   */
  originType?: number;
  /**
   * 资产种类编号
   */
  portfolioId?: number;
  /**
   * 处理后金额
   */
  processAfterAmount?: number;
  /**
   * 处理前金额
   */
  processBeforeAmount?: number;
  /**
   * 处理日期
   */
  processDate?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 校验状态
   */
  status?: number;
  /**
   * 会员 UID
   */
  uid?: number;
}

/**
 * 查询自己流量收益情况
 * @param params
 */
export function queryFlowBonus(params: QueryBonusParam): AxiosPromise<BdAccountPortfolioChangeVo[]> {
  return request({
    url: "/app/bonus/my/static/bonus",
    method: "get",
    params,
  });
}

/**
 * 查询自己数量收益情况
 * @param params
 */
export function queryNumberBonus(params: QueryBonusParam): AxiosPromise<BdAccountPortfolioChangeVo[]> {
  return request({
    url: "/app/bonus/my/dynamic/bonus",
    method: "get",
    params,
  });
}

/**
 * 查询自己特殊收益情况
 * @param params
 */
export function querySpecialBonus(params: QueryBonusParam): AxiosPromise<BdAccountPortfolioChangeVo[]> {
  return request({
    url: "/app/bonus/my/bucket/bonus",
    method: "get",
    params,
  });
}

export interface BdBonusEcoVo {
  /**
   * 水桶红利的日期
   */
  bonusDate: string;
  /**
   * 大模型生态奖励的金额=训练值余额*每日回报率
   */
  ecoBonusAmount?: number;
  /**
   * 大模型生态奖励编号
   */
  ecoBonusId?: number;
  harvestDetailItems: {
    amount: string;
    portfolio: string;
  }[];
  /**
   * 推荐人会员编号
   */
  inviteMemberId?: number;
  /**
   * 领导人会员编号
   */
  leaderMemberId?: number;
  member?: {
    /**
     * 创建时间
     */
    createTime?: Date;
    /**
     * 推荐人会员编号
     */
    inviteMemberId?: number;
    /**
     * 推荐人表达式
     */
    inviteParentMemberExpr?: string;
    /**
     * 推荐人 UID 表达式
     */
    inviteParentUidExpr?: string;
    /**
     * 推荐人UID
     */
    inviteUid?: number;
    /**
     * 领导会员编号
     */
    leaderMemberId?: number;
    /**
     * 领导人 UID 表达式
     */
    leaderParentUidExpr?: string;
    /**
     * 领导UID
     */
    leaderUid?: number;
    /**
     * 会员编号
     */
    memberId?: number;
    /**
     * 身份码牌
     */
    uid?: number;
  };
  /**
   * 获得奖励的会员编号
   */
  memberId?: number;
  /**
   * 套餐代号
   */
  packageCode?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 每日回报率
   */
  returnDaily?: number;
  /**
   * 训练值余额
   */
  trainingValueBalance?: number;
}

/**
 * 查询AI大模型红利获奖记录
 */
export function queryBonusByEcoRecord(params: QueryBonusParam): AxiosPromise<BdBonusEcoVo[]> {
  return request({
    url: "/app/bonus/my/eco/record",
    method: "get",
    params,
  });
}

export interface BdBonusInviteVo {
  /**
   * 邀请奖励来源编号,如果是来源套餐则是套餐记录编号,否则是CDKey记录编号
   */
  bonusOriginId?: number;
  /**
   * 邀请奖励来源, 1=套餐购买,2=套餐复投,3=CDKey激活
   */
  bonusOriginWay?: number;
  /**
   * 奖励发送时间
   */
  createTime: string;
  /**
   * 奖励的金额
   */
  inviteBonusAmount?: number;
  /**
   * 邀请奖励编号
   */
  inviteBonusId?: number;
  harvestDetailItems: {
    amount: string;
    portfolio: string;
  }[];
  /**
   * 奖励获取时的杠杆比例
   */
  inviteBonusLever?: number;
  member?: {
    /**
     * 创建时间
     */
    createTime?: Date;
    /**
     * 推荐人会员编号
     */
    inviteMemberId?: number;
    /**
     * 推荐人表达式
     */
    inviteParentMemberExpr?: string;
    /**
     * 推荐人 UID 表达式
     */
    inviteParentUidExpr?: string;
    /**
     * 推荐人UID
     */
    inviteUid?: number;
    /**
     * 领导会员编号
     */
    leaderMemberId?: number;
    /**
     * 领导人 UID 表达式
     */
    leaderParentUidExpr?: string;
    /**
     * 领导UID
     */
    leaderUid?: number;
    /**
     * 会员编号
     */
    memberId?: number;
    /**
     * 身份码牌
     */
    uid?: number;
  };
  /**
   * 获得奖励的会员编号
   */
  memberId?: number;
  /**
   * 购买的金额
   */
  packageRecordAmount?: number;
  /**
   * 备注
   */
  remark?: string;
}

/**
 * 查询共创红利获奖记录
 * @param params
 */
export function queryBonusByInviteRecord(params: QueryBonusParam): AxiosPromise<BdBonusInviteVo[]> {
  return request({
    url: "/app/bonus/my/invite/record",
    method: "get",
    params,
  });
}

export interface BdBonusIntelligenceVo {
  /**
   * 分红时计算得到的领导人会员编号
   * (考虑到错误的关系绑定,最终计算以这个为准)
   */
  actualLeaderMemberId?: number;
  /**
   * 分红时计算得到的领导人会员编号
   * (考虑到错误的关系绑定,最终计算以这个为准)
   */
  actualLeaderUid?: number;
  /**
   * 智能红利的日期
   */
  bonusDate: string;
  harvestDetailItems: {
    amount: string;
    portfolio: string
  }[];

  leftUid: string;

  /**
   * 该会员明日将继承的业绩
   */
  futureInheritBv?: number;

  /**
   * 该社区当日产生的继承业绩,由本社区的左区会员继承
   */
  inheritBvToday?: number;

  leftMemberId?: string;

  /**
   * 奖励的金额
   */
  intelligenceBonusAmount?: number;
  payoutPercentage?: string;
  /**
   * 智能红利奖励编号
   */
  intelligenceBonusId?: number;
  /**
   * 分红时其领导人会员编号
   */
  leaderMemberId?: number;
  /**
   * 分红时其领导人会员编号
   */
  leaderUid?: number;
  /**
   * 自盈区当日的业绩
   */
  leftBv?: number;
  /**
   * 自盈区历史累计业绩
   */
  leftHistorySumBv?: number;
  /**
   * 自盈区从昨日继承的业绩
   */
  leftInheritBv?: number;
  /**
   * 奖励获取时的杠杆比例
   */
  lever?: number;
  member?: {
    /**
     * 创建时间
     */
    createTime?: Date;
    /**
     * 推荐人会员编号
     */
    inviteMemberId?: number;
    /**
     * 推荐人表达式
     */
    inviteParentMemberExpr?: string;
    /**
     * 推荐人 UID 表达式
     */
    inviteParentUidExpr?: string;
    /**
     * 推荐人UID
     */
    inviteUid?: number;
    /**
     * 领导会员编号
     */
    leaderMemberId?: number;
    /**
     * 领导人 UID 表达式
     */
    leaderParentUidExpr?: string;
    /**
     * 领导UID
     */
    leaderUid?: number;
    /**
     * 会员编号
     */
    memberId?: number;
    /**
     * 身份码牌
     */
    uid?: number;
  };
  /**
   * 获得奖励的会员编号
   */
  memberId?: number;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 竞争区今日业绩
   */
  rightBv?: number;
  /**
   * 竞争区历史合并累计业绩
   */
  rightHistorySumBv?: number;
  /**
   * 竞争区从昨日继承的业绩
   */
  rightInheritBv?: number;
  /**
   * 自身的业绩
   */
  selfBv?: number;
  /**
   * 自身历史累计业绩
   */
  selfHistorySumBv?: number;
  /**
   * 自身当日从昨日继承的业绩
   */
  selfInheritBv?: number;
}

/**
 * 查询智能红利获奖记录
 * @param params
 */
export function queryBonusByIntelligenceRecord(params: QueryBonusParam): AxiosPromise<BdBonusIntelligenceVo[]> {
  return request({
    url: "/app/bonus/my/intelligence/record",
    method: "get",
    params,
  });
}

export  interface  BdBonusBucketVo {
  /**
   * 水桶奖励来源编号,如果是来源套餐则是套餐记录编号,否则是CDKey记录编号
   */
  bonusOriginId?: number;
  /**
   * 水桶奖励来源, 1=套餐购买,2=套餐复投,3=CDKey激活
   */
  bonusOriginWay?: number;
  harvestDetailItems: {
    amount: string;
    portfolio: string;
  }[];
  /**
   * 水桶奖励的金额,购买金额*奖励比例-下级团队已截留的水桶奖励
   */
  bucketBonusAmount?: number;
  /**
   * 水桶奖励编号
   */
  bucketBonusId?: number;
  /**
   * 水桶奖励比例
   */
  bucketLever?: number;
  /**
   * 奖励发送时间
   */
  createTime: string;
  member?: HierarchyRelationGroupVo;
  /**
   * 获得奖励的会员编号
   */
  memberId?: number;
  /**
   * 购买的金额
   */
  packageRecordAmount?: number;
  /**
   * 从入金会员到到本人的关系路径,中间用/分割
   * memberId
   */
  relationPath?: string;
  /**
   * 从入金会员到到本人的关系路径,中间用/分割
   * uid
   */
  relationUidPath: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 下级团队已截留的水桶奖励
   */
  teamGotBucketBonusTotal?: number;
}
export interface HierarchyRelationGroupVo {
  /**
   * 创建时间
   */
  createTime?: Date;
  /**
   * 邀请层级(会员编号)，从最远的开始,一直到本人
   */
  inviteHierarchy?: string;
  /**
   * 邀请层级(UID)，从最远的开始,一直到本人
   */
  inviteHierarchyDetails?: MemberBriefInfoDTO[];
  /**
   * 推荐人会员编号
   */
  inviteMemberId?: number;
  /**
   * 推荐人UID
   */
  inviteUid?: number;
  /**
   * 领导层级(会员编号)，从最远的开始,一直到本人
   */
  leaderHierarchy?: string;
  /**
   * 领导层级(UID)，从最远的开始,一直到本人
   */
  leaderHierarchyDetails?: MemberBriefInfoDTO[];
  /**
   * 领导会员编号
   */
  leaderMemberId?: number;
  /**
   * 领导UID
   */
  leaderUid?: number;
  /**
   * 归属的团队会员编号
   */
  mainLeaderMemberId?: number;
  /**
   * 归属的团队会员UID
   */
  mainLeaderUid?: number;
  /**
   * 会员编号
   */
  memberId?: number;
  /**
   * 身份码牌
   */
  uid?: number;
}
export interface MemberBriefInfoDTO {
  /**
   * 会员编号
   */
  memberId?: number;
  /**
   * UID
   */
  uid?: number;
}
/**
 * 查询特奖红利记录
 * @param params
 */
export function queryBonusBySpecialRecord(params: QueryBonusParam): AxiosPromise<BdBonusBucketVo[]> {
  return request({
    url: "/app/bonus/my/bucket/record",
    method: "get",
    params,
  });
}