import cache from '@/plugins/cache';
import { useToken } from '@/stores/modules/user';
import { useConfirm } from 'material-ui-confirm';

const TokenKey = 'App-SEC-Token';

export const getToken = () => (cache.local.get(TokenKey));

export const setToken = (access_token: string) => (cache.local.set(TokenKey, access_token));

export const removeToken = () => (cache.local.remove(TokenKey));

export const useAuthCheck = () => {
    const token = useToken();
    const confirm = useConfirm()

    return () => {
        if (token == null || token.trim().length == 0) {
            confirm({
                title: 'Error',
                description: 'Please login first',
                hideCancelButton: true,
                confirmationText: 'Close',
                titleProps: {
                    sx: {
                        textAlign: 'left !important'
                    }
                },
                dialogActionsProps: {
                    sx: {
                        justifyContent: 'center'
                    }
                },
                confirmationButtonProps: {
                    variant: 'contained'
                }
            }).then(() => {
                window.location.href = process.env.REACT_APP_CONTEXT_PATH;
            });
            return false;
        }
        return true;
    }
}
